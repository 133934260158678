import { gql } from "@apollo/client";

export default gql`
  query getPerson($id: Int!) {
    person: getPerson(id: $id) @rest(path: "/api/people/{args.id}", type: "LegacyPerson") {
      id
      created
      email
      enabled
      enterpriseId
      firstName
      fullName @client
      icon
      isArchived
      isLegacyPaidSeat @client
      landingPage
      lastLogin
      lastName
      name
      notes
      phone
      role
      updated
    }
  }
`;
