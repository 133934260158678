import { css } from "aphrodite/no-important";
import _ from "lodash";
import moment from "moment";
import FuturesContractHistoryChart from "prices/charts/FuturesContractHistoryChart";
import styles from "prices/charts/styles";
import CommodityContractSummary from "prices/components/CommodityContractSummary";
import PropTypes from "prop-types";
import React, { Component } from "react";

import { withFuturesContract } from "collection/graphql/marketing";
import useCommodityQuotes from "collection/graphql/prices/hooks/useCommodityQuotes";
import dollarFormat from "lib/utils/dollarFormat";

import CropBadge from "components/fl-ui/CropBadge";
import { Select, FormGroup } from "components/fl-ui/Form";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";
import CommodityYieldUnit from "components/units/CommodityYieldUnit";
import { useNavigate } from "react-router-dom";

const Header = (props) => {
  const { contract, onCommodityChange, symbol } = props;
  const { commodityQuotes } = useCommodityQuotes(props).data ?? {};
  const { commodity, priceSnapshot, shortName } = contract;
  const { contracts } =
    _.find(commodityQuotes, ({ contracts }) => contracts.some((contract) => contract.symbol === symbol)) || {};
  const options = _.map(contracts, ({ name, symbol }) => ({
    id: symbol,
    label: `${name} • ${symbol}`,
    value: symbol,
  }));

  return (
    <div>
      <div className={css(styles.listingTitle)}>
        <CropBadge marginRight="regular" color={commodity.color} abbr={commodity.abbr} />

        <h3 className={css(styles.detailHeaderTitle)}>{commodity.name} Futures</h3>

        <FormGroup className={css(styles.contractSelectionFormGroup)} horizontal label="Contract" inputId="contract">
          {!commodityQuotes ? (
            <LoadingWrapper isLoading />
          ) : (
            <Select
              id="contract"
              name="contract"
              options={options}
              onChange={(e, { contract }) => onCommodityChange(contract)}
              value={symbol}
            />
          )}
        </FormGroup>
      </div>

      <CommodityContractSummary commodityName={shortName} priceSnapshot={priceSnapshot} />
    </div>
  );
};

Header.propTypes = {
  contract: PropTypes.object.isRequired,
  onCommodityChange: PropTypes.func.isRequired,
  symbol: PropTypes.string.isRequired,
};

const FuturesContractDetailContainer = withFuturesContract((props) => {
  const {
    data: { contract, loading },
    symbol,
  } = props;
  const { commodity, low30Day, high30Day, history, priceSnapshot } = contract || {};
  const navigate = useNavigate();

  const cell = (title, price) => {
    return (
      <div className={css(styles.cell)}>
        <span className={css(styles.figureTitle)}>{title}</span>
        <span className={css(styles.figureAmount)}>
          <CommodityYieldUnit commodity={commodity} amount={<strong>{dollarFormat(price)}</strong>} per="unit" />
        </span>
      </div>
    );
  };

  const onCommodityChange = (contract) => {
    navigate(`futures/contract/${contract}`);
  }

  return loading ? (
    <LoadingWrapper isLoading />
  ) : (
    <div>
      <Header symbol={symbol} contract={contract} onCommodityChange={onCommodityChange} />

      <FuturesContractHistoryChart history={history} />

      <h3 className={css(styles.statsTitle)}>
        Stats for {moment(priceSnapshot.priceTimestamp).format("MMM DD, YYYY")}
      </h3>

      <div className={css(styles.container)}>
        <div>
          {cell("Open", priceSnapshot.open)}
          {cell("High", priceSnapshot.high)}
          {cell("Low", priceSnapshot.low)}
        </div>

        <div>
          {cell("30-day high", high30Day)}
          {cell("30-day low", low30Day)}
        </div>
      </div>
    </div>
  );
});

FuturesContractDetailContainer.propTypes = {
  symbol: PropTypes.string.isRequired,
};

export default FuturesContractDetailContainer;
