import { CARGILL_ELEVATE, SUMMARY } from "marketing/constants";
import ContractDetails from "marketing/routes/ContractDetails";
import Dashboard from "marketing/routes/Dashboard";
import MarketedCropDetail from "marketing/routes/MarketedCropDetail";
import ScaleDetails from "marketing/routes/ScaleDetails";
import { ADD_CONTRACT, ADD_SCALE_TICKET, EDIT_CONTRACT, EDIT_SCALE_TICKET } from "marketing/utils/contractEnums";
import React from "react";
import { redirect } from "react-router-dom";
import _ from "lodash";


import MarketingCropDetailGuard from "marketing/routes/MarketingCropDetailGuard";



export default [
  {
    path: "/marketing",
    element: <Dashboard view={SUMMARY} />,
  },

  {
    path: "/marketing/advisor",
    element: <Dashboard view={CARGILL_ELEVATE} />,
  },

  {
    path: "/marketing/cargillelevate",
    loader: () => redirect("/marketing/advisor"),
  },

  {
    element: <MarketingCropDetailGuard />,
    children: [
      {
        path: "/marketing/:marketedCropId",
        element: <MarketedCropDetail view={MarketedCropDetail.DASHBOARD} />,
      },

      {
        path: "/marketing/:marketedCropId/contracts",
        element: <MarketedCropDetail view={MarketedCropDetail.CONTRACTS} />,
      },

      {
        loader: ({ params }) => {
          const isValidContractType = ["cash", "futures", "options"].includes(_.toLower(params.contractType));
          return isValidContractType ? null : redirect(`/marketing/${params.marketedCropId}`);
        },

        children: [
          {
            path: "/marketing/:marketedCropId/contracts/:contractType/new",
            element: <ContractDetails action={ADD_CONTRACT} />,
          },

          {
            loader: ({ params: { contractType, marketedCropId } }) => {
              const path = contractType === "cash" ? `/marketing/${marketedCropId}` : `/marketing/${marketedCropId}/contracts`;
              return redirect(path);
            },

            path: "/marketing/:marketedCropId/contracts/:contractType",
            element: <div>View it</div>,
          },

          {
            path: "/marketing/:marketedCropId/contracts/:contractType/:contractId/edit",
            element: <ContractDetails action={EDIT_CONTRACT} />,
          },
        ],
      },

      {
        path: "/marketing/:marketedCropId/contracts/cash/:contractId/scale/new",
        element: <ScaleDetails action={ADD_SCALE_TICKET}/>,
      },

      {
        path: "/marketing/:marketedCropId/contracts/cash/:contractId/scale/:scaleTicketId",
        element: <ScaleDetails action={EDIT_SCALE_TICKET}/>,
      },
    ],
  },
];
