import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache } from "@apollo/client";

import { trackingCallback } from "collection/graphql/client/common";
import { authLink } from "collection/graphql/client/links/authLink";

const headers = {};
headers["X-Client-Version"] = process.env.CLIENT_VERSION || "dev";

const getLink = () =>
  ApolloLink.from([
    trackingCallback,
    authLink,
    createHttpLink({
      uri: "/v2.0/rain/graphql",
      credentials: "same-origin", // allow auth cookies to pass through
      headers,
    }),
  ]);

export const last24RainfallClient = new ApolloClient({
  assumeImmutableResults: true,
  cache: new InMemoryCache({}),
  link: getLink(),
});

export const historicalRainfallClient = new ApolloClient({
  assumeImmutableResults: true,
  cache: new InMemoryCache({}),
  link: getLink(),
});
