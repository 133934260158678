"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const client_1 = require("@apollo/client");
exports.default = (0, client_1.gql) `
  mutation createProduct($input: CreateProductInput!) {
    product: createProduct(input: $input) @rest(type: "BaseProduct", path: "/api/products", method: "POST") {
      ...DetailedProduct
    }
  }
`;
