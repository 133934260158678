import React, { createContext, useContext } from "react";


export const marketedCropDetailContext = createContext(undefined);

const useMarketedCropDetail = () => {
  const context = useContext(marketedCropDetailContext);
  if (!context) {
    throw new Error("Marketed crop detail context required");
  }

  return context;
};

export default useMarketedCropDetail;
