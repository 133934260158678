import { logoutMobileUser } from "mobile";

import { clearData } from "collection/graphql/auth/lib/clearData";
import { ssoUserVar, currentEnterpriseVar } from "collection/graphql/client/common";
import { KeycloakStorageString } from "modules/sso/constants";
import { clearCurrentEnterprise } from "modules/sso/utils/currentEnterpriseUtils";
import getSSOUser from "modules/sso/utils/getSSOUser";

export default async function logout() {
  const ssoUser = getSSOUser();

  const actions = [];
  actions.push(clearData());
  actions.push(logoutMobileUser());

  if (!ssoUser?.id_token) {
    // logout legacy
    actions.push(fetch("/v2.0/users/logout").catch(() => {}));
  }

  if (ssoUser?.access_token) {
    const logOutUrl = new URL(process.env.KEYCLOAK_LOGOUT_URL);
    logOutUrl.searchParams.set("id_token_hint", ssoUser?.id_token);
    // wait for logout keycloak before app logout
    await fetch(logOutUrl, { mode: "no-cors" }).catch(() => {});
    // logout bushel farm
    localStorage.removeItem(`oidc.user:${KeycloakStorageString}`);
    clearCurrentEnterprise();
    actions.push(ssoUserVar(null));
    actions.push(currentEnterpriseVar(null));
  }

  await Promise.allSettled(actions);
}
