/* eslint-disable react/no-unknown-property */
import { isNative } from "mobile/mobileManager";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

import useWindow from "hooks/useWindow";

const Logo = ({ className, size = "md" }) => {
  const win = useWindow();

  // clicking the logo should refresh the current page on native
  const handleClick = (event) => {
    if (isNative()) {
      event.preventDefault();
      win.location.reload();
    }
  };

  return (
    <Link className={className} to="/" onClick={handleClick}>
      <img
        alt="Bushel Farm"
        fetchpriority="high"
        height={size === "sm" ? "34" : "39"}
        src="/images/layout/logo.svg"
        width={size === "sm" ? "200" : "228"}
      />
    </Link>
  );
};

Logo.propTypes = {
  size: PropTypes.oneOf(["md", "sm"]),
};

export default Logo;
