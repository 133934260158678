import { css } from "aphrodite/no-important";
import dollarFormat from "marketing/utils/dollarFormat";
import ApprovalStatus from "prices/components/ApprovalStatus";
import { HARVEST_BIDS } from "prices/constants";
import React from "react";

import { localBidsStyles as styles } from "modules/prices/styles";

import DataTable from "components/fl-ui/DataTable";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";
import { Row, Col } from "components/fl-ui/Table";
import { Borders, Spacing } from "components/fl-ui/constants";
import CommodityYieldUnit from "components/units/CommodityYieldUnit";
import { Link } from "react-router-dom";

const tableStyles = {
  header: {
    borderTop: Borders.regular,
  },
  colPadding: {
    paddingTop: Spacing.regular,
    paddingBottom: Spacing.regular,
    paddingRight: Spacing.large,
    paddingLeft: Spacing.large,
  },
  noPaddingRight: {
    paddingRight: 0,
  },
  noPaddingLeft: {
    paddingLeft: 0,
  },
  borderRight: {
    borderRight: Borders.regular,
  },
};

const AreaBidsTable = ({ bids, commodity }) => {
  if (!bids) {
    return <LoadingWrapper isLoading />;
  }

  if (bids.length > 0) {
    return (
      <DataTable
        style={tableStyles}
        columns={[
          {
            label: "Buyer",
            style: { ...tableStyles.colPadding, ...tableStyles.noPaddingLeft },
          },
          {
            label: "Current bid",
            sortBy: "price",
            type: "number",
            style: { ...tableStyles.colPadding },
          },
          {
            label: "Basis",
            type: "number",
            style: { ...tableStyles.colPadding, ...tableStyles.noPaddingRight },
          },
        ]}
        values={bids}
        defaultSort="price"
      >
        {(v) => (
          <Row key={`${v.buyer.id}`}>
            <Col id="elevator" style={{ ...tableStyles.colPadding, ...tableStyles.noPaddingLeft }}>
              <Link className={css(styles.elevatorLink)} to={`/prices/local/buyers/${v.buyer.id}`}>
                <h5 className={css(styles.elevatorName)}>
                  {v.buyer.name}
                  <ApprovalStatus status={v.buyer.acceptsOfferDelivery} />
                </h5>
                <p
                  className={css(styles.elevatorDetails)}
                >{`${v.buyer.address} ${v.buyer.city}, ${v.buyer.state} ${v.buyer.zip}`}</p>
                <p className={css(styles.elevatorDetails)}>{`${v.buyer.distance} miles away`}</p>
              </Link>
            </Col>
            <Col type="number" style={tableStyles.colPadding}>
              <CommodityYieldUnit commodity={commodity} amount={dollarFormat(v.price)} per="unit" />
              <span className={css(styles.tableHelpText)}>{v.delivery && `${v.delivery} Delivery`}</span>
            </Col>
            <Col type="number" style={{ ...tableStyles.colPadding, ...tableStyles.noPaddingRight }}>
              <CommodityYieldUnit commodity={commodity} amount={<b>{dollarFormat(v.basis)}</b>} per="unit" />
            </Col>
          </Row>
        )}
      </DataTable>
    );
  }

  return (
    <h3 style={{ padding: "50px", display: "flex", justifyContent: "center" }}>
      There are no local prices for the zip code or commodity.
    </h3>
  );
};

const AreaBids = ({ bids, filters: { bidType, commodity } }) => {
  return (
    <div>
      <div className={css(styles.headerContainer)}>
        <h2>{bidType === HARVEST_BIDS ? "Harvest bids in your area" : "Spot bids in your area"}</h2>
      </div>
      <AreaBidsTable bids={bids} commodity={commodity} />
    </div>
  );
};

export default AreaBids;
