import React from "react";
import useBushelConnectLink from "settings/components/Integrations/hooks/useBushelConnectLink";
import styled from "styled-components";

import { useFarmAdvisorConfig } from "collection/graphql/advisor";
import useEnterpriseFeature from "hooks/useEnterpriseFeature";
import useViewType from "hooks/useViewType";

import { GreyColors } from "components/fl-ui/colors";
import { Spacing, Typography } from "components/fl-ui/constants";

const ContractsCardVariant = styled(({ className }) => {
  const viewType = useViewType();
  const { disabled, onClick, url } = useBushelConnectLink();
  const cargillElevateEnabled = useEnterpriseFeature("elevate_plus");
  const {
    data: { programName },
  } = useFarmAdvisorConfig();

  return (
    <div className={[className, viewType].join(" ")}>
      <img alt="Bushel Logo" className="bushel-logo" height="26" src="/images/layout/bushel-logo.svg" width="140" />

      <p className="copy">
        {cargillElevateEnabled
          ? `Connect now to finish setting up your ${programName} Bushel Farm account. Connecting will automatically import your contracts from buyers in the Bushel Network.`
          : "Reduce your manual data entry. Connect to Bushel to automatically import your contracts from grain buyers in the Bushel network."}
      </p>

      <div className="button-area">
        <a
          className="btn btn-primary"
          href="https://go.bushelfarm.com/connect-to-bushel-network"
          target="_blank"
          rel="noreferrer"
        >
          Learn More
        </a>

        <a className="btn btn-primary" data-source="ContractsBanner" disabled={disabled} href={url} onClick={onClick}>
          Connect
        </a>
      </div>
    </div>
  );
})`
  align-items: center;
  display: flex;
  gap: ${Spacing.xlarge};
  justify-content: space-evenly;

  &.mobile {
    flex-direction: column;
    gap: ${Spacing.regular};
    justify-content: center;
  }

  .copy {
    color: ${GreyColors.smoke40};
    font-size: ${Typography.sizes.md};
    font-weight: ${Typography.weights.bold};
    line-height: 1.5;
    margin: 0;
  }

  .button-area {
    align-items: center;
    display: flex;
    gap: ${Spacing.regular};
    justify-content: center;
  }
`;

export { ContractsCardVariant };
