/* eslint-disable react/display-name */
import { withErrorBoundary } from "@sentry/react";
import ActivityPageHeader from "activity/components/ActivityPageHeader";
import ActivitySummary from "activity/components/ActivitySummary";
import BlankSlate from "activity/components/BlankState";
import ActivityBulkActions from "activity/containers/ActivityBulkActions";
import ActivityFilterBar from "activity/containers/ActivityFilterBar";
import useActivitiesProvider, { Provider as ActivityListProvider } from "activity/hooks/useActivitiesProvider";
import { Provider as BulkSelectionProvider } from "activity/hooks/useActivityBulkSelection";
import { Provider as ActivityFilterProvider } from "activity/hooks/useActivityFilters";
import ActivityIndexBody from "activity/pages/containers/ActivityIndexBody";
import useDocumentTitle from "app/hooks/useDocumentTitle";
import React from "react";

import useCurrentCropYear from "hooks/useCurrentCropYear";
import { useFeatureAndPermissionAccess } from "hooks/useFeatureAndPermissionAccess";

import ActivitiesBlankStatePage from "components/advertisements/pages/ActivitiesBlankStatePage";
import { Stack } from "components/fl-ui/Layout";
import Container from "components/fl-ui/Layout/Container";
import { ErrorComponent } from "components/fl-ui/Layout/ErrorBoundary";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";
import { RestrictedAccessWithHeader } from "components/fl-ui/RestrictedAccessWithHeader";
import { Spacing } from "components/fl-ui/constants";

const ErrorFallbackComponent = () => (
  <Container>
    <ActivityPageHeader />
    <ErrorComponent />
  </Container>
);

const withActivityProviders = (Component) => (props) =>
  (
    <ActivityFilterProvider>
      <ActivityListProvider>
        <BulkSelectionProvider>
          <Component {...props} />
        </BulkSelectionProvider>
      </ActivityListProvider>
    </ActivityFilterProvider>
  );

const ActivityIndexPage = withErrorBoundary(
  withActivityProviders(() => {
    const cropYear = useCurrentCropYear()[0];
    const { loading, totalFilteredActivities } = useActivitiesProvider();
    useDocumentTitle("Activities")

    const { loadingAccess, showPaygate, showRestricted, showContent } = useFeatureAndPermissionAccess({
      featureName: "activities",
      permissionName: "activities",
    });

    if (showRestricted) {
      return <RestrictedAccessWithHeader title="Activities" />;
    }

    return (
      <Container>
        <LoadingWrapper isLoading={loadingAccess}>
          {showPaygate && <ActivitiesBlankStatePage />}
          {showContent && (
            <>
              <ActivityPageHeader showActivityCount={showContent} />
              <Stack space={Spacing.large}>
                <ActivityFilterBar />
                <ActivitySummary />

                <LoadingWrapper isLoading={loading} loadingText={`Loading activities for ${cropYear}…`}>
                  {totalFilteredActivities === 0 && <BlankSlate totalActivities={totalFilteredActivities} />}
                  {totalFilteredActivities > 0 && (
                    <>
                      <ActivityBulkActions />
                      <ActivityIndexBody />
                    </>
                  )}
                </LoadingWrapper>
              </Stack>
            </>
          )}
        </LoadingWrapper>
      </Container>
    );
  }),
  { fallback: ErrorFallbackComponent }
);

export default ActivityIndexPage;
