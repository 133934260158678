import { StyleSheet, css } from "aphrodite/no-important";
import { isEmpty } from "lodash";
import React from "react";
import { useNavigate } from "react-router-dom";

import useViewType from "hooks/useViewType";
import { ALERTS_DISMISS_ALL_CLICKED, ALERTS_VIEW_ALL_CLICKED, ALERTS_SETTINGS_ICON_CLICKED } from "lib/metrics/events";
import NotificationIcon from "modules/notifications/components/NotificationIcon";
import NotificationItem from "modules/notifications/components/NotificationItem";
import { withNotifications } from "modules/notifications/hoc/index";

import BlankSlate from "components/fl-ui/BlankSlate";
import DropDown from "components/fl-ui/DropDown";
import Icon from "components/fl-ui/Icons/Icon";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";
import ToolTip from "components/fl-ui/Tooltip";
import { GreyColors, UIColors } from "components/fl-ui/colors";
import { Mixins, Spacing, Typography } from "components/fl-ui/constants";

const styles = StyleSheet.create({
  blankSlateHeaderText: {
    fontSize: Typography.sizes.xl,
    fontWeight: Typography.weights.medium,
    margin: "1em 0 0",
  },
  blankSlateBodyText: {
    lineHeight: "1.5em",
    margin: "1em 0",
    fontSize: Typography.sizes.lg,
  },
  blankSlateImage: {
    maxWidth: Mixins.toRem(40),
    margin: "auto",
  },
  errorText: {
    color: UIColors.red,
    marginBottom: Spacing.xxlarge,
    textAlign: "center",
  },
  menuFooter: {
    borderTop: `1px solid ${GreyColors.smoke90}`,
    padding: Spacing.regular,
    textAlign: "center",
  },
  menuFooterText: {
    color: UIColors.blue,
    textDecoration: "none",
    fontSize: Typography.sizes.rg,
    fontWeight: Typography.weights.medium,
    lineHeight: 1.357,
    ":hover": {
      color: UIColors.blue,
    },
  },
  menuHeader: {
    borderBottom: `1px solid ${GreyColors.smoke90}`,
    padding: Spacing.regular,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  menuTitle: {
    fontSize: Typography.sizes.lg,
    margin: 0,
  },
  menuContents: {
    height: "auto",
    maxHeight: Mixins.toRem(350),
    overflowY: "auto",
    boxShadow: "inset 0 1px 5px 0 rgba(0,0,0,0.05)",
  },
  menuSettings: {
    display: "flex",
  },
  iconSpacer: {
    width: Spacing.regular,
  },
  alertIcon: {
    width: Spacing.large,
    maxWidth: Spacing.large,
    height: Spacing.large,
    maxHeight: Spacing.large,
    cursor: "pointer",
  },
});

const NotificationMenu = (props) => {
  const { data, dismissAllNotifications, dismissNotification } = props;
  const { error, loading, notifications = [] } = data;
  const errorMessage = "An unexpected error occurred. Please try again later.";
  const target = <NotificationIcon count={notifications.length} />;

  const navigate = useNavigate();
  const handleDismissAllClick = () => {
    ALERTS_DISMISS_ALL_CLICKED.track({});
    dismissAllNotifications();
  };
  const handleViewAllClick = () => {
    ALERTS_VIEW_ALL_CLICKED.track({});
    navigate("/alerts");
  };
  const handleSettingsClick = () => {
    ALERTS_SETTINGS_ICON_CLICKED.track({});
    navigate("/settings/alerts");
  };

  const isDesktop = useViewType() === "desktop";

  return (
    <div>
      {!isDesktop && <div onClick={handleViewAllClick}>{target}</div>}
      {isDesktop && (
        <DropDown width={360} height={500} target={target} offset={24}>
          <div className={css(styles.menuHeader)}>
            <h2 className={css(styles.menuTitle)}>Alerts</h2>
            <div className={css(styles.menuSettings)}>
              <ToolTip message="Dismiss All">
                <Icon className={css(styles.alertIcon)} icon="dismissAll" onClick={handleDismissAllClick} />
              </ToolTip>
              <div className={css(styles.iconSpacer)} />
              <ToolTip message="Settings">
                <Icon className={css(styles.alertIcon)} icon="settings" onClick={handleSettingsClick} />
              </ToolTip>
            </div>
          </div>

          <LoadingWrapper isLoading={loading} loadingText="Loading your alerts...">
            {error && <p className={css(styles.errorText)}>{errorMessage}</p>}

            {isEmpty(notifications) ? (
              <BlankSlate>
                <Icon icon="alertInactive" className={css(styles.blankSlateImage)} />

                <p className={css(styles.blankSlateHeaderText)}>{`You're all caught up!`}</p>

                <p className={css(styles.blankSlateBodyText)}>{`When new alerts arrive they'll display here.`}</p>
              </BlankSlate>
            ) : (
              <div>
                <div className={css(styles.menuContents)}>
                  {notifications.slice(0, 10).map((notification) => (
                    <NotificationItem
                      dismissNotification={dismissNotification}
                      key={notification.id}
                      notification={notification}
                    />
                  ))}
                </div>

                <div className={css(styles.menuFooter)}>
                  <a className={css(styles.menuFooterText)} onClick={handleViewAllClick}>
                    View all alerts
                  </a>
                </div>
              </div>
            )}
          </LoadingWrapper>
        </DropDown>
      )}
    </div>
  );
};

export default withNotifications(NotificationMenu);
