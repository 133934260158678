import useDocumentTitle from "app/hooks/useDocumentTitle";
import { LeftButton } from "profit_and_loss/styles";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-styled-flexboxgrid";
import DataImportRow from "settings/components/DataImportRow";
import FieldBoundariesUpload from "settings/components/FieldBoundariesUpload";
import YieldUpload from "settings/components/YieldUpload";
import { Container, ResponsiveHeading, StyledText } from "settings/styledComponents";

import { authHeaders } from "collection/graphql/client/common";
import App from "layout/app";

import { Button } from "components/fl-ui";

const FIELD_BOUNDARIES = "fieldBoundaries";
const YIELD_DATA = "yieldData";

const DataImportExport = ({ initialModalId }) => {
  const navigate = useNavigate();
  const [activeModal, setActiveModal] = useState(() => {
    if (["fieldBoundaries", "yieldData"].includes(initialModalId)) {
      return initialModalId;
    } else {
      return null;
    }
  });
  const onClose = () => {
    navigate("/settings/data", { replace: true });
    setActiveModal(null);
  };
  useDocumentTitle("Data Settings");

  const handleFieldBoundariesExport = () => {
    const headers = authHeaders();
    const link = document.createElement("a");

    fetch("/v2.0/export/field_boundaries", {
      method: "get",
      headers: headers,
    })
      .then((resp) => {
        let filename = "";
        const disposition = resp.headers.get("Content-Disposition");
        if (disposition && disposition.indexOf("attachment") !== -1) {
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = filenameRegex.exec(disposition);
          if (matches?.[1]) filename = matches[1].replace(/['"]/g, "");
        }
        link.download = filename || "fields.zip";
        return resp.blob();
      })
      .then((blob) => {
        const urlObj = URL.createObjectURL(blob);
        link.href = urlObj;
        link.click();
        setTimeout(function () {
          URL.revokeObjectURL(urlObj);
        }, 100); // cleanup
      })
      .catch((error) => {
        App.notify("An error occurred, please try again.");
        throw error;
      });
  };

  return (
    <>
      <Row>
        <Col xs={12}>
          <Container>
            <div>
              <ResponsiveHeading>Data import & export</ResponsiveHeading>
              <StyledText>Move information in or out of your account manually with files.</StyledText>
            </div>
          </Container>
        </Col>
      </Row>

      <DataImportRow
        copy="Zipped shapefiles contain the boundaries of your fields. Upload them here to import your field boundaries into Bushel Farm, or download to export a shapefile of all your boundaries in Bushel Farm."
        icon="fields"
        title="Field Boundary Shapefiles"
      >
        <LeftButton color="primary" hollow onClick={() => setActiveModal(FIELD_BOUNDARIES)}>
          Upload
        </LeftButton>
        <a className="btn btn-hollow btn-primary" onClick={handleFieldBoundariesExport}>
          Download
        </a>
      </DataImportRow>
      {activeModal === FIELD_BOUNDARIES && <FieldBoundariesUpload onClose={onClose} />}

      <DataImportRow
        copy="Upload zipped files from equipment displays to import yield, as-applied, or as-planted data generated by your machines."
        icon="harvesting"
        title="Equipment or Machine Data"
      >
        <Button color="primary" hollow onClick={() => setActiveModal(YIELD_DATA)}>
          Upload
        </Button>
      </DataImportRow>
      {activeModal === YIELD_DATA && <YieldUpload onClose={onClose} />}

      <DataImportRow
        copy="Export your fields, crops, activities, input applications, sales, and harvest loads as CSV files."
        icon="people"
        title="Account data"
      >
        <a className="btn btn-hollow btn-primary" href={`/${API_VERSION}/export/user_data`}>
          Download
        </a>
      </DataImportRow>
    </>
  );
};

DataImportExport.propTypes = {
  initialModalId: PropTypes.oneOf([FIELD_BOUNDARIES, YIELD_DATA]),
};

export default DataImportExport;
