import _ from "lodash";
import PropTypes from "prop-types";
import React, { useState } from "react";

import { marketingClient } from "collection/graphql/client";
import { getProducts } from "collection/graphql/products/queries";
import useProductRecipes from "collection/graphql/recipes/hooks/useProductRecipes";

import RecipesCrud from "components/product_recipe/crud";
import Chooser from "components/ui/chooser";

/*
 * CORE-5941: when creating a new recipe, the ingredient product is not always populated.
 * The real fix for this is to migrate recipes to graphql. In the meantime we will ensure
 * that the ingredient product is populated by querying for it an allowing apollo to save it
 * in the cache.
 */
const hydrateRecipeIngredients = async (recipe) => {
  recipe = _.cloneDeep(recipe);
  const ingredientsById = _.keyBy(recipe.ingredients, "productId");
  const { data } = await marketingClient.query({
    query: getProducts,
    variables: { productIds: _.keys(ingredientsById) },
  });
  data.products.forEach((product) => {
    ingredientsById[product.id].product = product;
  });

  return recipe;
};

const ProductChooser = ({ className, id, onSelect, placeholder, style }) => {
  const { recipes } = useProductRecipes();
  const [showCrud, setShowCrud] = useState(false);

  const onRecipeCreate = async (recipe) => {
    setShowCrud(false);
    if (recipe) {
      onSelect(await hydrateRecipeIngredients(recipe));
    }
  };

  return (
    <>
      <Chooser
        id={id}
        className={className}
        label="product_recipe"
        name="product_recipe_id"
        placeholder={placeholder}
        onChange={({ value }) => onSelect(value)}
        displayValue={false}
        style={style}
        onAdd={() => setShowCrud(true)}
      >
        {recipes.map((recipe) => (
          <Chooser.Option key={recipe.id} value={recipe} title={recipe.description}>
            {recipe.name}
          </Chooser.Option>
        ))}
      </Chooser>

      {showCrud && <RecipesCrud onClose={() => setShowCrud(false)} onFinish={onRecipeCreate} />}
    </>
  );
};

ProductChooser.propTypes = {
  className: PropTypes.string,
  excludeErrorBoundary: PropTypes.bool,
  id: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  style: PropTypes.object,
};

ProductChooser.defaultProps = {
  className: "btn",
  excludeErrorBoundary: false,
  placeholder: "Select Mix...",
};

export default ProductChooser;
