import React from "react";
import ProfitAndLossDashboard from "profit_and_loss/pages/DashboardContainer";
import ExpenseRevenueDashboardContainer from "profit_and_loss/components/ExpenseRevenueDashboardContainer";


export default [
  {
    path: "/other_expenses",
    element: <ExpenseRevenueDashboardContainer type="EXPENSE" />
  },

  {
    path: "/other_revenue",
    element: <ExpenseRevenueDashboardContainer type="REVENUE" />
  },

  {
    path: "/profit_and_loss",
    element: <ProfitAndLossDashboard />,
  }
];
