import PropTypes from "prop-types";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { Col, Row } from "react-styled-flexboxgrid";
import useBushelConnectLink from "settings/components/Integrations/hooks/useBushelConnectLink";
import {
  BorderedContainer,
  Container,
  Content,
  DataRowContentContainer,
  IntegrationImage,
  StyledLogo,
  StyledText,
  TopErrorText,
} from "settings/styledComponents";
import styled from "styled-components";

import useBoolean from "hooks/useBoolean";

import { Icon } from "components/fl-ui/Icons";
import { Spacing } from "components/fl-ui/constants";

const getMachineDataConnectionLink = (integrationId, { disabled, onClick, url }) => {
  const reconnectText = "click here";
  switch (integrationId) {
    case "bushel":
      return (
        <a data-source="Integrations" disabled={disabled} href={url} onClick={onClick}>
          {reconnectText}
        </a>
      );
    case "climate":
      return <a href={`https://${window.location.host}/v2.0/integrations/climate/connect`}>{reconnectText}</a>;
    case "cnhi":
      return <a href={`https://${window.location.host}/v2.0/integrations/cnhi/connect`}>{reconnectText}</a>;
    case "myjohndeere":
      return <a href={`https://${window.location.host}/v2.0/integrations/myjohndeere/connect`}>{reconnectText}</a>;
  }
};

const getMachineDataErrorText = (integrationId, status, bushelConnectLinkPayload) => {
  switch (status) {
    case "UNAUTHENTICATED":
      return (
        <span>
          {`Your connection has been disrupted. Please `}
          {getMachineDataConnectionLink(integrationId, bushelConnectLinkPayload)}
          {` to reconnect.`}
        </span>
      );
    case "MISSING_PERMISSION":
      return (
        <span>
          A permission error occurred. Please ensure Bushel Farm is configured with the correct permissions in your
          provider account. Reference{" "}
          <a href="https://support.bushelfarm.com/hc/en-us/articles/25923593170708-Machine-Data-Partner-Permissions">
            this support article
          </a>{" "}
          for more information.
        </span>
      );
    case "OK":
      return null;
    case null:
      return null;
    default:
      return (
        <span>
          An error occurred with your connection. Please contact{" "}
          <a href="mailto:support@bushelfarm.com">support@bushelfarm.com</a> for further assistance.
        </span>
      );
  }
};

const CommonIntegrationDataRow = styled(({ className, children, copy, imgDisabled = false, imgSrc, integration }) => {
  const [errorTextDismissed, { on }] = useBoolean(false);
  const [searchParams] = useSearchParams();
  const hasQueryParamStatus = searchParams.get("integration_id") === integration?.id;
  const machineDataErrorText = getMachineDataErrorText(
    integration.id,
    hasQueryParamStatus ? searchParams.get("integration_status") : integration.status,
    useBushelConnectLink()
  );
  const showErrorText = integration?.enabled && !errorTextDismissed && !!machineDataErrorText;

  return (
    <Row className={className}>
      <Col xs={12}>
        <BorderedContainer>
          {showErrorText && (
            <TopErrorText>
              <Icon className="warning error-text-icon" icon="warningOutline" />
              <div>{machineDataErrorText}</div>
              <Icon className="close error-text-icon" icon="close" onClick={on} />
            </TopErrorText>
          )}
          <Container>
            <Content>
              {imgSrc && (
                <StyledLogo>
                  <IntegrationImage disabled={imgDisabled} src={imgSrc} />
                </StyledLogo>
              )}

              <div>
                <StyledText>
                  <strong>{integration?.title}</strong>
                </StyledText>
                <StyledText>{copy}</StyledText>
              </div>
            </Content>

            <DataRowContentContainer>{children}</DataRowContentContainer>
          </Container>
        </BorderedContainer>
      </Col>
    </Row>
  );
})`
  .error-text-icon {
    color: black;
    display: inline-block;
    height: 1.3rem;
    margin-top: ${Spacing.xxxsmall};
    vertical-align: middle;
    width: 1.3rem;

    &.warning {
      margin-right: ${Spacing.xsmall};
    }
  }
`;

CommonIntegrationDataRow.propTypes = {
  children: PropTypes.any,
  copy: PropTypes.string,
  imgDisabled: PropTypes.bool,
  imgSrc: PropTypes.string,
  integration: PropTypes.object.isRequired,
};

export default CommonIntegrationDataRow;
