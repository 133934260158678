import React from "react";
import CommonEnrollmentPage, { enrollmentPageLoader } from "carbon_enrollment/pages/CommonEnrollmentPage";

export default [
  {
    path: "/carbon_enrollment/:clientId",
    element: <CommonEnrollmentPage />,
    loader: enrollmentPageLoader,
  },
];
