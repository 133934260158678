import { useReactiveVar } from "@apollo/client";
import PropTypes from "prop-types";
import React from "react";

import { historicalRainfall } from "collection/graphql/fields/rainfall";

import RainfallBadge from "fields/components/rainfall_badge";

const RainfallIndicator = ({ disableTip, fieldId }) => {
  const recent = useReactiveVar(historicalRainfall)?.get?.(fieldId);
  return !recent ? null : <RainfallBadge event={recent} disableTip={disableTip} />;
};

RainfallIndicator.propTypes = {
  disableTip: PropTypes.bool,
  fieldId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default RainfallIndicator;
