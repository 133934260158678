import React from "react";
import { Link } from "react-router-dom";

import CreateAgreementButton from "modules/agreements/components/CreateAgreementButton";

import Icon from "components/fl-ui/Icons/Icon";
import { css, StyleSheet } from "components/fl-ui/aphrodite";
import { GreyColors } from "components/fl-ui/colors";

const styles = StyleSheet.create({
  container: {
    lineHeight: 1.5,
    margin: "auto",
    maxWidth: 560,
    paddingTop: 100,
    textAlign: "center",
  },
  icon: {
    color: GreyColors.smoke91,
    display: "inline-block",
    height: 68,
    textAlign: "center",
    width: 72,
  },
});

const Title = ({ total }) => <h2>{total > 0 ? "No matching agreements" : "Add an agreement to get started"}</h2>;

const Body = ({ total }) => {
  if (total > 0) {
    return <p>Refine your filters to see agreements.</p>;
  }

  return (
    <p>
      Agreements are used to track land agreement terms, recurring costs, and non-crop revenue streams. Users{" "}
      <Link to="/billing">
        subscribed to a plan including the Reports feature
      </Link>{" "}
      can also generate Flex Rent, Crop Share, and Land Cost reports.
    </p>
  );
};

const BlankState = (props) => {
  const { totalAgreements } = props;

  return (
    <div className={css(styles.container)}>
      <div className={css(styles.icon)}>
        <Icon icon="landCosts" />
      </div>

      <Title total={totalAgreements} />
      <Body total={totalAgreements} />
      {totalAgreements === 0 && <CreateAgreementButton />}
    </div>
  );
};

export default BlankState;
