import React from "react";
import DashboardPage from "reports/routes/Dashboard";
import ReportPage from "reports/routes/Report";


export default [
  {
    path: "/reports",
    element: <DashboardPage />,
  },

  {
    path: "/reports/:reportId",
    element: <ReportPage />,
  },
];
