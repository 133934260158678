"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const AuthContextProvider_1 = require("collection/graphql/auth/hooks/AuthContextProvider");
const useAuth = () => {
    const auth = (0, react_1.useContext)(AuthContextProvider_1.authContext);
    if (!auth) {
        throw new Error("Authorization context required");
    }
    return auth;
};
exports.default = useAuth;
