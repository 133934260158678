import { gql } from "@apollo/client";

export default gql`
  mutation ImportFields($input: ImportFieldFromFileInput!) {
    importFields(input: $input) {
      ok
      message
    }
  }
`;
