import React from "react";
import useBushelConnectLink from "settings/components/Integrations/hooks/useBushelConnectLink";
import styled from "styled-components";

import { useFarmAdvisorConfig } from "collection/graphql/advisor";

import { ColorPalette } from "components/fl-ui/colors";
import { Spacing, Typography } from "components/fl-ui/constants";

const MarketingCardVariant = styled(({ className }) => {
  const { disabled, onClick, url } = useBushelConnectLink();
  const {
    data: { isEnabled, programName },
  } = useFarmAdvisorConfig();

  return (
    <div>
      <div className={className}>
        <img
          alt="Bushel Logo"
          className="bushel-logo"
          height="100"
          src="/images/layout/bushel-logo-icon.svg"
          width="80"
        />

        <div>
          <h3>Reduce your manual data entry</h3>
          <p>
            {isEnabled
              ? `Connect now to finish setting up your ${programName} Bushel Farm account. Connecting will automatically import your contracts from buyers in the Bushel Network and keep your Marketing up-to-date.`
              : "Import your contracts from grain buyers in the Bushel network."}{" "}
            <a href="https://go.bushelfarm.com/connect-to-bushel-network">Learn more</a>
          </p>
        </div>
      </div>

      <a className="btn btn-primary" data-source="MarketingCard" disabled={disabled} href={url} onClick={onClick}>
        Import your grain contracts
      </a>
    </div>
  );
})`
  align-items: center;
  display: flex;
  gap: ${Spacing.xxlarge};
  height: auto;
  margin-bottom: ${Spacing.xxlarge};

  img {
    width: 80px;
  }
  p {
    font-size: ${Typography.sizes.lg};
    font-weight: ${Typography.weights.regular};
    a {
      color: ${ColorPalette.blue};
    }
  }

  & + a {
    display: block;
    text-align: center;
  }
`;

export { MarketingCardVariant };
