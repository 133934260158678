import d3 from "d3";
import leaflet from "leaflet";
import _ from "lodash";
import { isNative, geolocationClearWatch, geolocationWatchPosition } from "mobile/mobileManager";

import SatelliteLayer from "lib/map/layers/satellite";
import MapUtils from "lib/map/utils";

const getScale = (zoom) => (256 / Math.PI / 2) * Math.pow(2, zoom);

const project = function () {
  const center = this.getCenter();
  const centerPoint = this._getCenterLayerPoint();

  this.projection
    .scale(getScale(this.getZoom()))
    .center([center.lng, center.lat])
    .translate([centerPoint.x, centerPoint.y]);
};

const clip = function () {
  const center = this._getCenterLayerPoint();
  const size = this.getSize();
  const width = size.x / 2;
  const height = size.y / 2;

  this.projection.clipExtent([
    [center.x - width, center.y - height],
    [center.x + width, center.y + height],
  ]);
};

export default leaflet.Map.extend({
  initialize(element, options = {}) {
    if (options == null) {
      options = {};
    }
    const isLayerOnly = !!options.isLayerOnly;
    options = _.omit(options, ["isLayerOnly"]);
    // Needs to be re-initialized in initialization for split map to work (creating new layers);
    const satelliteMap = new SatelliteLayer({ isLayerOnly });

    const zoom = Math.round(options.zoom) || null;
    options = _.defaults({ zoom }, options, {
      attributionControl: false,
      bounceAtZoomLimits: false,
      center: [39.833333, -98.583333],
      maxBounds: leaflet.latLngBounds([85, -180], [-85, 180]),
      maxZoom: 19,
      noWrap: true,
      zoom: 5,
      zoomControl: !isNative(),
    });

    this.projection = d3.geo.mercator();

    leaflet.Map.prototype.initialize.call(this, element, options);

    // Add attribution unless noAttribution is set to true
    if (!options?.noAttribution) {
      leaflet.control.attribution({ prefix: false }).addTo(this).addAttribution(`
          © <a href="https://www.mapbox.com/about/maps/" target="_blank">Mapbox</a>
          © <a href="http://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a>
          <strong>
            <a href="https://www.mapbox.com/map-feedback/" target="_blank">Improve this map</a>
          </strong>
        `);
      leaflet.control.attribution({ prefix: false, position: "bottomleft" }).addTo(this).addAttribution(`
          <a href="http://mapbox.com/about/maps" class='mapbox-wordmark' target="_blank">Mapbox</a>
        `);
    }

    this.setOptions(options);
    this.addLayer(satelliteMap);

    if (options.geometry) {
      this.fitBounds(MapUtils.getBoundsFromGeometry(options.geometry), {
        padding: new leaflet.Point(50, 50),
      });
    }

    if (options.maxBounds instanceof leaflet.LatLngBounds) {
      this.fitBounds(options.maxBounds);
      this.setView(options.center, options.zoom);
    }

    project.call(this);
    clip.call(this);
    this.setData();

    const layout = document.getElementById("layout");
    leaflet.DomEvent.on(window, "resize", this.resize, this);
    leaflet.DomEvent.on(layout, "transitionend", this.resize, this);
    leaflet.DomEvent.on(layout, "webkitTransitionEnd", this.resize, this);
    leaflet.DomEvent.on(layout, "oTransitionEnd", this.resize, this);
    leaflet.DomEvent.on(layout, "MSTransitionEnd", this.resize, this);

    leaflet.DomEvent.on(this._container, "transitionend", leaflet.DomEvent.stopPropagation);
    leaflet.DomEvent.on(this._container, "webkitTransitionEnd", leaflet.DomEvent.stopPropagation);
    leaflet.DomEvent.on(this._container, "oTransitionEnd", leaflet.DomEvent.stopPropagation);
    leaflet.DomEvent.on(this._container, "MSTransitionEnd", leaflet.DomEvent.stopPropagation);

    if (options.zoomControl) {
      this.zoomControl.setPosition("bottomright");
    }

    this.on("viewreset", project, this);
    this.on("moveend", clip, this);
    this.on("search:clear", this.removeMarker);
    this.on("search:done", this.setSearchLocation);
    this.on("moveend", this.setData);
    this.on("locationfound", this.updateLocation);

    if (!options?.ignoreLocation) {
      this.getUserLocation();
    }
  },

  setOptions(options) {
    // handle zoom bug for maxBounds (https://github.com/Leaflet/Leaflet/issues/1475)
    if (options.maxBounds && !options.minZoom) {
      this.options.minZoom = 2;
      options = leaflet.extend({ minZoom: this.getBoundsZoom(options.maxBounds) }, options);
    }

    return leaflet.Util.setOptions(this, options);
  },

  setData() {
    const { lat, lng } = this.getCenter();
    const container = this.getContainer();

    container.dataset.center = `${lat},${lng}`;
    return (container.dataset.zoom = this.getZoom());
  },

  addMarker(latLng) {
    this._marker = leaflet.marker(latLng);
    return this.addLayer(this._marker);
  },

  removeMarker() {
    if (this._marker) {
      return this.removeLayer(this._marker);
    }
  },

  addGeoJsonLayer(data, style) {
    if (data) {
      return leaflet.geoJson(data, style).addTo(this);
    }
  },

  resize() {
    project.call(this);
    clip.call(this);
    this.invalidateSize({ pan: false });
    return this;
  },

  getPathRoot() {
    return this._pathRoot;
  },

  toggleBackdrop(backdropOn) {
    const color = backdropOn ? "rgb(46,49,56)" : null;
    const opacity = backdropOn ? 0.3 : 1;

    // set map bgColor to black
    this.getContainer().style.backgroundColor = color;
    // set tile layer opacity
    return this.eachLayer(function (layer) {
      if (layer instanceof leaflet.TileLayer) {
        return layer.setOpacity(opacity);
      }
    });
  },

  setSearchLocation({ bounds, location }) {
    // move to searched location
    this.fitBounds(bounds);
    this.panTo(location);
    // set marker
    this.removeMarker();
    return this.addMarker(location);
  },

  remove() {
    const layout = document.getElementById("layout");
    leaflet.DomEvent.off(window, "resize", this.resize, this);
    leaflet.DomEvent.off(layout, "transitionend", this.resize, this);
    leaflet.DomEvent.off(layout, "webkitTransitionEnd", this.resize, this);
    leaflet.DomEvent.off(layout, "oTransitionEnd", this.resize, this);
    leaflet.DomEvent.off(layout, "MSTransitionEnd", this.resize, this);

    leaflet.DomEvent.off(this._container, "transitionend", leaflet.DomEvent.stopPropagation);
    leaflet.DomEvent.off(this._container, "webkitTransitionEnd", leaflet.DomEvent.stopPropagation);
    leaflet.DomEvent.off(this._container, "oTransitionEnd", leaflet.DomEvent.stopPropagation);
    leaflet.DomEvent.off(this._container, "MSTransitionEnd", leaflet.DomEvent.stopPropagation);

    this.eachLayer(this.removeLayer, this);
    this.off();
    this.stopLocate();
    this.locationWatcherMobile && geolocationClearWatch(this.locationWatcherMobile);

    return leaflet.Map.prototype.remove.call(this);
  },

  getUserLocation() {
    const options = { enableHighAccuracy: true, maximumAge: 10000, timeout: 10000 };

    if (this.userLocationMarker) {
      return this.userLocationMarker.getLatLng();
    }

    if (isNative()) {
      this.locationWatcherMobile = geolocationWatchPosition(options, (geoPosition) => {
        if (geoPosition?.coords) {
          this.updateLocation(geoPosition.coords);
        }
      });
    } else {
      this.locate({ watch: true, ...options });
    }
  },

  updateLocation(coords) {
    const { latitude: lat, longitude: lng } = coords;
    const previousLatLng = this.userLocationMarker?.getLatLng();

    if (previousLatLng?.lat !== lat || previousLatLng?.lng !== lng) {
      if (this.userLocationMarker) {
        this.userLocationMarker.setLatLng({ lat, lng });
      } else {
        this.userLocationMarker = leaflet
          .marker(
            { lat, lng },
            {
              icon: leaflet.icon({
                iconSize: [20, 20],
                iconUrl: "images/map/location.png",
              }),
            }
          )
          .addTo(this);
      }
    }

    return this.userLocationMarker.getLatLng();
  },
});
