import React from "react";
import { useNavigate } from "react-router-dom";

import { Button } from "components/fl-ui";

const CreateAgreementButton = (props) => {
  const navigate = useNavigate();
  return (
    <Button color="primary" onClick={() => navigate("/land_costs/add")} style={props.style}>
      Add agreement
    </Button>
  );
};

export default CreateAgreementButton;
