import { datadogLogs } from "@datadog/browser-logs";
import useAnalyticsSetup from "app/hooks/useAnalyticsSetup";
import useDocumentTitle from "app/hooks/useDocumentTitle";
import useMobileListeners from "app/hooks/useMobileListeners";
import useODICEvents from "app/hooks/useODICEvents";
import useSentrySetup from "app/hooks/useSentrySetup";
import { debug } from "debug";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import { useAuth as useSSOAuth } from "react-oidc-context";

import { useAuth } from "collection/graphql/auth";
import logout from "collection/graphql/auth/lib/logout";
import { ssoUserVar } from "collection/graphql/client/common";
//Seems like this is another place that needs continued investigation too, can probably pull this from old PR
//import { loadFieldsIndexQueries } from "collection/graphql/prefetch";
//Here is a link to this file in the repo, not sure why it was removed and if it needs to come back in:
//https://dev.azure.com/bushelpowered/bushel-farm/_git/Web-Application/pullrequest/34751?_a=files&path=/src/collection/graphql/prefetch.js
import GlobalContextProvider from "context/GlobalContextProvider";
import useCurrentEnterprise from "hooks/useCurrentEnterprise";
import EventChannel from "lib/events";
import NotificationPopoverContainer from "modules/notifications/containers/NotificationPopoverContainer";

import PrimaryBanner from "components/banners/PrimaryBanner";
import Navigation from "components/fl-ui/Layout/Navigation";
import ContentContainer from "components/fl-ui/Layout/Navigation/ContentContainer";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";
import Toast from "components/fl-ui/Toast";
import LoggedOutModal from "components/modals/LoggedOutModal";

const logAuth = debug("auth");
// to enable auth logs in console: set localStorage.debug = "auth" or "*" & set Chromium-based browsers debugging level to verbose

const App = ({ children }) => {
  const auth = useSSOAuth();

  //TODO: This needs to be set in our new email check form
  const hasSSOFlag = true;

  const { hasInvalidated, isLoggedIn, isLoggingOut, loading: authIsLoading, refetch: authRefetch } = useAuth();

  // legacy auth enterprise
  const { currentEnterprise, loading: enterpriseLoading, refetch: enterpriseRefetch } = useCurrentEnterprise();
  const lastEnterpriseId = useRef();
  const hasEnterpriseChanged = !enterpriseLoading && lastEnterpriseId.current !== currentEnterprise?.id;

  if (hasEnterpriseChanged) {
    lastEnterpriseId.current = currentEnterprise?.id;
  }

  const isSyncing = [authIsLoading, isLoggingOut, enterpriseLoading && !lastEnterpriseId.current].some((x) => x);
  const loadingMessage = isLoggingOut ? `Logging out of Bushel Farm\u2026` : `Fetching data. Please wait\u2026`;

  useODICEvents();
  useDocumentTitle();
  useSentrySetup();
  useMobileListeners();
  useAnalyticsSetup();

  useEffect(() => {
    //Need to store this `hasSSOFlag` in local storage whenever the user goes through the email check.
    // every subsequent login it will be reset
    // (not checked again based on the previous state in case the user toggled users)
    if (hasSSOFlag) {
      if (!auth.isLoading) {
        if (auth.isAuthenticated) {
          ssoUserVar(auth.user);
          logAuth("app.jsx USER IS AUTH'D", ssoUserVar());

          if (_.isEqual({}, datadogLogs.getUser())) {
            datadogLogs.setUser({
              id: auth.user.profile.bushel_id,
              name: auth.user.profile.name,
              email: auth.user.profile.email,
            });
          }
          //TODO: Do we need to uncomment this? Why was it removed?
          //loadFieldsIndexQueries();
        }
        EventChannel.getChannel("app").fire("ready");
      }
    } else {
      // LEGACY AUTH

      const keycloakStorageString = `${process.env.KEYCLOAK_AUTH_ISSUER}:${process.env.KEYCLOAK_CLIENT_ID}`;
      // make sure the sso token is cleared from localStorage
      localStorage.removeItem(`oidc.user:${keycloakStorageString}`);
      localStorage.removeItem(`enterprise:${keycloakStorageString}`);

      if (isLoggedIn) {
        //TODO: Do we need to uncomment this? Why was it removed?
        //loadFieldsIndexQueries();
      }
      if (!isSyncing) {
        EventChannel.getChannel("app").fire("ready");
      }
    }
  }, [auth.isAuthenticated, auth.isLoading, isSyncing, isLoggedIn]);

  logAuth("app.jsx  auth.isAuthenticated", auth.isAuthenticated, "auth.isLoading", auth.isLoading);

  if (auth.isLoading) {
    return (
      <div id="layout">
        <div id="main" className="js-only">
          <div className="loading-placeholder">
            <h2>Loading...</h2>
            <div className="loading-spinner" />
          </div>
        </div>
      </div>
    );
  }

  if (auth.error) {
    window.history.replaceState({}, document.title, window.location.pathname + window.location.hash);
    logAuth(`Auth Error ${auth.error.message}`);
    logout();
  }

  return (
    <>
      <PrimaryBanner />
      <div id="appContent">
        <Navigation />
        {hasSSOFlag && (
          <>
            <ContentContainer>
              {auth.isLoading ? <LoadingWrapper loadingText={loadingMessage} /> : children}
            </ContentContainer>

            {auth.isAuthenticated && <NotificationPopoverContainer />}
            {auth.error && <LoggedOutModal />}
          </>
        )}

        {!hasSSOFlag && (
          <>
            <ContentContainer>
              {isSyncing ? <LoadingWrapper loadingText={loadingMessage} /> : children}
            </ContentContainer>
            {isLoggedIn && <NotificationPopoverContainer />}
            {hasInvalidated && <LoggedOutModal />}
          </>
        )}
        <Toast />
      </div>
    </>
  );
};

App.propTypes = {
  children: PropTypes.node,
};

export default ({ children }) => (
  <GlobalContextProvider>
    <App>{children}</App>
  </GlobalContextProvider>
);
