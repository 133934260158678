import React from "react";

import FieldMap from "map/FieldMap";
import LayersContainer from "map/layers/LayersContainer";



export default [
  {
    path: "/fields/add",
    element: <LayersContainer />,
  },

  {
    path: "/map",
    element: <FieldMap />,
  },

  {
    path: "/map/:id",
    element: <FieldMap />,
  },

  {
    path: "/map/:id/layers",
    element: <LayersContainer />,
  },
]
