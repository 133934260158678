import React from "react";
import DetailPage from "equipment/pages/DetailPage";
import IndexPage from "equipment/pages/IndexPage";


export default [
  {
    path: "/equipment",
    element: <IndexPage />,
  },

  {
    path: "/equipment/implement/:id",
    element: <DetailPage type="implement" />,
  },

  {
    path: "/equipment/tractor/:id",
    element: <DetailPage type="tractor" />,
  },
];
