import InventoryTransaction from "loads/components/InventoryTransaction";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Link } from "react-router-dom";

import useCommodities from "hooks/useCommodities";

import { Button } from "components/fl-ui";

const LoadRow = ({ model }) => {
  const { getCommodityById } = useCommodities();
  const [showModal, setShowModal] = useState(false);
  const load = model.toJSON();

  const destinationNodeDetails = model.getNodeDetails(load.destination_id) ?? {};
  const { node: destination, type: destinationType } = destinationNodeDetails;
  const handleEdit = () => {
    setShowModal(true);
  };

  return (
    <>
      <div className="load-row row-fluid" onClick={handleEdit}>
        <div className="col xs-12 sm-1">
          <div className="delivery-icon outgoing">
            <svg height="16px" version="1.1" viewBox="0 0 512 512" width="16px">
              <path d="M383.6,322.7L278.6,423c-5.8,6-13.7,9-22.4,9c-8.7,0-16.5-3-22.4-9L128.4,322.7c-12.5-11.9-12.5-31.3,0-43.2  c12.5-11.9,32.7-11.9,45.2,0l50.4,48.2v-217c0-16.9,14.3-30.6,32-30.6c17.7,0,32,13.7,32,30.6v217l50.4-48.2  c12.5-11.9,32.7-11.9,45.2,0C396.1,291.4,396.1,310.7,383.6,322.7z" />
            </svg>
          </div>
        </div>
        <div className="col xs-12 sm-2 date">{load.date}</div>
        <div className="col xs-12 sm-2 amount">{`${load.amount} ${load.amount_unit}`}</div>
        <div className="col xs-12 sm-2 crop">{getCommodityById(load.commodity_id)?.name}</div>
        <div className="col xs-12 sm-4 source-destination">
          {destination && (
            <Link to={`/inventory/${destinationType}/${destination.id}`} onClick={(e) => e.stopPropagation()}>
              {destination.get("name")}
            </Link>
          )}
        </div>
        <div className="col xs-12 sm-1 text-right">
          <Button className="btn btn-tiny" onClick={handleEdit}>
            Edit
          </Button>
        </div>
      </div>

      {showModal && <InventoryTransaction load={load} onClose={() => setShowModal(false)} />}
    </>
  );
};

LoadRow.propTypes = {
  model: PropTypes.object.isRequired,
};

export default LoadRow;
