import ProductDetailsPage from "products/pages/ProductDetailsPage";
import React from "react";
import ProductIndexPage from "products/pages/ProductIndexPage";


export default [
  {
    path: "/products",
    element: <ProductIndexPage />,
  },

  {
    path: "/products/:id",
    element: <ProductDetailsPage />,
  },
];
