import { useSuspenseQuery } from "@apollo/client";
import * as turf from "@turf/turf";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useReducer, useRef } from "react";

import { useUserConfig } from "collection/graphql/config";
import { getBasicFieldsData } from "collection/graphql/fields/queries";
import useViewType from "hooks/useViewType";
import MapUtils from "lib/map/utils";
import { CLU_SELECT_FIELD_DRAW_PRESS } from "lib/metrics/events";

import { getFeature, hasOverlap } from "components/field/utils";
import MapControl from "components/map/control";
import Fullscreen from "components/ui/fullscreen";
import Map from "components/ui/map";
import SearchControl from "fields/add/SearchControl";
import ClusLayer from "fields/add/clus_layer";
import DrawHandler from "fields/add/draw_handler";
import { ControlButton, FieldForm, HelpBar } from "fields/add/helpers";
import CursorTracker from "fields/components/CursorTracker";

const AddMap = ({ children, clearMap, data, firstTime, isSaving, onChange, onExit, onReset, onSave, ready }) => {
  const { fields } = useSuspenseQuery(getBasicFieldsData).data;
  const userConfig = useUserConfig();
  const formPosition = useViewType() === "mobile" ? "bottom" : "middle";

  const handleMove = ({ target }) => {
    const { lat, lng } = target.getCenter();
    updateState({ center: `${lat},${lng}`, zoom: target.getZoom() });
  };

  const [state, updateState] = useReducer(
    (state, newState) => ({
      ...state,
      ...newState,
    }),
    {},
    () => {
      const { center, zoom } = userConfig("mapBounds");

      return {
        center,
        drawMode: !!data.geometry,
        drawTool: "Polygon",
        mapDefaults: {
          center: center.split(","),
          zoom,
        },
        mapEvents: [["moveend", handleMove]],
        zoom,
      };
    }
  );

  const lastDrawMode = useRef(state.drawMode);
  useEffect(() => {
    if (state.drawMode !== lastDrawMode.current) {
      CLU_SELECT_FIELD_DRAW_PRESS.track();
      lastDrawMode.current = state.drawMode;
    }

    if (clearMap) {
      if (state.drawMode) {
        toggleDraw();
      }
      onReset();
    }

    return () => {
      userConfig("mapBounds", {
        center: state.center,
        zoom: state.zoom,
      });
    };
  }, [state.drawMode]);

  const toggleDraw = () => {
    updateState({
      drawMode: !state.drawMode,
      drawTool: "Polygon",
    });
  };

  const handleFormChange = ({ target: { name, value } }) => {
    return onChange({ [name]: value });
  };

  const sanitizeLayer = (layer) => {
    // takes a layer or a geometry
    const feature = layer?.toGeoJSON ? layer.toGeoJSON() : layer;
    const fieldId = data.id || "_tempLayerId";
    const sanitizedData = { geometry: feature?.geometry ?? feature, layer };

    const intersectingFields = fields.filter(({ geometry, id }) => {
      const isPoint = geometry?.type === "Point";
      const overlapFeature = layer && fieldId !== id && !isPoint && turf.intersect(feature, geometry);
      return !!overlapFeature && hasOverlap(MapUtils.calculateAcreage(overlapFeature), feature, geometry);
    });

    if (intersectingFields.length) {
      sanitizedData.message = "This shape overlaps with an existing boundary";
      sanitizedData.temporaryFeatures = intersectingFields.map(({ geometry, id, name }) =>
        turf.feature(geometry, { id, name })
      );
    }

    return sanitizedData;
  };

  const handleGeoChange = (geometry, geometricCircumference) => {
    const acreage = geometry ? MapUtils.calculateAcreage(geometry) : null;
    onChange({ acreage, geometry, geometricCircumference: geometricCircumference ?? null });
  };

  const handleCancel = () => {
    if (data.id) {
      return onExit();
    }
    updateState({ drawMode: false });
    return onReset();
  };

  const isReady = () => !data.geometry && !!ready && !state.drawMode;

  const onDraw = (drawTool) => {
    updateState({ drawMode: true, drawTool });
  };

  const updateLocation = (location) => updateState({ location });
  const { acreage, geometry } = data;
  const feature = useMemo(() => {
    const { geometry, geometricCircumference, id } = data;
    const fieldId = id || (geometry && "_tempLayerId");
    return getFeature({ geometry, geometricCircumference, id: fieldId });
  }, [data]);

  return (
    <Fullscreen id="map-container" showBottomBar={!!isReady()}>
      <Map
        id="map_canvas"
        defaultValue={state.mapDefaults}
        isSearchMode={isReady()}
        location={state.location}
        on={state.mapEvents}
      >
        {state.drawMode ? (
          <DrawHandler
            drawTool={state.drawTool}
            feature={feature}
            onBack={toggleDraw}
            onCancelEdit={handleCancel}
            onChange={handleGeoChange}
            sanitizeLayer={sanitizeLayer}
          />
        ) : (
          <ClusLayer geometry={geometry} onEdit={toggleDraw} onSelect={handleGeoChange} sanitizeLayer={sanitizeLayer} />
        )}
        {isReady() && (
          <MapControl position="top">
            <div className="col xs-2 sm-4 md-7 lg-9">
              {!firstTime && <ControlButton icon="chevronLeft" label="Exit Field Adding" onClick={onExit} />}
            </div>

            <div className="col xs-10 sm-8 md-5 lg-3">
              <SearchControl onChange={updateLocation} showHelp={firstTime} />
            </div>
          </MapControl>
        )}
        {!!geometry && (
          <MapControl position={formPosition}>
            <div className="col xs-12 sm-6 sm-offset-3 md-4 md-offset-7 lg-3 lg-offset-8">
              <FieldForm
                data={data}
                isSaving={isSaving}
                onCancel={handleCancel}
                onChange={handleFormChange}
                onSave={onSave}
              />
            </div>
          </MapControl>
        )}
      </Map>

      {isReady() && <HelpBar zoom={state.zoom} onDraw={onDraw} />}
      {children}

      <CursorTracker>{acreage && `${acreage} ac`}</CursorTracker>
    </Fullscreen>
  );
};

AddMap.propTypes = {
  children: PropTypes.element,
  clearMap: PropTypes.bool,
  data: PropTypes.object,
  error: PropTypes.object,
  firstTime: PropTypes.bool,
  isSaving: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onExit: PropTypes.func,
  onReset: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  ready: PropTypes.bool,
  status: PropTypes.object,
};

AddMap.defaultProps = {
  clearMap: false,
  data: {},
  error: {},
  onChange: null,
  onSave: null,
  ready: true,
};

export default AddMap;
