import React, { useState } from "react";
import DeactivateUserButton from "settings/components/DeactivateUserButton";
import PasswordChangeForm from "settings/pages/Personal/forms/PasswordChangeForm";

import { useAuth } from "collection/graphql/auth";
import { GET_CURRENT_USER } from "collection/graphql/auth/queries";
import { updatePerson } from "collection/graphql/people/mutations";
import { LegacyPersonSchema } from "collection/graphql/people/schemas/LegacyPersonSchema";
import useRestMutation from "hooks/useRestMutation";
import App from "layout/app";

import { Form, Input } from "components/fl-ui/Form/index";

const PersonalSettingsFormSchema = LegacyPersonSchema.pick(["firstName", "lastName", "email", "phone"]);

const PersonalSettings = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { currentUser } = useAuth();
  const [person, setPerson] = useState(() => {
    return PersonalSettingsFormSchema.cast(currentUser, { assert: false, stripUnknown: true });
  });
  const savePerson = useRestMutation(updatePerson, {
    refetchQueries: [GET_CURRENT_USER],
  })[0];

  const errorClass = (field) => (getError(field) ? "error" : "");
  const formIsValid = PersonalSettingsFormSchema.isValidSync(person);
  const getError = (field) => {
    try {
      PersonalSettingsFormSchema.validateSyncAt(field, person);
    } catch (error) {
      return error.message;
    }
  };

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setPerson({
      ...person,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const input = LegacyPersonSchema.cast(
      {
        ...currentUser,
        ...person,
      },
      { stripUnknown: true }
    );

    try {
      await savePerson({
        variables: { input },
      });
      App.notify("Changes saved.");
    } catch (error) {
      App.notify("An error occurred");
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div>
      <Form className="form-horizontal" onChange={handleFormChange} onSubmit={handleSubmit}>
        <h2 className="heading-underline">Personal Information</h2>

        <div className={`js-control-group control-group ${errorClass("firstName")}`}>
          <label className="control-label" htmlFor="firstName">
            First name
          </label>
          <div className="js-controls controls">
            <Input id="firstName" name="firstName" type="text" defaultValue={person.firstName} />
            <span className="js-help-inline help-inline">{getError("firstName")}</span>
          </div>
        </div>

        <div className={`js-control-group control-group ${errorClass("lastName")}`}>
          <label className="control-label" htmlFor="lastName">
            Last name
          </label>
          <div className="js-controls controls">
            <Input id="lastName" name="lastName" type="text" defaultValue={person.lastName} />
            <span className="js-help-inline help-inline">{getError("lastName")}</span>
          </div>
        </div>

        <div className={`js-control-group control-group ${errorClass("email")}`}>
          <label className="control-label" htmlFor="email">
            Email
          </label>
          <div className="js-controls controls">
            <Input id="email" name="email" type="email" defaultValue={person.email} disabled />
            <span className="js-help-inline help-inline">{getError("email")}</span>
          </div>
        </div>

        <div className={`js-control-group control-group ${errorClass("phone")}`}>
          <label className="control-label" htmlFor="phone">
            Phone
          </label>
          <div className="js-controls controls">
            <Input
              id="phone"
              name="phone"
              type="text"
              defaultValue={person.phone}
              placeholder="(123) 456-7890"
              disabled
            />
            <span className="js-help-inline help-inline">{getError("phone")}</span>
          </div>
        </div>

        <div className="form-actions">
          <button className="btn btn-primary save_person" disabled={isSubmitting || !formIsValid} type="submit">
            Save Changes
          </button>
        </div>
      </Form>

      <PasswordChangeForm />

      <div>
        <h2 className="heading-underline">Manage Account</h2>
        <DeactivateUserButton />
      </div>
    </div>
  );
};

PersonalSettings.tabId = "personal";
PersonalSettings.title = "Account Settings";

export default PersonalSettings;
