import { isNative } from "mobile/mobileManager";
import React from "react";

import { useSubscriptionPlatform } from "collection/graphql/subscription";

const ManageElsewhere = () => {
  const platform = useSubscriptionPlatform();
  if (isNative() && platform?.isWebPaymentProvider) {
    return "Please log in to your Bushel Farm account from a web browser to manage your subscription.";
  }

  if (platform?.isMobilePaymentProvider) {
    return (
      <a className="btn btn-primary" href={platform.storeUrl} rel="noopener noreferrer" target="_blank">
        Manage subscription through {platform.storeName}
      </a>
    );
  }

  return null;
};

export default ManageElsewhere;
