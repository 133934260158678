import _ from "lodash";

const BaseProductTypePolicy = {
  fields: {
    shortDescription: {
      read(__, { readField }) {
        const brand = readField("brand") || readField("manufacturer") || "Generic";
        const output = [brand];

        switch (readField("__typename")) {
          case "ChemicalProduct":
            output.push(_.capitalize(readField("chemicalType")));
            if (readField("restrictedUse")) {
              output.push("(Restricted)");
            }
            break;

          case "FertilizerProduct":
            const npk = [readField("percentNitrogen"), readField("percentPhosphate"), readField("percentPotash")];
            const suffix = npk.includes(null) ? "" : `, ${npk.join("-")}`;
            output.push(_.capitalize(readField("type")) + suffix);
            break;

          case "SeedProduct":
            output.push(_.startCase(readField("commodity")?.name), _.capitalize(readField("type")));
            break;

          case "WaterProduct":
            output.push("Water");
            break;
        }

        return output.filter((x) => x).join(" ");
      },
    },

    /*
     * `__typename` is not included by the REST endpoints. Given that we are now using Apollo's `@rest` queries for products, this
     * is needed to patch `__typename` for mutations. This can be removed when/if we ever move product mutations to graphql.
     */
    __typename: {
      read(cachedTypename, { readField }) {
        const type = _.toUpper(readField("type"));
        const typenameMap = new Map([
          ["CHEMICAL", "ChemicalProduct"],
          ["FERTILIZER", "FertilizerProduct"],
          ["SEED", "SeedProduct"],
          ["WATER", "WaterProduct"],
        ]);

        return typenameMap.has(type) ? typenameMap.get(type) : cachedTypename;
      },
    },
  },
};

export default BaseProductTypePolicy;
