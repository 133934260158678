import React, { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";

import { useAuth } from "collection/graphql/auth";
import { marketingClient } from "collection/graphql/client";
import { GET_COMMODITIES } from "collection/graphql/commodities/queries";
import { getUserConfig } from "collection/graphql/config";
import { GET_CURRENT_ENTERPRISE } from "collection/graphql/enterprise/queries";
import { GET_FIELD_CROPS } from "collection/graphql/fields/queries";
import { getAllUnits } from "collection/graphql/queries";
import { useSSOContext } from "context/SSOContext";
import useAsyncFn from "hooks/useAsyncFn";
import { getCurrentCropYear } from "hooks/useCurrentCropYear";

import LoadingWrapper from "components/fl-ui/LoadingWrapper";

const ProtectedRouteLayout = () => {
  const { isLoggedIn, isLoggingOut, loading: loadingAuth } = useAuth();
  const { selectedEnterprise } = useSSOContext();

  /*
   * These queries are being preloaded for performance purposes.
   *
   * The SSO context also needs a couple of these queries and it seems like it would be a great place to load them.
   * The bottom portion of this file (the redirection stuff) should be moved to some higher global layer.
   */
  const [{ loading: loadingDependencies }, loadDependencies] = useAsyncFn(async () => {
    const queries = [];
    queries.push(marketingClient.query({ query: getAllUnits }));
    queries.push(marketingClient.query({ query: GET_CURRENT_ENTERPRISE }));
    queries.push(marketingClient.query({ query: getUserConfig }));
    queries.push(marketingClient.query({ query: GET_COMMODITIES }));
    queries.push(
      marketingClient.query({
        query: GET_FIELD_CROPS,
        variables: { years: [getCurrentCropYear()] },
      })
    );

    return Promise.allSettled(queries);
  }, [isLoggedIn]);

  useEffect(() => {
    if (isLoggedIn && !loadingDependencies) {
      loadDependencies();
    }
  }, [isLoggedIn]);

  if (loadingAuth || loadingDependencies || isLoggingOut) {
    return <LoadingWrapper isLoading />;
  } else if (!loadingAuth && !loadingDependencies && !isLoggedIn) {
    return <Navigate to="/sign_in" />;
  } else if (!loadingAuth && !loadingDependencies && isLoggedIn && !selectedEnterprise) {
    return <Navigate to="/sso/select-enterprise" />;
  }

  return <Outlet />;
};

export default ProtectedRouteLayout;
