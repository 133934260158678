import React from "react";
import { AgreementDetailPage, Dashboard } from "agreements/pages";

export default [
  {
    path: "/land_costs",
    element: <Dashboard />,
  },

  {
    path: "/land_costs/add",
    element: <AgreementDetailPage action="add" />,
  },

  {
    path: "/land_costs/:id",
    element: <AgreementDetailPage action="edit" />,
  },
];
