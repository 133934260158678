import LeaseAgreementForm from "agreements/forms/LeaseAgreementForm";
import LoanAgreementForm from "agreements/forms/LoanAgreementForm";
import RevenueAgreementForm from "agreements/forms/RevenueAgreementForm";
import _ from "lodash";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { withDeleteAgreement } from "collection/graphql/agreements";
import App from "layout/app";

const EditAgreementForm = ({ agreement, deleteAgreement }) => {
  const [fields, setFields] = useState(_.cloneDeep(agreement));
  const navigate = useNavigate();

  let Form = () => {};
  switch (agreement.__typename) {
    case "LeaseAgreement":
      Form = LeaseAgreementForm;
      break;

    case "LoanAgreement":
      Form = LoanAgreementForm;
      break;

    case "RevenueAgreement":
      Form = RevenueAgreementForm;
      break;
  }

  const redirect = () => navigate("/land_costs");

  const handleDelete = () => {
    App.confirm({
      message: "Are you sure you want to delete this agreement?",
      cancel: "Cancel",
      confirm: "Delete",
      warning: true,
    }).then(async () => {
      await deleteAgreement(fields.id);
      redirect();
    });
  };

  const formProps = {
    fields,
    performDelete: handleDelete,
    onCancel: () => redirect(),
    onSaveSuccess: () => redirect(),
    onChange: (changes) => {
      const updatedFields = _.defaultsDeep({}, changes, fields);
      if ("attachments" in changes) {
        updatedFields.attachments = changes.attachments;
      }
      if ("paymentSchedules" in changes) {
        updatedFields.paymentSchedules = changes.paymentSchedules;
      }
      if ("terms" in changes) {
        updatedFields.terms = changes.terms;
      }
      if ("fields" in changes) {
        updatedFields.fields = _.cloneDeep(changes.fields);
      }

      setFields(updatedFields);
    },
  };

  return <Form {...formProps} />;
};

export default withDeleteAgreement(EditAgreementForm);
