import React from "react";
import DataImportExport from "settings/components/DataImportExport";

const Data = ({ subroute }) => {
  return <DataImportExport initialModalId={subroute} />;
};


Data.tabId = "data";

export default Data;
