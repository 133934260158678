import createReactClass from "create-react-class";
import leaflet from "leaflet";
import PropTypes from "prop-types";
import React from "react";
import BodyClassName from "react-body-classname";

import cache from "collection/graphql/cache";
import { getUserConfig } from "collection/graphql/config";

import { getFeature } from "components/field/utils";
import GeocoderSearch from "components/map/GeocoderSearch";
import Bounds from "components/map/bounds";
import MapControl from "components/map/control";
import Locate from "components/map/locate";
import Fullscreen from "components/ui/fullscreen";
import Map from "components/ui/map";
import { withBasicFields } from "fields/hooks/useFieldData";

const getCenter = (fields, fieldId) => {
  const { center } = cache.readQuery({ query: getUserConfig }).userConfig.mapBounds;
  const field = fields.find(({ id }) => fieldId === id);
  return field ? [...field.centroid.coordinates].reverse() : center.split(",");
};

export default withBasicFields(
  createReactClass({
    displayName: "NotesMap",

    propTypes: {
      children: PropTypes.any,
      data: PropTypes.object,
      onCancel: PropTypes.func,
      onChange: PropTypes.func,
    },

    getDefaultProps() {
      return {
        data: {},
        onChange() {},
        onCancel() {},
      };
    },

    getInitialState() {
      const latlng =
        this.props.data.geometry != null ? this.props.data.geometry.coordinates.slice().reverse() : undefined;
      this.setMarker({ latlng });

      return {
        fieldId: null,
        geometry: this.props.data.geometry,
        mapValue: {
          center: latlng || getCenter(this.props.fields, this.props.data.fieldId),
          layers: [this.getGeometries(), this.marker],
          zoom: 17,
        },
      };
    },

    componentDidMount() {
      return this.marker.on("move", () => {
        return this.setState({ geometry: this.marker.toGeoJSON().geometry });
      });
    },

    componentWillUnmount() {
      return this.marker.off();
    },

    marker: new leaflet.marker(null, {
      draggable: true,
      icon: leaflet.icon({
        className: "layer-info",
        iconSize: [20, 20],
        iconUrl: "images/map/scouting.png",
      }),
    }),

    onEachFeature(feature, layer) {
      return layer.on("click", (event) => {
        this.setMarker(event);
        return this.setState({ fieldId: feature.properties.id });
      });
    },

    getGeometries() {
      const styleOptions = {
        color: "#fff",
        opacity: 1,
        fillOpacity: 0.1,
        weight: 2,
        className: "crosshair-cursor",
      };

      const geoModels = this.props.fields.map(getFeature);

      return leaflet.geoJson(geoModels, {
        onEachFeature: this.onEachFeature,
        style: styleOptions,
      });
    },

    setMarker(...args) {
      const obj = args[0],
        val = obj.latlng,
        latlng = val != null ? val : [200, 200];
      return this.marker.setLatLng(latlng);
    },

    handleChange() {
      const { fieldId, geometry } = this.state;
      return this.props.onChange({ fieldId, geometry });
    },

    getMapBounds() {
      const layer = new leaflet.geoJson();
      return layer.addData(this.props.fields.map(({ geometry }) => geometry)).getBounds();
    },

    getLocation(e) {
      if (!this.marker._latlng) {
        return this.setMarker(e.latlng);
      }
    },

    updateLocation(location) {
      this.setState({ location });
    },

    render() {
      const showBottomBar = !!this.state.geometry;

      return (
        <BodyClassName className="hideNavMenuMobile">
          <div id="map-wrapper">
            <Fullscreen
              id="map-container"
              className="crosshair-cursor"
              showBottomBar={showBottomBar}
              style={{ zIndex: 2500 }}
            >
              <Map defaultValue={this.state.mapValue} location={this.state.location} on={this.state.mapEvents}>
                <MapControl position="top">
                  <div className="col xs-hide sm-hide md-3">
                    <div className="map-notification map-activity-helper">
                      Click to set a location
                      <br />
                      for your note
                      <div className="sub">
                        <img src="/images/map/how-to-add-note.gif" alt="Click to set your note's location" />
                      </div>
                    </div>
                  </div>
                  <div className="col xs-3 sm-2 md-push-2">
                    <Locate onChange={this.getLocation} />
                    <Bounds bounds={this.getMapBounds()} />
                  </div>
                  <div className="col xs-9 sm-10 md-5 md-push-2">
                    <GeocoderSearch onChange={this.updateLocation} />
                  </div>
                </MapControl>
                {!showBottomBar ? (
                  <MapControl position="bottom">
                    <div className="col">
                      <button
                        className="btn btn-on-map"
                        style={{ width: "180px", pointerEvents: "all" }}
                        onClick={this.props.onCancel}
                      >
                        Cancel
                      </button>
                    </div>
                  </MapControl>
                ) : (
                  <div className="bottom-bar">
                    <div className="medium-page-wrapper row-fluid">
                      <div className="col xs-6 md-3">
                        <div className="btn btn-block" onClick={this.props.onCancel}>
                          Cancel
                        </div>
                      </div>
                      <div className="col xs-hide sm-hide md-6">{" "}</div>
                      <div className="col xs-6 md-3">
                        <div className="btn btn-block btn-primary" onClick={this.handleChange}>
                          Set Location
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </Map>
              {this.props.children}
            </Fullscreen>
          </div>
        </BodyClassName>
      );
    },
  })
);
