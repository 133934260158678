const animationCurves = {
  fastOutSlowIn: "cubic-bezier(0.4, 0, 0.2, 1)",
  linearOutSlowIn: "cubic-bezier(0, 0, 0.2, 1)",
  fastOutLinearIn: "cubic-bezier(0.4, 0, 1, 1)",
};

const Transitions = {
  easeOut(property = "all", duration = "500ms", delay = "0ms") {
    return `${property} ${duration} ${delay} ease-out`;
  },
  easeInOut(property = "all", duration = "500ms", delay = "0ms") {
    return `${property} ${duration} ${delay} ease-in-out`;
  },
  easeIn(property = "all", duration = "500ms", delay = "0ms") {
    return `${property} ${duration} ${delay} ease-in`;
  },
  fastOutSlowIn(property = "all", duration = "500ms", delay = "0ms") {
    return `${property} ${duration} ${delay} ${animationCurves.fastOutSlowIn}`;
  },
  linearOutSlowIn(property = "all", duration = "500ms", delay = "0ms") {
    return `${property} ${duration} ${delay} ${animationCurves.linearOutSlowIn}`;
  },
  fastOutLinearIn(property = "all", duration = "500ms", delay = "0ms") {
    return `${property} ${duration} ${delay} ${animationCurves.fastOutLinearIn}`;
  },
  Standard(property = "all", duration = "500ms", delay = "0ms") {
    return `${property} ${duration} ${delay} ${animationCurves.fastOutSlowIn}`;
  },
};

export default Transitions;
