import React from "react";
import Dashboard from "notifications/routes/Dashboard";


export default [
  {
    path: "/alerts",
    element: <Dashboard />,
  },
];
