import { useQuery } from "@apollo/client";
import _ from "lodash";
import { appVersion, isNative } from "mobile/mobileManager";
import moment from "moment";
import { useEffect } from "react";

import { useAuth } from "collection/graphql/auth";
import { GET_CURRENT_USER } from "collection/graphql/auth/queries";
import { GET_CURRENT_ENTERPRISE } from "collection/graphql/enterprise/queries";
import { getCurrentSubscription } from "collection/graphql/subscription";
import useEnterpriseFeature from "hooks/useEnterpriseFeature";
import useIntegrations from "hooks/useIntegrations";
import useRestQuery from "hooks/useRestQuery";
import { isPendoReady } from "lib/pendo";
import { isReady } from "lib/segment";

/**
 * Listens for changes and updates the Segment/Pendo integrations appropriately.
 */
const useAnalyticsSetup = () => {
  // Needed due to Hubspot requiring date values to not have time components
  const formattedDate = (date) => {
    return date ? moment(date).format("YYYY-MM-DD") : null;
  };

  const sendAnalytics = () => {
    if (
      currentEnterprise &&
      currentUser &&
      currentSubscription &&
      _.isBoolean(elevatePlusEnabled) &&
      !integrationsLoading
    ) {
      const enterpriseName = currentEnterprise.name || currentUser.name + "'s Farm";

      isReady.then(({ segment }) => {
        segment.identify(
          currentUser.id,
          {
            createdAt: formattedDate(currentUser.created),
            email: currentUser.email,
            enterpriseId: currentUser.enterpriseId,
            isCargillElevatePlus: elevatePlusEnabled,
            name: currentUser.name,
            firstName: currentUser.firstName,
            lastName: currentUser.lastName,
            phone: currentUser.phone,
            userId: currentUser.id,
          },
          {
            context: {
              app: {
                version: process.env.CLIENT_VERSION || "dev",
              },
            },
          }
        );

        segment.group(currentEnterprise.id, {
          address: getAddressValues(currentEnterprise),
          createdAt: formattedDate(currentEnterprise.created),
          enterpriseId: currentEnterprise.id,
          name: enterpriseName,
          phone: currentEnterprise.phone,
          industry: "FARMING",
          reportedAcreage: currentEnterprise.acreage,
          employees: currentEnterprise.employees,
          useType: currentEnterprise.useType,
          timezone: currentEnterprise.timezone,
          ...getSubscriptionValues(currentSubscription),
        });
      });

      isPendoReady.then(async (pendo) => {
        const mobileAppVersion = isNative() ? await appVersion() : undefined;

        pendo.initialize({
          visitor: {
            creationDate: formattedDate(currentUser.created),
            email: currentUser.email,
            enterpriseId: currentEnterprise.uuid,
            id: currentUser.uuid,
            isCargillElevatePlus: elevatePlusEnabled,
            latestClientVersion: process.env.CLIENT_VERSION || "dev",
            latestMobileAppVersion: mobileAppVersion,
            name: currentUser.name,
            nameFirst: currentUser.firstName,
            nameLast: currentUser.lastName,
            phone: currentUser.phone,
          },
          account: {
            creationDate: formattedDate(currentEnterprise.created),
            employees: currentEnterprise.employees,
            id: currentEnterprise.uuid,
            name: enterpriseName,
            phone: currentEnterprise.phone,
            reportedAcreage: currentEnterprise.acreage,
            timezone: currentEnterprise.timezone,
            useType: currentEnterprise.useType,
            ...getAddressValues(currentEnterprise),
            ...getIntegrationValues(integrations),
            ...getSubscriptionValues(currentSubscription),
          },
        });
      });
    }
  };

  const getAddressValues = (currentEnterprise) => {
    return {
      street: currentEnterprise.address,
      street2: currentEnterprise.address2,
      city: currentEnterprise.city,
      postalCode: currentEnterprise.postalCode,
      county: currentEnterprise.county?.name ?? "",
      countyFips: currentEnterprise.county?.fips ?? "",
      state: currentEnterprise.state,
      country: currentEnterprise.country,
    };
  };

  const getIntegrationValues = (integrations) => {
    return _.mapValues(
      _.keyBy(integrations, (integration) => "integrationEnabled_" + integration.id),
      "enabled"
    );
  };

  const getSubscriptionValues = (currentSubscription) => {
    if (currentSubscription.isSubscribed) {
      return {
        subscriptionActive: true,
        subscriptionType: currentSubscription.currentPlan?.name,
        subscriptionSource: currentSubscription.currentPlan?.source,
        subscriptionIsTrialing: currentSubscription.isTrialing,
        subscriptionIsCancelled: currentSubscription.cancelAtPeriodEnd,
        subscriptionDateStart: formattedDate(currentSubscription.currentPlan?.periodStart),
        subscriptionDateEnd: formattedDate(currentSubscription.currentPlan?.periodEnd),
      };
    } else {
      return {
        subscriptionActive: false,
      };
    }
  };

  const { isLoggedIn, loading: authIsLoading } = useAuth();
  const skipQuery = !isLoggedIn || authIsLoading;

  const currentEnterprise = useQuery(GET_CURRENT_ENTERPRISE, { skip: skipQuery }).data?.currentEnterprise;
  const currentUser = useRestQuery(GET_CURRENT_USER, { skip: skipQuery }).data?.currentUser;
  const currentSubscription = useRestQuery(getCurrentSubscription, { skip: skipQuery }).data?.subscription;
  const elevatePlusEnabled = useEnterpriseFeature("elevate_plus");
  const { integrations, loading: integrationsLoading } = useIntegrations();
  useEffect(sendAnalytics, [
    currentEnterprise,
    currentUser,
    currentSubscription,
    elevatePlusEnabled,
    integrationsLoading,
  ]);
};

export default useAnalyticsSetup;
