import { useMutation } from "@apollo/client";
import _ from "lodash";
import { useCallback } from "react";

import createProduct from "collection/graphql/products/mutations/createProduct";
import deleteProduct from "collection/graphql/products/mutations/deleteProduct";
import updateProduct from "collection/graphql/products/mutations/updateProduct";
import getCustomProducts from "collection/graphql/products/queries/getCustomProducts";
import ChemicalProductSchema from "collection/graphql/products/schemas/ChemicalProductSchema";
import FertilizerProductSchema from "collection/graphql/products/schemas/FertilizerProductSchema";
import SeedProductSchema from "collection/graphql/products/schemas/SeedProductSchema";

const useProductMutations = () => {
  const [update] = useMutation(updateProduct, {
    optimisticResponse: ({ input }) => ({ product: input }),
  });

  const [destroy] = useMutation(deleteProduct, {
    update: (cache, { data: { product } }) => {
      cache.evict({ id: cache.identify(product) });
      cache.gc();
    },
  });

  const [create] = useMutation(createProduct, {
    refetchQueries: [
      {
        query: getCustomProducts,
      },
    ],
  });

  const save = useCallback(
    (product, options = {}) => {
      const normalizedType = _.toUpper(product.type);
      const schemas = new Map([
        ["CHEMICAL", ChemicalProductSchema],
        ["FERTILIZER", FertilizerProductSchema],
        ["SEED", SeedProductSchema],
      ]);
      if (!schemas.has(normalizedType)) {
        throw new Error(`Unrecognized product type: ${product.type}`);
      }
      const schema = schemas.get(normalizedType);

      const input = schema.validateSync(product, {
        abortEarly: false,
        strict: true,
        stripUnknown: true,
      });

      const queryOptions = {
        ...options,
        variables: {
          input,
        },
      };

      return input.id ? update(queryOptions) : create(queryOptions);
    },
    [create, update]
  );

  return [save, destroy];
};

export default useProductMutations;
