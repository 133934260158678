import { datadogLogs } from "@datadog/browser-logs";

import createSiemLog from "collection/graphql/logging/mutations/createSiemLog";
import useRestMutation from "hooks/useRestMutation";
import getSSOUser from "modules/sso/utils/getSSOUser";

const useCreateSiemLog = (options = {}) => {
  const buildSiemLogInput = (logObj) => {
    const user = getSSOUser();
    const SIEMLogObject = {
      "bushel.SIEM.index": "main",
      timestamp: Date.now(),
      // "network.client.ip": is added by Backend
      "usr.id": user?.profile.bushel_id,
      "usr.name": user?.profile.name,
      "usr.email": user?.profile.email,
      "usr.idSource": "bushel",
    };

    logObj.message = `${logObj.message} - ${
      datadogLogs.getUser()?.email || logObj["usr.email"] || user?.profile.email
    }`;

    if (logObj["evt.outcome"] === "failure") {
      datadogLogs.logger.error(JSON.stringify({ ...SIEMLogObject, ...logObj }));
    } else {
      datadogLogs.logger.info(JSON.stringify({ ...SIEMLogObject, ...logObj }));
    }

    return { variables: { input: { ...SIEMLogObject, ...logObj } } };
  };

  const sendLog = useRestMutation(createSiemLog)[0];

  return {
    buildSiemLogInput,
    sendLog,
  };
};

export default useCreateSiemLog;
