import useDocumentTitle from "app/hooks/useDocumentTitle";
import Map from "map/container";
import FieldMapLayers from "map/layer/fields";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useUserConfig } from "collection/graphql/config";
import withCropMutation from "hoc/withCropMutation";
import useCurrentEnterprise from "hooks/useCurrentEnterprise";

import withYearFieldCrops from "fields/hoc/withYearFieldCrops";
import useFieldAccess from "fields/hooks/useFieldAccess";

const FieldMap = ({ createCrop, crops, fields }) => {
  useDocumentTitle("Map");
  const id = useParams().id;
  useFieldAccess();
  const { currentEnterprise: enterprise } = useCurrentEnterprise();
  const [fieldLayers, setFieldLayers] = useState(null);
  const [map, setMap] = useState(null);
  const mapRef = useRef();
  const userConfig = useUserConfig();
  const navigate = useNavigate();

  const setUpMap = () => {
    if (map) {
      map.removeLayers();
      fieldLayers && map.addLayer(fieldLayers);
      map.fire("map:view");
    } else {
      const newMap = new Map(mapRef.current, {
        bounds: userConfig("mapBounds"),
        enterprise,
        fields,
        fieldLayers,
      });
      newMap.addEventListener("bounds:change", ({ center, zoom }) => {
        userConfig("mapBounds", { center, zoom });
      });
      fieldLayers && newMap.addLayer(fieldLayers);
      newMap.fire("map:view");
      setMap(newMap);
    }
  };

  useEffect(() => {
    setUpMap();

    if (id) {
      const focusField = fields.some((field) => field.id === +id);

      if (focusField) {
        map?.viewField(+id);
      } else {
        navigate("/map", { replace: true });
      }
    }

    return () => {
      map?.remove();
    };
  }, [id, map]);

  useEffect(() => {
    if (fieldLayers) {
      fieldLayers.updateCrops({ crops, fields });
    } else {
      const layers = new FieldMapLayers({ createCrop, crops, enablePopup: true, fields });
      map?.addLayer(layers);
      setFieldLayers(layers);
    }

    map?.fire("map:cropChange", { crops });
  }, [crops, fields]);

  return <div ref={mapRef} style={{ minHeight: "100vh", width: "100%", zIndex: 0 }} />;
};

export default withCropMutation(withYearFieldCrops(FieldMap));
