import { css } from "aphrodite/no-important";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Col, Row } from "react-styled-flexboxgrid";

import styles from "components/advertisements/styles";
import { Button } from "components/fl-ui";
import { Card } from "components/fl-ui/Card";
import { ContentMain } from "components/fl-ui/Layout";

const PayGateCommonBlankStatePage = ({ banner: Banner, steps = [] }) => {
  const getColor = (i) => {
    switch (i) {
      case 1:
        return styles.steps_color_green;
      case 2:
        return styles.steps_color_red;
      case 3:
        return styles.steps_color_yellow;
      default:
        return;
    }
  };

  const navigate = useNavigate();
  const onClick = () => navigate("/billing");

  const renderSteps = (steps) => {
    if (steps.length > 0) {
      return (
        <>
          <hr className={css(styles.promo_hr)} />

          <h2 className={css(styles.promo_h2, styles.promo_centeredContent, styles.marginBottom_24)}>How it works</h2>

          <Row>
            {steps.map((body, i) => (
              <Col className={css(styles.steps_col)} key={`col-${i}`} xs={12} smOffset={1} sm={10} mdOffset={0} md={4}>
                <Card className={css(styles.steps_card)}>
                  <h3 className={css(styles.steps_number)}>
                    <span className={css(styles.steps_color, getColor(i + 1))}>{i + 1}</span>
                  </h3>
                  <p className={css(styles.steps_text)}>{body}</p>
                </Card>
              </Col>
            ))}
          </Row>

          <Row>
            <Col xs={12} smOffset={2} sm={8} mdOffset={1} md={4}>
              <img alt="Rainfall" height="161" src="images/pay_gates/rainfall-footer.png" width="267" />
            </Col>
            <Col xs={12} smOffset={2} sm={8} mdOffset={0} md={6}>
              <h3 className={css(styles.footer_h3, styles.centered_on_tablet)}>
                Looking for more features? Check out our other plans & add-ons
              </h3>
              <Button className={css(styles.promo_cta, styles.centered_on_tablet)} color="primary" onClick={onClick}>
                View plans
              </Button>
              <p className={css(styles.promo_text, styles.centered_on_tablet)}>
                For more information, visit{" "}
                <Link to="https://bushelfarm.com/" rel="noopener noreferrer" target="_blank">
                  bushelfarm.com
                </Link>
                .
              </p>
            </Col>
          </Row>
        </>
      );
    }
  };

  return (
    <ContentMain fullWidth>
      <Banner />
      {renderSteps(steps)}
    </ContentMain>
  );
};

export default PayGateCommonBlankStatePage;
