import ActivityDetail from "activity/pages/ActivityDetail";
import React from "react";
import ActivityIndexPage from "activity/pages/ActivityIndexPage";


export default [
  {
    path: "/activity",
    element: <ActivityIndexPage />,
  },

  {
    path: "/activity/:id",
    element: <ActivityDetail action="view" />,
  },

  {
    path: "/activity/:id/edit",
    element: <ActivityDetail action="edit" />,
  },
];
