import React from "react";
import SbbEnrollmentPage, { enrollmentLoader } from "program_enrollment/pages/SbbEnrollment";



export default [
  {
    path: "/program_enrollment/:clientId",
    element: <SbbEnrollmentPage />,
    loader: enrollmentLoader,
  },
];
