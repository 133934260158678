import _, { pick } from "lodash";

import UpdateEnterpriseInputSchema from "collection/graphql/enterprise/schemas/UpdateEnterpriseInputSchema";

const ENTERPRISE_FIELDS = ["acreage", "address", "address2", "city", "country", "name", "phone", "postalCode", "state"];

export const getSortedTypes = (allActivityTypes) => _.partition(_.sortBy(allActivityTypes, ["name"]), "isArchived");

export const hasEmptyRequiredField = (data) => {
  const requiredFields = ["category", "name"];
  return requiredFields.some((field) => !data[field]);
};

export const isInvalidName = (allActivityTypes, data) =>
  allActivityTypes.some(({ id, name }) => name.toLowerCase() === data.name.toLowerCase() && id !== data.id);

export const filterEnterpriseFields = (enterprise) => {
  return pick(enterprise, ENTERPRISE_FIELDS);
};

export const hasStates = (country) => ["US", "CA"].includes(country);

export const validateAndUpdateEnterpriseState = async (enterprise) => {
  const validatedEnterprise = await UpdateEnterpriseInputSchema.validate(enterprise);

  return hasStates(enterprise.country) ? { ...validatedEnterprise } : { ...validatedEnterprise, state: null };
};
