import _ from "lodash";
import React from "react";
import styled from "styled-components";

import { getCurrentSubscription } from "collection/graphql/subscription";
import getAllProducts from "collection/graphql/subscription/getAllProducts";
import useRestQuery from "hooks/useRestQuery";
import { LITE } from "model/Subscription/constants";

import { Typography } from "components/fl-ui/constants";

export const usePromoVisibility = () => {
  const isSubscribedToPlan = useRestQuery(getCurrentSubscription).data?.subscription?.isSubscribedToPlan;
  const { allProducts } = useRestQuery(getAllProducts).data ?? {};
  const litePlan = _.find(allProducts, { id: LITE });

  return !isSubscribedToPlan && _.some(litePlan?.promos, { id: "contracts_lite_free" });
};

const RibbonContainer = styled.div`
  --ribbonColor: #9c5ba4;
  --size: 160px;

  background: rgba(250, 250, 251, 0.5) url("/images/pay_gates/lines.png") no-repeat -1330px -2150px;
  border-radius: 16px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);

  .ribbon {
    float: right;
    height: var(--size);
    overflow: hidden;
    shape-outside: polygon(100% 100%, 100% 0, 0 0);
    width: var(--size);

    > * {
      background-color: var(--ribbonColor);
      box-shadow: 0 0 0 999px var(--ribbonColor);
      color: #fff;
      clip-path: inset(0 -100%);
      font-size: 16px;
      font-weight: 500;
      line-height: 14px;
      padding: 10px 10px 10px 72px;
      text-transform: uppercase;
      transform: translate(-40px) rotate(45deg);
      white-space: nowrap;
    }
  }
`;
const ContractsFreeLitePromo = styled(({ className }) => {
  const show = usePromoVisibility();

  return !show ? null : (
    <RibbonContainer data-cy="contracts-promo">
      <div className="ribbon">
        <div>Limited Time Offer</div>
      </div>

      <div className={className}>
        <h3>
          Get the Lite plan free for 12 months when you connect to your accounts with grain buyers in the Bushel Network
        </h3>

        <p>
          Map your fields, track your rain & heat history, and now manage all of your grain sale contracts in one place.
          Connect to your accounts with grain buyers in the Bushel Network to automatically feed your contract data into
          Bushel Farm. Promo eligible for subscriptions purchased through the Bushel Farm website only.
        </p>

        <div>
          <a
            className="btn btn-primary"
            href="https://go.bushelfarm.com/connect-get-lite-for-free"
            target="_blank"
            rel="noreferrer"
          >
            Learn more
          </a>
        </div>
      </div>
    </RibbonContainer>
  );
})`
  @media only screen and (width < 62.5rem) {
    margin-top: 10px;
    padding: 10px 20px;
    > :last-child * {
      display: block;
    }
  }
  @media only screen and (width >= 62.5rem) {
    margin-top: 20px;
    padding: 40px 48px 30px;
    h3 {
      margin-top: 0;
    }
  }

  h3 {
    font-size: 20px;
    line-height: 1.5;
  }

  p {
    font-size: ${Typography.sizes.lg};
    line-height: 1.5;
  }

  > :last-child {
    display: block;
    margin: 10px auto 0;
    text-align: center;
  }
`;

export default ContractsFreeLitePromo;
