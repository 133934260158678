import { useMutation } from "@apollo/client";
import { pick } from "lodash";
import useFinancialAccounts from "profit_and_loss/hooks/useFinancialAccounts";
import React, { useState } from "react";
import ExpenseAndRevenueList from "settings/components/ExpenseAndRevenueList";
import FinancialAccountAddEditModal from "settings/components/FinancialAccountAddEditModal";
import { Link } from "settings/styles";

import { marketingClient } from "collection/graphql/client";
import { CREATE_FINANCIAL_ACCOUNT, UPDATE_FINANCIAL_ACCOUNT } from "collection/graphql/profit_and_loss/mutations";
import useEnterpriseFeature from "hooks/useEnterpriseFeature";
import usePermissions from "hooks/usePermissions";
import App from "layout/app";

import LoadingWrapper from "components/fl-ui/LoadingWrapper";
import RestrictedAccessBlankslate from "components/fl-ui/RestrictedAccessBlankslate";

const copyMap = {
  EXPENSE: (
    <span>
      {"Use expense accounts to track costs not captured by "}
      <Link to="/activity">activities</Link>, <Link to="/land_costs">land costs</Link>, and{" "}
      <Link to="/products">input purchases</Link>
    </span>
  ),
  REVENUE: "Use revenue accounts to track revenue not associated with your crops",
};

const ExpenseAndRevenue = () => {
  const { accountTypes, loading, ...data } = useFinancialAccounts();
  const [accountData, setAccountData] = useState(null);
  const { canRead } = usePermissions();
  const hasAccess = useEnterpriseFeature("profit_and_loss") && canRead("profit_and_loss");

  const [createFinancialAccount, { loading: createLoading }] = useMutation(CREATE_FINANCIAL_ACCOUNT, {
    client: marketingClient,
    refetchQueries: ["GetFinancialAccountsData"],
  });
  const [updateFinancialAccount, { loading: updateLoading }] = useMutation(UPDATE_FINANCIAL_ACCOUNT, {
    client: marketingClient,
  });

  const handleDataMutation = async (accountData) => {
    const account = pick(accountData, ["accountType", "id", "isArchived", "name"]);
    account.name = account.name?.trim();
    const mutatator = account.id ? updateFinancialAccount : createFinancialAccount;

    try {
      await mutatator({ variables: { account } });
      setAccountData(null);
    } catch (err) {
      App.notify("An error occurred, please try again later");
    }
  };

  return hasAccess ? (
    <LoadingWrapper isLoading={loading}>
      {accountTypes?.map(({ display, value }) => (
        <ExpenseAndRevenueList
          copy={copyMap[value]}
          data={data[value]}
          handleCreateData={() => setAccountData({ accountType: value, display })}
          handleEditData={(data) => setAccountData({ ...data, display })}
          key={value}
          type={display.toLowerCase()}
        />
      ))}

      {accountData && (
        <FinancialAccountAddEditModal
          accountData={accountData}
          disabled={createLoading || updateLoading}
          onArchive={() => handleDataMutation({ ...accountData, isArchived: !accountData.isArchived })}
          onClose={() => setAccountData(null)}
          onInputChange={(e, newData) => setAccountData({ ...accountData, ...newData })}
          onSave={() => handleDataMutation(accountData)}
        />
      )}
    </LoadingWrapper>
  ) : (
    <RestrictedAccessBlankslate />
  );
};

ExpenseAndRevenue.tabId = "expense_and_revenue";
ExpenseAndRevenue.title = "Expense & Revenue Accounts";

export default ExpenseAndRevenue;
