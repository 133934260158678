import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import useFeatureRedirect from "hooks/useFeatureRedirect";
import App from "layout/app";

import useFieldData from "fields/hooks/useFieldData";

const noGeo = (id) => {
  const navigate = useNavigate();
  App.confirm({
    title: "Sorry!",
    message:
      "We don't have any boundaries for this field. Click 'Add Boundaries' to be taken to the map where you can add them.",
    confirm: "Add Boundaries",
    warning: false,
  })
    .then(() => navigate(`/fields/${id}/edit`))
    .fail(() => navigate("/fields"));
};

const useFieldAccess = (fieldId, path = "/fields") => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { fields, loading } = useFieldData();
  const isOnboarding = fields?.length === 1 && pathname === "/fields/add";
  const skip = !fields?.length || isOnboarding;
  useFeatureRedirect({ feature: "fields", skip });
  const field = fields?.find(({ id }) => id === fieldId);

  useEffect(() => {
    if (fieldId) {
      if (!loading && !field) {
        navigate(path);
      } else if (field && !field.geometry) {
        noGeo(fieldId);
      }
    }
  }, [field, fieldId, loading]);

  return { field, loading };
};

export default useFieldAccess;
