import { useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import UserInformationFormSchema from "sso/forms/UserInformationFormSchema";
import _ from "lodash";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import styled from "styled-components";

import { restClient } from "collection/graphql/client";
import useCreateSiemLog from "collection/graphql/logging/hooks/useCreateSiemLog";
import { updatePerson } from "collection/graphql/people/mutations";
import { LegacyPersonSchema } from "collection/graphql/people/schemas/LegacyPersonSchema";
import { useSSOContext } from "context/SSOContext";

import { Button } from "components/fl-ui";
import { Checkbox, ControlledFormGroup, Form, Input } from "components/fl-ui/Form";

const UserInformationForm = styled(({ className }) => {
  const { user } = useSSOContext();
  const [updateUser] = useMutation(updatePerson, { client: restClient });
  const { sendLog, buildSiemLogInput } = useCreateSiemLog();

  const methods = useForm({
    defaultValues: UserInformationFormSchema.cast(
      {
        firstName: user?.firstName || "",
        lastName: user?.lastName || "",
        receiveNotificationEmails: false,
      },
      { assert: false, stripUnknown: true }
    ),
    mode: "onTouched",
    resolver: yupResolver(UserInformationFormSchema),
  });

  const onSubmit = async (formData) => {
    const input = LegacyPersonSchema.cast(
      {
        ...user,
        firstName: formData.firstName,
        lastName: formData.lastName,
        isTransactionEmailEnabled: formData.receiveNotificationEmails,
      },
      { assert: false, stripUnknown: true }
    );

    await updateUser({
      variables: { input },
    });

    await sendLog(
      buildSiemLogInput({
        "evt.name": "bushel_farm.user_information_changed",
        "evt.outcome": "success",
        message: "User Information Changed",
      })
    );

    //This should trigger the useMemo in the context to navigate to the next step,
    // likely by changing the underlying user, but any way to trigger that should work.
  };

  return (
    <FormProvider {...methods} schema={UserInformationFormSchema}>
      <div className={className}>
        <p className="top-text">Please provide your information.</p>
        <Form onSubmit={methods.handleSubmit(onSubmit)}>
          <ControlledFormGroup name="firstName" render={({ field }) => <Input {...field} placeholder="First" />} />

          <ControlledFormGroup name="lastName" render={({ field }) => <Input {...field} placeholder="Last" />} />

          <br />

          <ControlledFormGroup
            name="receiveNotificationEmails"
            render={({ field }) => {
              return (
                <div className="checkbox-row">
                  <Checkbox {...field} />
                  <p>I want to receive emails from Bushel Farm about my account.</p>
                </div>
              );
            }}
          />

          <br />

          <Button className="save-button" color="primary" disabled={!_.isEmpty(methods.formState.errors)} type="submit">
            Submit
          </Button>
        </Form>
      </div>
    </FormProvider>
  );
})`
  padding: 20px 5% 0 5%;

  .checkbox-row {
    display: inline-block;

    input {
      display: inline-block;
      vertical-align: top;
      width: 5%;
    }

    p {
      display: inline-block;
      margin: 0;
      width: 90%;
    }
  }

  .save-button {
    width: 100%;
  }

  .top-text {
    text-align: left;
  }
`;

export default UserInformationForm;
