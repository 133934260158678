import React from "react";

import { marketingClient } from "collection/graphql/client";
import { GET_CURRENT_ENTERPRISE } from "collection/graphql/enterprise/queries";
import NoteContainer from "notes/components/NoteContainer";
import IndexPage from "notes/index";


export default [
  {
    loader: async () => {
      return marketingClient.query({ query: GET_CURRENT_ENTERPRISE });
    },

    children: [
      {
        path: "/scouting",
        element: <IndexPage />,
      },

      {
        path: "/scouting/:actionOrId",
        element: <NoteContainer />,
      },
    ],
  },
];
