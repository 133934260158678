/* eslint-disable react/display-name */
import { css } from "aphrodite";
import { useViewportSpy } from "beautiful-react-hooks";
import { round } from "lodash";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "react-styled-flexboxgrid";

import usePermissions from "hooks/usePermissions";
import FieldCardMenu from "modules/fields/components/FieldCardMenu";

import Icon from "components/fl-ui/Icons/Icon";
import FieldCropButton from "fields/FieldCropButton";
import styles from "fields/common/styles";
import CropMapImage from "fields/components/CropMapImage";
import RainfallHistogram from "fields/components/RainfallHistogram";
import FieldIndexContext from "fields/components/context/FieldIndexContext";
import Forecast from "fields/components/forecast";

const withLazyRender = (Component) => (props) => {
  const ref = useRef();
  const { renderStrategy } = useContext(FieldIndexContext);
  const [renderCard, setRenderCard] = useState(renderStrategy.card === "standard");
  const isVisible = useViewportSpy(ref, { rootMargin: "10px" });
  useEffect(() => {
    if (isVisible && !renderCard) {
      setRenderCard(true);
    }
  }, [isVisible && !renderCard]);

  return (
    <Col className={`${css(styles.fieldCard_Col, styles.fieldCard_Col_MinHeight)}`} xs={12} sm={6} lg={4}>
      <div ref={ref} className={css(styles.fieldCard)}>
        {renderCard ? <Component {...props} /> : null}
      </div>
    </Col>
  );
};

const FieldCard = withLazyRender((props) => {
  const { crops, cropsLoading, field, showGroupName } = props;
  const groupName = showGroupName ? field.group?.name : null;

  const hasWritePermissions = usePermissions().hasPermission("fields", ["write"]);

  return (
    <>
      <Row>
        <Col xs className={css(styles.fieldCard_header)}>
          <Link className={css(styles.fieldCard_info)} data-cy="fieldDetailLink" to={`/fields/${field.id}`}>
            <CropMapImage className={css(styles.fieldCard_fieldImg)} crops={crops ?? []} field={field} />
            <div>
              {groupName && <p className={css(styles.fieldCard_groupName)}>{groupName}</p>}
              <h2 className={css(styles.fieldCard_name)}>{field.name}</h2>
            </div>
          </Link>
          {hasWritePermissions && <FieldCardMenu field={field} />}
        </Col>
      </Row>

      <hr />
      <Row>
        <Col xs>
          <RainfallHistogram fieldId={field.id} />
        </Col>
      </Row>

      <hr />
      <Row>
        <Col xs>
          <Forecast fieldId={field.id} />
        </Col>
      </Row>

      <hr />
      <Row>
        <Col className={css(styles.fieldCard_header)} xs>
          <p className={css(styles.fieldCard_p)}>
            {`${round(field.acreage, 1)} ac · `}
            <FieldCropButton crops={crops} fieldId={field.id} loading={cropsLoading} />
          </p>
          {field.isIrrigated && (
            <p className={css(styles.fieldCard_p)}>
              <Icon icon="irrigation" className={css(styles.fieldCard_irrigationIcon)} />
              Irrigated
            </p>
          )}
        </Col>
      </Row>
    </>
  );
});

export default FieldCard;
