import React from "react";

import useViewType from "hooks/useViewType";

import DesktopMenu from "components/fl-ui/Layout/Navigation/DesktopMenu";
import ResponsiveMenu from "components/fl-ui/Layout/Navigation/ResponsiveMenu";

const Navigation = () => {
  const isDesktop = useViewType() === "desktop";

  return (
    <div>
      {isDesktop && <DesktopMenu />}
      {!isDesktop && <ResponsiveMenu />}
    </div>
  );
};

export default Navigation;
