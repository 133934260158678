import { debug } from "debug";
import { User } from "oidc-client-ts";

import { currentEnterpriseVar, ssoUserVar } from "collection/graphql/client/common";
import { getCurrentEnterprise } from "modules/sso/utils/currentEnterpriseUtils";

const logAuth = debug("auth");

const getSSOUser = () => {
  if (ssoUserVar() === null) {
    const oidcStorage = localStorage.getItem(
      `oidc.user:${process.env.KEYCLOAK_AUTH_ISSUER}:${process.env.KEYCLOAK_CLIENT_ID}`
    );
    if (oidcStorage) {
      ssoUserVar(User.fromStorageString(oidcStorage));
    }
  }
  // This both gets, and sets the current enterprise
  getCurrentEnterprise();

  logAuth("router.js getSSOUser", ssoUserVar(), currentEnterpriseVar());
  return ssoUserVar();
};

export default getSSOUser;
