import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

import { Button } from "components/fl-ui";
import { CloseX } from "components/fl-ui/Icons";
import { Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle } from "components/fl-ui/Modal/Modal";

const ConnectWarningModal = ({ onClose }) => {
  return (
    <Modal>
      <ModalHeader>
        <ModalTitle>Action unsupported</ModalTitle>
        <CloseX onClick={onClose} />
      </ModalHeader>

      <ModalBody>
        <p>
          At this time this action can only be completed in a web browser. To open a web browser and connect click{" "}
          <Link to="https://app.bushelfarm.com/#/settings/connections" target="_blank">
            here
          </Link>
          . You may be asked to log in again.
        </p>
      </ModalBody>

      <ModalFooter>
        <Button color="primary" onClick={onClose}>
          Ok
        </Button>
      </ModalFooter>
    </Modal>
  );
};

ConnectWarningModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default ConnectWarningModal;
