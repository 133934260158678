import PropTypes from "prop-types";
import React, { useState } from "react";

import useUnits from "hooks/useUnits";

const getYieldUnitValue = (unit, units) => {
  const { value } = units?.find(({ display, value }) => display === unit || value === unit) ?? {};
  return value || unit;
};

const EditYieldForm = (props) => {
  const { defaultYield, defaultYieldRate, defaultYieldUnit, isUpdating, onCancel, onSubmit, units } = props;
  const { findEnumByName } = useUnits();
  const rates = findEnumByName("CropYieldRate");
  const [formFields, setFormFields] = useState({
    yield: defaultYield,
    yieldRate: defaultYieldRate,
    yieldUnit: getYieldUnitValue(defaultYieldUnit, units),
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit(formFields);
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        className="input-100"
        type="text"
        style={{ marginBottom: 0 }}
        onChange={({ target }) => setFormFields({ ...formFields, yield: target.value })}
        value={formFields.yield || ""}
      />
      <div className="select-wrapper" style={{ width: "auto" }}>
        <select
          style={{ marginBottom: 0 }}
          onChange={({ target }) => setFormFields({ ...formFields, yieldUnit: target.value })}
          value={formFields.yieldUnit}
        >
          {units.map(({ display, value }) => (
            <option key={value} value={value}>
              {display}
            </option>
          ))}
        </select>
      </div>

      <div className="select-wrapper" style={{ width: "auto" }}>
        <select
          style={{ marginBottom: 0 }}
          onChange={({ target }) => setFormFields({ ...formFields, yieldRate: target.value })}
          value={formFields.yieldRate}
        >
          {rates.map(({ display, value }) => (
            <option key={value} value={value}>
              {display.toLowerCase() !== "total" ? `/ ${display}` : display}
            </option>
          ))}
        </select>
      </div>
      <br />
      <br />
      <div>
        <button className="btn btn-smoke" disabled={isUpdating || !formFields.yield} type="submit">
          Save
        </button>{" "}
        <button className="btn" onClick={onCancel}>
          Cancel
        </button>
      </div>
    </form>
  );
};

EditYieldForm.propTypes = {
  defaultYield: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  defaultYieldRate: PropTypes.string,
  defaultYieldUnit: PropTypes.string,
  isUpdating: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  units: PropTypes.arrayOf(PropTypes.object),
};

export default EditYieldForm;
