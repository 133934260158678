"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const oidc_client_ts_1 = require("oidc-client-ts");
const onSignInCallback = (_user) => {
    // have to odic state params returning in querystring to avoid breaking session on a refresh, or back button press
    // need to grab any extra querystring param we need before the odic params are stripped below
    window.history.replaceState({}, document.title, window.location.pathname + window.location.hash);
    // todo: remove this history entry from lib/History also
};
const oidcConfiguration = {
    authority: process.env.KEYCLOAK_AUTH_ISSUER,
    client_id: process.env.KEYCLOAK_CLIENT_ID,
    redirect_uri: process.env.KEYCLOAK_REDIRECT_URL,
    onSigninCallback: onSignInCallback,
    userStore: new oidc_client_ts_1.WebStorageStateStore({ store: window.localStorage }),
};
exports.default = oidcConfiguration;
