import ActivityPopoverButton from "activity/components/ActivityPopoverButton";
import { css } from "aphrodite/no-important";
import _ from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { Card } from "components/fl-ui/Card";
import { UIColors } from "components/fl-ui/colors";
import { Spacing, Typography } from "components/fl-ui/constants";
import styles from "fields/common/styles";

const StatusText = styled(({ activity, className }) => {
  const { date, statusText } = activity;

  return (
    <div className={className}>
      <span className="statusText">{statusText}</span>
      {moment(date).format("MMM D")}
    </div>
  );
})`
  font-size: ${Typography.sizes.sm};
  margin-bottom: ${Spacing.xsmall};

  > .statusText {
    color: ${({ activity }) => (activity.isCancelled || activity.isOverdue ? UIColors.red : UIColors.green)};
    margin-right: ${Spacing.xsmall};
    text-transform: uppercase;
  }
`;

const ActivityCard = (props) => {
  const { activity } = props;
  const { field, inputs, type } = activity;
  const productNamesCopy = _.uniq(_.map(inputs, "product.name")).join(" ");
  const activityName = type ? type.name : "Unknown";
  const containerRef = useRef(null);

  const navigate = useNavigate();
  const handleClick = (event) => {
    if (!event.isDefaultPrevented() && containerRef.current.contains(event.target)) {
      navigate(`/activity/${activity.id}`);
    }
  };

  return (
    <Card className={css(styles.cardStyle)} onClick={handleClick} data-cy="activityCard">
      <div className={css(styles.spacedBetweenContainer)} ref={containerRef}>
        <div style={{ overflow: "hidden" }}>
          <StatusText activity={activity} />
          <div className={css(styles.cardText)}>{`${activityName} At ${_.upperFirst(field.name)}`}</div>
          <div className={css(styles.inputText, styles.ellipsify)}>{productNamesCopy}</div>
        </div>
        <ActivityPopoverButton activity={activity} />
      </div>
    </Card>
  );
};

ActivityCard.propTypes = {
  activity: PropTypes.object.isRequired,
};

export default ActivityCard;
