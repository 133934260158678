import PropTypes from "prop-types";
import React from "react";

import useCommodities from "hooks/useCommodities";
import { FALLOW, UNKNOWN_COMMODITY } from "lib/constants";

import CommodityChooser, { CommodityChooserProvider } from "components/commodity/CommodityChooser";

const SeedCommodityChooser = ({ commodityId, inputId, onChange }) => {
  const commodities = useCommodities().commodities.filter(({ id }) => ![FALLOW, UNKNOWN_COMMODITY].includes(id));

  return (
    <CommodityChooserProvider value={{ commodities }}>
      <CommodityChooser commodityId={commodityId} inputId={inputId} onChange={onChange} />
    </CommodityChooserProvider>
  );
};

SeedCommodityChooser.propTypes = {
  commodityId: PropTypes.number,
  inputId: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default SeedCommodityChooser;
