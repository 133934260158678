import themeGet from "@styled-system/theme-get";
import styled from "styled-components";

import { BorderRadius, Spacing } from "components/fl-ui/constants";

const ResponsiveMenuDrawer = styled.ul`
  background-color: ${themeGet("colors.smoke99")};
  border-radius: ${BorderRadius.medium} ${BorderRadius.medium} 0 0;
  border-bottom: 1px solid ${themeGet("colors.smoke90")};
  bottom: 4.75rem;
  box-shadow: inset ${themeGet("colors.smoke90")} 0 0 10px;
  display: ${({ state }) => (state === "exited" ? "none" : "grid")};
  grid-gap: ${Spacing.xxsmall};
  grid-template-columns: repeat(3, 1fr);
  left: 0.5rem;
  opacity: ${({ state }) => (state === "entered" ? 1 : 0)};
  padding: ${Spacing.regular} ${Spacing.xxsmall};
  position: fixed;
  width: calc(100% - 1rem);
  z-index: 3000;
  
  a {
    color: ${themeGet("colors.darkBlue")};
    padding: .3rem 0;
    
    &.active {
      background-color: ${themeGet("colors.lightestBlue")};
      > :nth-child(2) {
        color: ${themeGet("colors.blue")};
      }
    }
  }
`;

ResponsiveMenuDrawer.displayName = "ResponsiveMenuDrawer";

export default ResponsiveMenuDrawer;
