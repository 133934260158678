import { FUTURES_CONTRACT, FUTURES_PRICES, LOCAL_BIDS } from "prices/constants";
import React from "react";
import IndexPage from "prices/routes/index";
import { redirect } from "react-router-dom";


export default [
  {
    path: "/prices",
    element: <IndexPage view={FUTURES_PRICES} />,
  },

  {
    path: "/prices/commentary",
    loader: () => redirect("/prices"),
  },

  {
    path: "/prices/futures",
    element: <IndexPage view={FUTURES_PRICES} />,
  },

  {
    path: "/prices/futures/contract/:symbol",
    element: <IndexPage view={FUTURES_CONTRACT} />,
  },

  {
    path: "/prices/local",
    element: <IndexPage view={LOCAL_BIDS} />,
  },

  {
    path: "/prices/local/buyers/:buyerId",
    element: <IndexPage view={LOCAL_BIDS} />,
  },
]
