import useDocumentTitle from "app/hooks/useDocumentTitle";
import { upperFirst } from "lodash";
import numeral from "numeral";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import useCurrentCropYear from "hooks/useCurrentCropYear";
import useEnterpriseFeature from "hooks/useEnterpriseFeature";
import useFeatureRedirect from "hooks/useFeatureRedirect";
import usePermissions from "hooks/usePermissions";
import useViewType from "hooks/useViewType";

import FieldSidebar from "components/field/FieldSidebar";
import { Button } from "components/fl-ui";
import CropYearPicker from "components/fl-ui/CropYearPicker";
import Container from "components/fl-ui/Layout/Container";
import Content from "components/fl-ui/Layout/Content";
import ContentMain from "components/fl-ui/Layout/ContentMain";
import ContentSidebar from "components/fl-ui/Layout/ContentSidebar";
import Header from "components/fl-ui/Layout/Header";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";
import { RestrictedAccessWithHeader } from "components/fl-ui/RestrictedAccessWithHeader";
import { FieldEditModal } from "fields/components";
import Details from "fields/components/details";
import { HeaderContent, RightContent } from "fields/components/styledComponents";
import useFieldData from "fields/hooks/useFieldData";
import OnboardingDashboard from "fields/onboarding/OnboardingDashboard";

const FieldDetail = () => {
  const [title, setTitle] = useState("Field detail");
  useDocumentTitle(title);
  const fieldId = +useParams().id;
  const { executeGroupCreate, fields, loading } = useFieldData();
  const year = useCurrentCropYear()[0];
  const field = fields?.find(({ id }) => id === fieldId);
  const isMobile = useViewType() === "mobile";
  const [showModal, setShowModal] = useState(false);
  const hasFieldFeature = useEnterpriseFeature("fields");
  const isOnlyField = field && fields.length === 1;
  const showOnboardingPreview = isOnlyField && !hasFieldFeature;
  useFeatureRedirect({ feature: "fields", skip: loading || showOnboardingPreview });

  const hasWritePermissions = usePermissions().hasPermission("fields", ["write"]);

  useEffect(() => {
    if (field?.name) {
      setTitle(field.name);
    } else {
      setTitle("Field detail");
    }
  }, [field?.name]);

  if (loading && !field) {
    return <LoadingWrapper />;
  }

  if (!hasWritePermissions) {
    return <RestrictedAccessWithHeader title={field.name} />;
  }

  const acreage = numeral(field.acreage).format("0[.]0");
  const description = field?.group ? [`${upperFirst(field.group.name)}`, `${acreage} ac`] : [`${acreage} ac`];

  const toggleEditModal = () => setShowModal(!showModal);
  const commonProps = { field, fieldId, isMobile, toggleEditModal, year };

  return showOnboardingPreview ? (
    <OnboardingDashboard field={field} />
  ) : (
    <Container>
      <Header description={description} fullWidth title={!isMobile ? upperFirst(field.name) : ""}>
        <HeaderContent>
          <Button color="primary" hollow lightWeight onClick={() => setShowModal(true)}>
            Edit field details
          </Button>

          <RightContent>
            <CropYearPicker />
          </RightContent>
        </HeaderContent>
      </Header>

      <Content>
        <ContentSidebar>
          <FieldSidebar {...commonProps} description={description} />
        </ContentSidebar>

        <ContentMain>
          <Details {...commonProps} />
        </ContentMain>

        {showModal && (
          <FieldEditModal createGroup={executeGroupCreate} fieldId={fieldId} onClose={() => setShowModal(false)} />
        )}
      </Content>
    </Container>
  );
};

export default FieldDetail;
