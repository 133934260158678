import _ from "lodash";
import moment from "moment";
import numeral from "numeral";
import TableHeading from "products/components/TableHeading";
import PurchaseCard from "products/containers/ProductPurchaseCard";
import { Table } from "profit_and_loss/styles";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

import useViewType from "hooks/useViewType";

import ProductPurchaseUnit from "components/product/ProductPurchaseUnit";

const ProductPurchasesDetailTable = (props) => {
  const { onEditClick, purchaseSummary } = props;
  const { purchases } = purchaseSummary;
  const isPopulated = _.size(purchases) > 0;
  const isMobile = useViewType() === "mobile";

  const heading = (
    <TableHeading
      title="Purchases this year"
      subtitle={`${purchases.length} ${purchases.length === 1 ? "purchase" : "purchases"}`}
    />
  );

  if (isMobile && isPopulated) {
    return (
      <div>
        {heading}
        {purchases.map((purchase, i) => {
          return <PurchaseCard key={i} purchase={purchase} onEdit={() => onEditClick(purchase)} />;
        })}
      </div>
    );
  }

  return (
    <div>
      {heading}

      <Table>
        <thead>
          <tr>
            <th>Date</th>
            <th>Vendor</th>
            <th>Amount</th>
            <th>Avg Unit Price</th>
            <th>Cost</th>
            <th> </th>
          </tr>
        </thead>

        <tbody>
          {_.map(purchases, (purchase, i) => {
            const { amountTotal, costPerUnit, costTotal, date, vendor } = purchase;
            const handleEditClick = (event) => {
              event.preventDefault();
              onEditClick(purchase);
            };

            return (
              <tr key={[purchase.id, i].join("-")}>
                <td>{moment(date).format("MM/DD/YYYY")}</td>
                <td>{vendor.name}</td>
                <td>
                  <ProductPurchaseUnit
                    source={purchase}
                    amount={numeral(amountTotal).format("0,0[.]0")}
                    attribute="name"
                  />
                </td>
                <td>{numeral(costPerUnit).format("$0,0.00")}</td>
                <td>{numeral(costTotal).format("$0,0")}</td>
                <td>
                  <Link to="/" onClick={handleEditClick}>
                    Edit
                  </Link>
                </td>
              </tr>
            );
          })}

          {!isPopulated && (
            <tr>
              <td colSpan={6} style={{ textAlign: "center" }}>
                No purchases in the selected year.
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

ProductPurchasesDetailTable.propTypes = {
  onEditClick: PropTypes.func.isRequired,
  purchaseSummary: PropTypes.shape({
    purchases: PropTypes.arrayOf(
      PropTypes.shape({
        amountTotal: PropTypes.number.isRequired,
        costPerUnit: PropTypes.string.isRequired,
        costRate: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        costTotal: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        date: PropTypes.string.isRequired,
        product: PropTypes.shape({
          id: PropTypes.number.isRequired,
        }).isRequired,
        vendor: PropTypes.shape({
          name: PropTypes.string.isRequired,
        }).isRequired,
      })
    ).isRequired,
  }),
};

export default ProductPurchasesDetailTable;
