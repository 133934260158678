import themeGet from "@styled-system/theme-get";
import styled from "styled-components";

import { BorderRadius, Spacing } from "components/fl-ui/constants";

const ResponsiveMenuContainer = styled.ul`
  background-color: ${themeGet("colors.white")};
  border-radius: ${BorderRadius.medium};
  bottom: ${Spacing.small};
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.2);
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  left: 0.5rem;
  padding: ${Spacing.xxsmall};
  position: fixed;
  transition: height 400ms cubic-bezier(0.37, 0, 0.63, 1);
  width: calc(100% - 1rem);
  z-index: 3000;
  
  &.drawerOpen {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  > li a {
    height: 3.5rem;
    padding: .5rem;
    
    > :nth-child(2) {
      color:  ${themeGet("colors.darkBlue")};
      font-weight: ${themeGet("fontWeights.bold")};
    }
    
    &.active,
    &#back {
      background-color: ${themeGet("colors.lightestBlue")};
    
      > :nth-child(2) {
        color: ${themeGet("colors.blue")};
      }
    }
  }
`;

ResponsiveMenuContainer.displayName = "ResponsiveMenuContainer";

export default ResponsiveMenuContainer;
