import { useReactiveVar } from "@apollo/client";
import { css } from "aphrodite/no-important";
import _ from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

import { historicalRainfall } from "collection/graphql/fields/rainfall";

import useWeatherHistory from "components/field/weather/hooks/useWeatherHistory";
import useWeatherSummary from "components/field/weather/hooks/useWeatherSummary";
import { transformDailyWeatherReadings, transformHistoricalWeatherReadings } from "components/field/weather/lib";
import { Card, CardHeader } from "components/fl-ui/Card";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";
import { GreyColors, UIColors } from "components/fl-ui/colors";
import { Typography } from "components/fl-ui/constants";
import styles from "fields/onboarding/onboardingStyles";

const xAxisProps = {
  dataKey: "date",
  domain: ["dataMin", "dataMax"],
  stroke: GreyColors.smoke91,
  tick: { fill: GreyColors.smoke50, fontSize: Typography.sizes.rg, lineHeight: Typography.sizes.xl },
  tickFormatter: (d) => d.substring(0, 3).toUpperCase(),
  tickMargin: 10,
  ticks: moment.months().map((m) => `${m} 15th`),
};

const yAxisProps = {
  axisLine: false,
  domain: ["dataMin", (dataMax) => Math.ceil(dataMax / 10) * 10],
  label: {
    dx: 24,
    dy: -1,
    fill: GreyColors.smoke50,
    fontSize: Typography.sizes.rg,
    offset: 0,
    position: "insideTopRight",
    stroke: 0.1,
    value: "INCHES",
  },
  orientation: "right",
  scale: "linear",
  tick: { fill: GreyColors.smoke50, fontSize: Typography.sizes.rg },
  tickLine: false,
  type: "number",
};

const ToolTipContent = (props) => {
  const { payload } = _.isEmpty(props.payload) ? {} : props.payload[0];
  const { cummAverage, date, sum } = payload || {};
  const year = moment().year();
  const since = moment((_.minBy(props.chartData, "rawDate") || {}).rawDate || moment().startOf("year"));

  return (
    <div className={css(styles.tooltipContainer)}>
      <p className={css(styles.tooltipTitle)}>{sum ? `${date} ${year}` : date}</p>
      {sum && (
        <div>
          <h1 className={css(styles.blue)}>{`${sum}"`}</h1>
          <p className={css(styles.tooltipDescription, styles.blue)}>Since {since.format("MMM D")}</p>
        </div>
      )}
      <div>
        <h1>{`${cummAverage}"`}</h1>
        <p className={css(styles.tooltipDescription)}>10 year YTD average</p>
      </div>
    </div>
  );
};

const getChartData = ({ average, daily }) => {
  const data = [];
  average.forEach(({ day, month, total }) => {
    const match = daily.find(({ date }) => {
      const [matchingYear, matchingMonth, matchingDay] = (date || "").split("-");
      return month === +matchingMonth && day === +matchingDay;
    });

    if (day !== 29 || month !== 2 || moment().isLeapYear()) {
      data.push({
        cummAverage: total.toFixed(2),
        date: moment({ day, month: month - 1 }).format("MMMM Do"),
        rawDate: moment({ day, month: month - 1 }),
        sum: match && match.total.toFixed(2),
      });
    }
  });

  return data;
};

const RainfallTrendChart = ({ fieldId, title }) => {
  const event = useReactiveVar(historicalRainfall)?.get?.(fieldId);
  const { data: weatherSummary } = useWeatherSummary(fieldId, new Date().getFullYear(), "Onboarding");
  const { data: historicalData, loading } = useWeatherHistory(weatherSummary, "rainfall");
  const average = transformHistoricalWeatherReadings(historicalData?.averages, 3);
  const daily = transformDailyWeatherReadings(historicalData?.daily, 3);
  const chartData = getChartData({ average, daily });

  return (
    <Card className={css(styles.card)}>
      <CardHeader className={css(styles.bottomBorder, styles.cardHeader)}>
        <h2>{title}</h2>
        {event?.amount && (
          <div>
            <p className={css(styles.rainBadge)}>{`${event.amount}"`}</p>
            {moment(event.endTime).fromNow()}
          </div>
        )}
      </CardHeader>

      <LoadingWrapper isLoading={loading}>
        <ResponsiveContainer minHeight={400}>
          <LineChart data={chartData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid isAnimationActive={false} strokeDasharray="3 3" vertical={false} />
            <Line dataKey="sum" dot={false} name="Cumulative" stroke={`${UIColors.blue}`} strokeWidth={2} />
            <Line
              dataKey="cummAverage"
              dot={false}
              name="Average"
              stroke={`${UIColors.black}`}
              strokeDasharray="4 4"
              strokeWidth={2}
            />
            <Tooltip content={<ToolTipContent chartData={chartData} />} />
            <XAxis {...xAxisProps} />
            <YAxis {...yAxisProps} />
          </LineChart>
        </ResponsiveContainer>
      </LoadingWrapper>
    </Card>
  );
};

RainfallTrendChart.propTypes = {
  fieldId: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
};

export default RainfallTrendChart;
