import { currentEnterpriseVar } from "collection/graphql/client/common";
import { KeycloakStorageString } from "modules/sso/constants";

//Add reactive vars into here
const getCurrentEnterprise = () => {
  if (currentEnterpriseVar() === null) {
    try {
      const currentEnterpriseStorage = JSON.parse(localStorage.getItem(`enterprise:${KeycloakStorageString}`));
      currentEnterpriseVar(currentEnterpriseStorage);
    } catch (e) {
      clearCurrentEnterprise();
    }
  }
  return currentEnterpriseVar();
};

// I think this is the reason that the entire enterprise is stored in the local storage,
// so we can get the ID or the UUID based upon what state we are currently in
// This getCurrentEnterpriseLegacyId doesn't seem to be used anywhere.
const getCurrentEnterpriseLegacyId = () => {
  return getCurrentEnterprise()?.id;
};

const getCurrentEnterpriseUUID = () => {
  return getCurrentEnterprise()?.uuid;
};

const setCurrentEnterprise = (enterprise) => {
  localStorage.setItem(`enterprise:${KeycloakStorageString}`, JSON.stringify(enterprise));
  currentEnterpriseVar(enterprise);
};

const clearCurrentEnterprise = () => {
  localStorage.removeItem(`enterprise:${KeycloakStorageString}`);
  currentEnterpriseVar(null);
};

export {
  getCurrentEnterprise,
  getCurrentEnterpriseUUID,
  getCurrentEnterpriseLegacyId,
  setCurrentEnterprise,
  clearCurrentEnterprise,
};
