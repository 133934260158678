import { css } from "aphrodite";
import PropTypes from "prop-types";
import React, { useRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import AuthForm from "layout/components/AuthForm";
import FooterMessage from "layout/components/FooterMessage";
import styles from "layout/styles";
import { LOGIN_FORGOT_PASSWORD_PRESS } from "lib/metrics/events";

import { Button } from "components/fl-ui";
import { FormGroup } from "components/fl-ui/Form";
import { Container as InputContainer, Suffix, withBorder } from "components/fl-ui/Form/Input/ui";
import { GreyColors } from "components/fl-ui/colors";

const PasswordContainer = withBorder(styled(({ children, className }) => {
  return (
    <span className={["flui-input-compat", className].join(" ")}>
      <InputContainer>{children}</InputContainer>
    </span>
  );
})`
  width: 100%;
  ${({ disabled }) => disabled && `background-color: ${GreyColors.smoke93};`}

  input {
    flex-grow: 2;
  }
`);

const Footer = () => (
  <>
    <FooterMessage
      link="/password/reset"
      message="Did you forget your password?"
      onClick={() => LOGIN_FORGOT_PASSWORD_PRESS.track()}
    />
    <div className={css(styles.footerContainer)}>
      <Link
        className={css(styles.blueText)}
        to="https://bushelpowered.com/policies"
        href="https://bushelpowered.com/trust-center/"
        rel="noopener noreferrer"
        target="_blank"
      >
        privacy policy and terms of use
      </Link>
    </div>
  </>
);

const LoginForm = ({
  formErrors,
  formValues,
  handleLogin,
  handlePasswordFieldToggle,
  handleChange,
  obfuscatePassword,
}) => {
  const { email, error, loading } = formValues;
  const disabled = loading;
  const passwordInputType = obfuscatePassword ? "password" : "text";
  const passwordToggleText = obfuscatePassword ? "SHOW" : "HIDE";
  const passwordRef = useRef();

  const handleSubmit = (event) => {
    event.preventDefault();
    handleLogin({
      email: email.trim(),
      password: passwordRef.current.value,
    });
  };

  return (
    <AuthForm
      action="Sign in"
      disabled={disabled}
      error={error}
      footer={<Footer />}
      label="Sign in to Bushel Farm"
      onSubmit={handleSubmit}
    >
      <FormGroup inputId="email" label="Email">
        <input
          autoComplete="off"
          disabled={disabled}
          id="email"
          name="email"
          value={email}
          onChange={(e) => handleChange(e, { email: e.target.value })}
          type="email"
        />
        {formErrors.email && <div className="help-inline">{formErrors.email}</div>}
      </FormGroup>

      <FormGroup inputId="password" label="Password">
        <PasswordContainer disabled={disabled}>
          <input disabled={disabled} id="password" name="password" ref={passwordRef} type={passwordInputType} />
          {!disabled && (
            <Suffix>
              <Button className={css(styles.toggleText)} link onClick={() => handlePasswordFieldToggle()}>
                {passwordToggleText}
              </Button>
            </Suffix>
          )}
        </PasswordContainer>
      </FormGroup>
    </AuthForm>
  );
};

LoginForm.propTypes = {
  formErrors: PropTypes.object.isRequired,
  formValues: PropTypes.shape({
    email: PropTypes.string,
    error: PropTypes.string,
    loading: PropTypes.bool,
    password: PropTypes.string,
    passwordType: PropTypes.string,
    passwordToggleText: PropTypes.string,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleLogin: PropTypes.func.isRequired,
  handlePasswordFieldToggle: PropTypes.func.isRequired,
};

export default LoginForm;
