import { useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import ConfirmFarmNameFormSchema from "sso/forms/ConfirmFarmNameFormSchema";
import _ from "lodash";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { filterEnterpriseFields, validateAndUpdateEnterpriseState } from "settings/utils";
import styled from "styled-components";

import { UPDATE_ENTERPRISE } from "collection/graphql/enterprise/mutations";
import useCreateSiemLog from "collection/graphql/logging/hooks/useCreateSiemLog";
import { useSSOContext } from "context/SSOContext";
import App from "layout/app";

import { Button } from "components/fl-ui";
import { ControlledFormGroup, Form, Input } from "components/fl-ui/Form";

const ConfirmFarmNameForm = styled(({ className }) => {
  const { selectedEnterprise } = useSSOContext();
  const [updateEnterprise] = useMutation(UPDATE_ENTERPRISE);
  const { sendLog, buildSiemLogInput } = useCreateSiemLog();

  const methods = useForm({
    defaultValues: ConfirmFarmNameFormSchema.cast(
      {
        farmName: "",
      },
      { assert: false, stripUnknown: true }
    ),
    mode: "onTouched",
    resolver: yupResolver(ConfirmFarmNameFormSchema),
  });

  const onSubmit = async (data) => {
    try {
      const filteredEnterpriseFields = filterEnterpriseFields(selectedEnterprise);
      const enterprise = validateAndUpdateEnterpriseState(filteredEnterpriseFields);
      await updateEnterprise({
        variables: {
          enterprise: {
            ...enterprise,
            name: data.farmName,
          },
        },
      });
      App.notify("Changes saved.");
      await sendLog(
        buildSiemLogInput({
          "evt.name": "bushel_farm.farm_name_changed",
          "evt.outcome": "success",
          message: "Farm Name Changed",
        })
      );
    } catch (error) {
      console.log({ error });
      App.notify("Update failed, please try again");
    }
  };

  return (
    <FormProvider {...methods} schema={ConfirmFarmNameFormSchema}>
      <div className={className}>
        <p className="top-text">Please confirm your farm name</p>
        <Form onSubmit={methods.handleSubmit(onSubmit)}>
          <ControlledFormGroup
            name="farmName"
            render={({ field }) => <Input {...field} placeholder="Example Farm" />}
          />

          <br />

          <p>
            To maintain security, users will no longer be able to share credentials. If you have other users who need to
            access this account, you can invite them to join your account in Settings under Manage Users.
          </p>

          <br />

          <Button className="save-button" color="primary" disabled={!_.isEmpty(methods.formState.errors)} type="submit">
            Next
          </Button>
        </Form>
      </div>
    </FormProvider>
  );
})`
  padding: 20px 5% 0 5%;

  .checkbox-row {
    display: inline-block;

    input {
      display: inline-block;
      vertical-align: top;
      width: 5%;
    }

    p {
      display: inline-block;
      margin: 0;
      width: 90%;
    }
  }

  .save-button {
    width: 100%;
  }

  .top-text {
    text-align: left;
  }
`;

export default ConfirmFarmNameForm;
