import React, { useEffect } from "react";

import { useSSOContext } from "context/SSOContext";

import LoadingWrapper from "components/fl-ui/LoadingWrapper";

const SSOEntryPoint = () => {
  const { navigateThroughSSO } = useSSOContext();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigateThroughSSO();
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <div style={{ padding: "0 30px" }}>
      <LoadingWrapper isLoading />
    </div>
  );
};

export default SSOEntryPoint;
