"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const beautiful_react_hooks_1 = require("beautiful-react-hooks");
const react_1 = require("react");
const useViewType = () => {
    /* 1001px is a magic number added to prevent a crash at 1000px. */
    const desktopBreakpoint = "1001px";
    const getViewType = () => (matchMedia(`(min-width: ${desktopBreakpoint})`).matches ? "desktop" : "mobile");
    const [viewType, setViewType] = (0, react_1.useState)(getViewType());
    (0, beautiful_react_hooks_1.useWindowResize)(() => setViewType(getViewType()));
    return viewType;
};
exports.default = useViewType;
