import Icon from "loads/components/table_icon";
import React from "react";
import { Link } from "react-router-dom";

import useCommodities from "hooks/useCommodities";

const DeliveryTable = ({ loads, onRowClick }) => {
  const { getCommodityById } = useCommodities();

  return (
    <table className="report-table table-row-clickable horizontal-scrollable-parent">
      <tbody>
        {loads.map((load, i) => (
          <tr key={i} className="no-header horizontal-scrollable-child-400" onClick={() => onRowClick(load.id)}>
            <Icon {...load} />
            <td>{load.date_formatted}</td>

            <td>
              {!!load.source_name && (
                <div>
                  {load.source_path ? (
                    <Link data-cy="binDetailLink" onClick={(e) => e.stopPropagation()} to={`/${load.source_path}`}>
                      {load.source_name}
                    </Link>
                  ) : (
                    <span>{load.source_name}</span>
                  )}

                  <br />
                  <span>{load.source_group_name}</span>
                </div>
              )}

              {!load.source_name && <span className="sub">no source</span>}
            </td>

            <td>to</td>

            <td>
              {!!load.destination_name && (
                <div>
                  {load.dest_path ? (
                    <Link onClick={(e) => e.stopPropagation()} to={`/${load.dest_path}`}>
                      {load.destination_name}
                    </Link>
                  ) : (
                    <span>{load.destination_name}</span>
                  )}

                  <br />
                  <span>{load.destination_group_name}</span>
                </div>
              )}

              {!load.destination_name && <span className="sub">no destination</span>}
            </td>

            <td>
              {getCommodityById(load.commodity_id)?.name}
              <div>{`${load.amount_formatted} ${load.amount_unit}`}</div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default DeliveryTable;
