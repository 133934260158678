import { CapsHeader } from "../Helpers";
import PropTypes from "prop-types";
import React from "react";
import autoBind from "react-autobind";
import _ from "lodash";

import naturalSort from "lib/naturalSort";

import Select from "components/fl-ui/Form/Select";

class DropdownFilter extends React.Component {
  static propTypes = {
    param: PropTypes.object.isRequired,
    onParamChanged: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      selected: props.param.default,
    };

    autoBind(this);
  }

  onChange(event) {
    const selected = event.target.value;

    this.setState({
      selected: selected,
    });

    const payload = {
      id: this.props.param.id,
      value: selected,
    };

    this.props.onParamChanged(payload);
  }

  render() {
    const { id, title, choices } = this.props.param;
    const sortedChoices = _.sortBy(choices, (a, b) => naturalSort(a.label, b?.label));

    return (
      <div key={id}>
        <CapsHeader>{title}</CapsHeader>

        <Select value={this.state.selected} options={sortedChoices} onChange={this.onChange} block />
      </div>
    );
  }
}

export default DropdownFilter;
