import { GET_CURRENT_USER } from "collection/graphql/auth/queries";
import cache from "collection/graphql/cache";
import { authHeaders } from "collection/graphql/client/common";

/**
 * Fails the query if the user is not currently authenticated.
 */

export const authLink = (operation, forward) => {
  const isAuthenticated = !!cache.readQuery({ query: GET_CURRENT_USER })?.currentUser;
  if (!isAuthenticated) {
    throw new Error("Unable to execute query if not authenticated");
  }

  // if you have an sso auth header, but no enterprise yet -- Legacy Auth will not have Auth header, so will skip
  if (authHeaders()["Authorization"] && !authHeaders()["BF-Enterprise"]) {
    throw new Error("Unable to execute query if no enterprise has been selected");
  }

  operation.setContext(({ headers }) => ({
    headers: {
      ...headers,
      ...authHeaders(),
    },
  }));

  return forward(operation);
};
