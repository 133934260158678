import { css } from "aphrodite";
import PropTypes from "prop-types";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-styled-flexboxgrid";

import useWindow from "hooks/useWindow";
import AuthForm from "layout/components/AuthForm";
import FooterMessage from "layout/components/FooterMessage";
import styles from "layout/styles";

import { Button } from "components/fl-ui";
import { FormGroup, Input } from "components/fl-ui/Form";
import { Logo } from "components/fl-ui/Icons";
import Fullscreen from "components/fl-ui/util/Fullscreen";

const FinishedView = ({ email }) => {
  const navigate = useNavigate();

  const redirect = (event) => {
    event.preventDefault();
    navigate("/login", { replace: true });
  };

  return (
    <Fullscreen>
      <Row className={css(styles.fullscreen)}>
        <Col xs={12} md={4} className={css(styles.authContainer)}>
          <Logo size="sm" />
          <h1>Thanks</h1>

          <p className={css(styles.copyText)}>
            {`Please check your email. We've sent password reset instructions to ${email}.`}
          </p>

          <Button color="primary" display="block" onClick={redirect} size="lg">
            Return to login
          </Button>
        </Col>
      </Row>
    </Fullscreen>
  );
};

const PasswordResetForm = ({ formErrors, formValues, handleChange, handleReset }) => {
  const { done, email, error, loading } = formValues;

  return done ? (
    <FinishedView email={email} />
  ) : (
    <AuthForm
      action="Reset Password"
      copy={
        <p className={css(styles.copyText)}>
          {`Enter your email address below. We'll send you password reset instructions.`}
        </p>
      }
      disabled={loading}
      error={error}
      footer={<FooterMessage link="https://support.bushelfarm.com/" message="Need some help?" />}
      label="Forgot your password?"
      onChange={handleChange}
      onSubmit={handleReset}
    >
      <FormGroup label="Your Email">
        <Input
          autoFocus
          disabled={loading}
          hasError={formErrors.email}
          id="email"
          name="email"
          style={{ width: "100%" }}
          type="email"
          value={email}
        />
        {formErrors.email && <div className="help-inline">{formErrors.email}</div>}
      </FormGroup>
    </AuthForm>
  );
};

PasswordResetForm.propTypes = {
  formErrors: PropTypes.object.isRequired,
  formValues: PropTypes.shape({
    done: PropTypes.bool,
    email: PropTypes.string,
    error: PropTypes.string,
    loading: PropTypes.bool,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
};

export default PasswordResetForm;
