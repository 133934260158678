import themeGet from "@styled-system/theme-get";
import PropTypes from "prop-types";
import React from "react";
import { NavLink } from "react-router-dom";
import styled, { css } from "styled-components";

import { Icon } from "components/fl-ui/Icons";
import { Spacing } from "components/fl-ui/constants";


const labelMixin = css`
  &:after {
    border: 4px solid ${themeGet("colors.orange")};
    border-radius: 100%;
    content: "";
    position: absolute;
    left: calc(50% + 5px);
    top: 3px;
  }
`;


const ResponsiveMenuItem = styled(({ className, icon, id, onClick, title, to }) => {
  const classes = ({ isActive }) => isActive ? "active" : null;

  return (
    <li className={className}>
      <NavLink className={classes} id={id} onClick={onClick} to={to}>
        <Icon icon={icon} />
        <span>{title}</span>
      </NavLink>
    </li>
  );
})`
  a {
    align-items: center;
    background-color: ${({ active, theme }) => (active ? theme.colors.lightestBlue : "transparent")};
    display: flex;
    flex-direction: column;
    justify-content: center;
    outline: none;
    position: relative;
    text-decoration: none !important;
    
    > :nth-child(1) {
      color: ${themeGet("colors.blue")};
      height: ${Spacing.large};
      width: ${Spacing.large};
    }
  
    > :nth-child(2) {
      font-size: ${themeGet("fontSizes.sm")};
      line-height: 1.125rem;
      white-space: nowrap;
    }
  
    ${({ label }) => label && labelMixin}
    }
`;

ResponsiveMenuItem.propTypes = {
  active: PropTypes.bool,
  boldTitles: PropTypes.bool,
};

ResponsiveMenuItem.defaultProps = {
  active: false,
  boldTitles: false,
};

export default ResponsiveMenuItem;
