import { gql } from "@apollo/client";

export default gql`
  fragment ReportDefinition on ReportDefinition {
    beta
    columns {
      id
      required
      title
      type
    }
    description
    formats
    id
    parameters {
      choices {
        label
        value
      }
      default
      id
      multiple
      required
      title
      type
    }
    title
    type
  }
`
