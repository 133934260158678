"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const yup_1 = require("yup");
const SeedProductSchema = (0, yup_1.object)({
    brand: (0, yup_1.string)().trim().nullable().label("Brand").default(""),
    commodityId: (0, yup_1.number)()
        .label("Commodity")
        .typeError("${path}must be a number")
        .positive()
        .integer()
        .required(),
    id: (0, yup_1.number)().optional().positive().integer().label("Product ID"),
    name: (0, yup_1.string)().trim().required().label("Seed name"),
    notes: (0, yup_1.string)().optional().default("").label("Notes"),
    relativeMaturity: (0, yup_1.number)().label("Relative maturity").positive().nullable(),
    type: (0, yup_1.string)().lowercase().oneOf(["seed"]).required().label("Type"),
    unitType: (0, yup_1.string)()
        .lowercase()
        .oneOf(["count"], "Invalid ${path}")
        .required()
        .label("Unit type"),
});
exports.default = SeedProductSchema;
