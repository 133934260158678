import PropTypes from "prop-types";
import React from "react";

import { BlankDetailContent } from "fields/components/styledComponents";

const BlankDetail = ({ onClick, ...props }) => {
  const role = onClick ? "button" : undefined;
  const clickAction = (event) => {
    if (typeof onClick === "function") {
      onClick(event);
    }
    return undefined;
  };

  return <BlankDetailContent {...props} className="blank-slate blank-slate-dotted" onClick={clickAction} role={role} />;
};

BlankDetail.propTypes = {
  onClick: PropTypes.func,
};

export default BlankDetail;
