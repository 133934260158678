import React from "react";
import ContractsPage from "contracts/pages/ContractsPage";


export default [
  {
    path: "/contracts",
    element: <ContractsPage />,
  },
];
