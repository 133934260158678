import { gql } from "@apollo/client";

export default gql`
  mutation CreateSiemLog($input: CreateSiemLogInput!) {
    SiemLog: createSiemLog(input: $input) @rest(type: "SiemLog", path: "/log_siem_events", method: "POST") {
      message
      status
    }
  }
`;
