import _ from "lodash";
import moment from "moment";
import numeral from "numeral";
import ProductCard from "products/components/ProductCard";
import PropTypes from "prop-types";
import React from "react";
import { useNavigate } from "react-router-dom";

import { Button } from "components/fl-ui";
import ProductApplyUnit from "components/product/ProductApplyUnit";

const ActivityInputCard = ({ activityInput }) => {
  const navigate = useNavigate();
  const { activity, amountTotal, product } = activityInput;
  const { id, type } = activity;
  const footer = (
    <span>
      <ProductApplyUnit source={activityInput} amount={numeral(Math.ceil(amountTotal)).format("0,0")} /> applied on{" "}
      {moment(activity.date).format("MMM D, YY")}
    </span>
  );

  return (
    <ProductCard
      body={product.name}
      heading={type.name}
      footer={footer}
      onCardClick={() => navigate(`/activity/${id}`)}
    />
  );
};

const PurchaseActivityCard = ({ onLogPurchase, purchaseSummary }) => {
  const { amountAppliedTotal, product } = purchaseSummary;
  const footer = (
    <>
      <span>
        <ProductApplyUnit source={purchaseSummary} amount={numeral(Math.ceil(amountAppliedTotal)).format("0,0")} />{" "}
        applied
      </span>
      <Button color="primary" type="button" link>
        Log purchase
      </Button>
    </>
  );

  return (
    <ProductCard
      body={product.name}
      heading={product.type}
      footer={footer}
      onCardClick={() => onLogPurchase(product)}
    />
  );
};

const ProductActivityCard = (props) => {
  const { activityInput, onLogPurchase, purchaseSummary } = props;

  return _.isEmpty(purchaseSummary) ? (
    <ActivityInputCard activityInput={activityInput} />
  ) : (
    <PurchaseActivityCard purchaseSummary={purchaseSummary} onLogPurchase={onLogPurchase} />
  );
};

ProductActivityCard.propTypes = {
  activityInput: PropTypes.object,
  onLogPurchase: PropTypes.func,
  purchaseSummary: PropTypes.object,
};

ProductActivityCard.defaultProps = {
  activityInput: {},
  purchaseSummary: {},
};

export default ProductActivityCard;
