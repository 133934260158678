import { fileClient } from "@farmlogs/fl-ui";
import cx from "classnames";
import _ from "lodash";
import moment from "moment";
import useIconPath from "notes/hooks/useIconPath";
import React from "react";
import { Link } from "react-router-dom";

import { getAllPeople } from "collection/graphql/people/queries";
import useRestSuspenseQuery from "hooks/useRestSuspenseQuery";

import { withSuspenseWrapper } from "components/fl-ui/LoadingWrapper";

const NoteCard = withSuspenseWrapper(({ fieldName, note }) => {
  const iconPath = useIconPath(note);
  const imagePaths = _.map(note.images, ({ filepicker_id, filepickerId }) =>
    fileClient.getImagePreviewUrl(filepicker_id || filepickerId, { height: 100, width: 100 })
  );

  const { people } = useRestSuspenseQuery(getAllPeople).data;
  const peopleById = (id) => _.find(people, { id });

  const classNames = cx({
    "maps-and-images": true,
    "has-map": note.geometry,
    "no-map": !note.geometry,
    "has-image": imagePaths.length > 0,
    "no-image": imagePaths.length === 0,
    "num-pics-none": imagePaths.length === 0,
    "num-pics-one": imagePaths.length === 1,
    "num-pics-two": imagePaths.length === 2,
    "num-pics-three": imagePaths.length === 3,
    "num-pics-four": imagePaths.length === 4,
    "num-pics-five": imagePaths.length === 5,
    "num-pics-six": imagePaths.length === 6,
    "num-pics-more": imagePaths.length > 6,
  });

  return (
    <div className="block-grid-item">
      <Link className="note-item" to={`/scouting/${note.id}`}>
        <div className={classNames}>
          {iconPath && (
            <div className="maps">
              <div className="note-icon" />
              <div className="map" style={{ backgroundImage: `url(${iconPath})` }} />
            </div>
          )}

          {imagePaths.length > 0 && (
            <div className="images">
              {imagePaths.map((path) => (
                <div key={path} className="image" style={{ backgroundImage: `url(${path})` }} />
              ))}
            </div>
          )}
        </div>

        <div className="text">{note.notes}</div>
        <div className="meta">
          {note.people
            .map((id) => peopleById(id).name)
            .filter((x) => x)
            .join(", ")}
          {fieldName ? ` at ${fieldName}` : ""}
          {" - "}
          {moment(note.date).format("L")}
        </div>
      </Link>
    </div>
  );
});

export default NoteCard;
