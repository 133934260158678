import { css } from "aphrodite/no-important";
import { SalesPhoneLink } from "billing/lib";
import React from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "collection/graphql/auth";
import { getCurrentSubscription } from "collection/graphql/subscription";
import useCurrentEnterprise from "hooks/useCurrentEnterprise";
import useRestQuery from "hooks/useRestQuery";
import { LEGACY } from "model/Subscription/constants";

import styles from "components/advertisements/styles";
import useFieldData from "fields/hooks/useFieldData";

const copy = {
  body: {
    overLimit: {
      legacy: (
        <>
          Reach out to us at <SalesPhoneLink /> or click below to tell us more about your operation.
        </>
      ),
      standard: (
        <>
          Customized plans are available to farms over 10,000 acres. Reach out to us at <SalesPhoneLink /> or click the
          button to schedule a demo and start a free trial.
        </>
      ),
    },
    underLimit: {
      legacy: (
        <>
          Want to unlock rainfall updates and other features of Bushel Farm? Select one of our 4 plans, from Basic
          Rainfall to our comprehensive Business plan.
        </>
      ),
      standard: (
        <>
          - you’ve added your first field, now unlock the power of Bushel Farm for your entire farm by starting your
          free trial. Select the Business plan to try all features.
        </>
      ),
    },
  },

  button: {
    overLimit: {
      legacy: <>Reach out</>,
      standard: <>Schedule a demo</>,
    },
    underLimit: {
      legacy: <>Select a plan</>,
      standard: <>Start a free trial</>,
    },
  },
  header: {
    overLimit: {
      legacy: "Customized plans are available for accounts with over 10,000 acres.",
      standard: "Get a personalized demo to continue exploring Bushel Farm",
    },
    underLimit: {
      legacy: <>Subscribe now to continue accessing Bushel Farm</>,
      standard: <>Continue exploring Bushel Farm with a free trial</>,
    },
  },
};

export const PayGateAdBannerContent = ({ isLegacy, limitStatus }) => {
  const { email, firstName, lastName, phone } = useAuth().currentUser;
  let href;
  if (limitStatus === "overLimit") {
    href = isLegacy
      ? "https://share.hsforms.com/136jY_2lOQESUU2BuEoGHzQxdw1"
      : `https://bushelfarm.as.me/schedule.php?firstName=${firstName}&lastName=${lastName}&phone=${phone}&email=${email}`;
  }

  const navigate = useNavigate();
  const handleClick = (event) => {
    if (limitStatus === "underLimit") {
      event.preventDefault();
      navigate("/billing");
    }
  };

  return (
    <div className={css(isLegacy ? styles.banner_container_fullbleed_legacy : styles.banner_container_fullbleed)}>
      <div className={css(styles.banner_content)}>
        <div className={css(styles.banner_textContainer, styles.banner_textContainer_sm)}>
          <h1 className={css(styles.promo_h2, styles.centered_on_mobile)}>
            {copy.header[limitStatus][isLegacy ? "legacy" : "standard"]}
          </h1>

          <p className={css(styles.banner_content_item)}>
            {isLegacy && copy.body[limitStatus]["legacy"]}
            {!isLegacy && limitStatus === "underLimit" && `${firstName || ""} `}
            {!isLegacy && copy.body[limitStatus]["standard"]}
          </p>

          <a
            className={css(styles.promo_cta, styles.centered_on_mobile) + " btn btn-block btn-lg btn-primary"}
            href={href}
            onClick={handleClick}
            rel="noopener noreferrer"
            target="_blank"
          >
            {copy.button[limitStatus][isLegacy ? "legacy" : "standard"]}
          </a>
        </div>
      </div>
    </div>
  );
};

const PayGateAdBanner = () => {
  const { currentEnterprise } = useCurrentEnterprise();
  const { totalAcreage: totalAcreageComputed } = useFieldData();
  const totalAcreage = Math.max(totalAcreageComputed, currentEnterprise?.totalAcreage);
  const acreLimit = useRestQuery(getCurrentSubscription).data?.subscription?.acreLimit;
  const limitStatus = totalAcreage > acreLimit ? "overLimit" : "underLimit";
  const isLegacy = useRestQuery(getCurrentSubscription).data?.subscription?.currentPlan?.id === LEGACY;

  return <PayGateAdBannerContent isLegacy={isLegacy} limitStatus={limitStatus} />;
};

export default PayGateAdBanner;
