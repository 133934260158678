import EmailCheckForm from "auth/forms/EmailCheckForm";
import React, { useEffect, useState } from "react";
import { useAuth as useSSOAuth } from "react-oidc-context";
import BaseSSOPageContainer from "sso/components/BaseSSOPageContainer";
import styled from "styled-components";

import { Button } from "components/fl-ui";
import { GreyColors } from "components/fl-ui/colors";
import { Spacing } from "components/fl-ui/constants";

const SignIn = styled(({ className }) => {
  const auth = useSSOAuth();
  const [ssoUserEmail, setSSOUserEmail] = useState("");

  useEffect(() => {
    if (!auth?.isLoading) {
      if (auth?.isAuthenticated) {
        // TODO: Once sso logic is complete, we can send the user to landing page
        // goToLandingPage()
        // for now send back to Backbone router to decide the page
        window.location.href = "/";
        //I'm not even sure if any of this logic is still valid
      }
    }
  }, [auth?.isAuthenticated, auth?.isLoading]);

  if (auth?.isLoading || auth?.isAuthenticated) {
    return (
      <div id="layout">
        <div id="main" className="js-only">
          <div className="loading-placeholder">
            <h2>Loading...</h2>
            <div className="loading-spinner" />
          </div>
        </div>
      </div>
    );
  }

  return (
    <BaseSSOPageContainer title="Next Generation Farm Management Software">
      <div className={className}>
        {!!ssoUserEmail ? (
          <>
            <p>Sign in or sign up for a 30-day free trial*</p>
            <Button
              className="sign-in"
              color="primary"
              onClick={() =>
                void auth.signinRedirect({
                  login_hint: ssoUserEmail,
                })
              }
            >
              Sign In
            </Button>
            <Button
              className="sign-up"
              color="primary"
              hollow
              onClick={() =>
                void auth.signinRedirect({
                  login_hint: ssoUserEmail,
                })
              }
            >
              Sign Up
            </Button>
            <div className="grey-text">
              <p>
                {`By creating a Bushel Farm account, you are accepting Bushel's `}
                <a href="https://bushelpowered.com/trust-center/" target="_blank" rel="noreferrer">
                  privacy policy and terms of use
                </a>
                .
              </p>
              <p>*30-day free trial available for Lite, Essentials, or Business Plans</p>
            </div>
          </>
        ) : (
          <div id="test">
            <EmailCheckForm setSSOUserEmail={setSSOUserEmail} />
          </div>
        )}
      </div>
    </BaseSSOPageContainer>
  );
})`
  width: 100%;

  .sign-in,
  .sign-up {
    margin: ${Spacing.xxsmall} 0;
    width: 100%;
  }

  .grey-text {
    color: ${GreyColors.smoke70};
    font-size: 0.8rem;
    margin-top: ${Spacing.large};

    a {
      color: ${GreyColors.smoke70};
      text-decoration: underline;
    }
  }
`;

export default SignIn;
