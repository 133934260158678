import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { IconContainer } from "components/fl-ui/Icons";
import { ColorPalette } from "components/fl-ui/colors";
import { DetailsWidgetContainer, WidgetHeader } from "fields/components/styledComponents";

export const MoreLink = styled(Link)`
  align-items: center;
  color: ${ColorPalette.blue};
  display: flex;
`;

const DetailsWidget = ({ children, more, title, to }) => (
  <DetailsWidgetContainer>
    <WidgetHeader>
      <h2>{title}</h2>
      {more && (
        <MoreLink to={to}>
          {more}
          <IconContainer icon="chevronRight" />
        </MoreLink>
      )}
    </WidgetHeader>

    {children}
  </DetailsWidgetContainer>
);

DetailsWidget.propTypes = {
  children: PropTypes.node.isRequired,
  more: PropTypes.string,
  title: PropTypes.string.isRequired,
  to: PropTypes.string,
};

export default DetailsWidget;
