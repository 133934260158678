import { gql } from "@apollo/client";

export default gql`
  fragment DetailedProduct on BaseProduct {
    id
    isUsed
    name
    public
    shortDescription @client
    type
    unitType
    __typename
    ... on ChemicalProduct {
      brand
      chemicalType
      density
      epaNumber
      manufacturer
      notes
      restrictedUse
    }
    ... on FertilizerProduct {
      brand
      density
      manufacturer
      notes
      percentNitrogen
      percentPhosphate
      percentPotash
      percentSulfate
      percentZinc
    }
    ... on SeedProduct {
      brand
      commodity {
        id
        defaultSeedApplyUnit
        defaultSeedPurchaseUnit
        defaultYieldUnit
        name
        seedUnitType
      }
      manufacturer
      notes
    }
  }
`;
