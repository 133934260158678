import { css } from "aphrodite/no-important";
import useDocumentTitle from "app/hooks/useDocumentTitle";
import Disclaimer from "marketing/components/Disclaimer";
import { FUTURES_CONTRACT, FUTURES_PRICES, LOCAL_BIDS } from "prices/constants";
import FuturesContractDetailContainer from "prices/containers/FuturesContractDetailContainer";
import FuturesPricesContainer from "prices/containers/FuturesPricesContainer";
import LocalBids from "prices/containers/LocalBids";
import { localBidsStyles as styles } from "prices/styles";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import { useFeatureAndPermissionAccess } from "hooks/useFeatureAndPermissionAccess";

import PayGateAdBanner from "components/advertisements/banners/PayGateAdBanner";
import { Container, Header, Content, ContentMain } from "components/fl-ui/Layout";
import ErrorBoundary from "components/fl-ui/Layout/ErrorBoundary";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";
import RestrictedAccessBlankslate from "components/fl-ui/RestrictedAccessBlankslate";
import Tabs, { Tab } from "components/fl-ui/Tabs";
import { useNavigate, useParams } from "react-router-dom";
import { Spacing, Typography } from "components/fl-ui/constants";

const MarketPricesErrorView = styled.div`
  font-size: ${Typography.sizes.rg};
  margin: ${Spacing.small};
`;

const MarketPricesFallback = () => {
  return <MarketPricesErrorView>Error processing market data</MarketPricesErrorView>;
};

const LocalPricesIndex = ({ view }) => {
  const { buyerId, symbol } = useParams();
  useDocumentTitle("Market prices");

  const { loadingAccess, showPaygate, showRestricted, showContent } = useFeatureAndPermissionAccess({
    featureName: "market_prices",
    permissionName: "market_prices",
  });

  const navigate = useNavigate();
  const onChangeTab = (currentTab) => {
    if (currentTab !== view) {
      switch (currentTab) {
        case FUTURES_PRICES:
          navigate("/prices/futures");
          break;

        case FUTURES_CONTRACT:
          navigate(`/prices/futures/contact/${symbol}`);
          break;

        case LOCAL_BIDS:
          navigate(`/prices/local`);
          break;
      }
    }
  };

  return (
    <Container>
      <LoadingWrapper isLoading={loadingAccess}>
        <Header title="Market Prices" noBorder />

        {showPaygate && <PayGateAdBanner />}
        {showRestricted && <RestrictedAccessBlankslate />}
        {showContent && (
          <>
            <div className={css(styles.tabBar)}>
              <Tabs onChange={onChangeTab} value={view} size="large" className={css(styles.tabBarContainer)}>
                <Tab active={[FUTURES_CONTRACT, FUTURES_PRICES].includes(view)} value={FUTURES_PRICES}>
                  Futures Prices
                </Tab>
                <Tab value={LOCAL_BIDS}>Local Prices</Tab>
              </Tabs>
            </div>

            <ErrorBoundary fallback={MarketPricesFallback}>
              <Content>
                <ContentMain fullWidth>
                  {view === FUTURES_CONTRACT && <FuturesContractDetailContainer symbol={symbol} />}
                  {view === LOCAL_BIDS && <LocalBids buyerId={buyerId} />}
                  {view === FUTURES_PRICES && <FuturesPricesContainer />}
                  <Disclaimer />
                </ContentMain>
              </Content>
            </ErrorBoundary>
          </>
        )}
      </LoadingWrapper>
    </Container>
  );
};

LocalPricesIndex.propTypes = {
  view: PropTypes.oneOf([FUTURES_CONTRACT, FUTURES_PRICES, LOCAL_BIDS]).isRequired,
};

export default LocalPricesIndex;
