import useDocumentTitle from "app/hooks/useDocumentTitle";
import FullPageOverlay from "billing/layouts/FullPageOverlay";
import { SupportEmailLink, SupportPhoneLink } from "billing/lib";
import { StyledIconsContainer } from "carbon_enrollment/components/styledComponents";
import useProgramEnrollment from "program_enrollment/hooks/useProgramEnrollment";
import SbbEnrollmentFormSchema from "program_enrollment/validation/SbbEnrollmentFormSchema";
import React, { useEffect, useState } from "react";
import { replace } from "react-router-dom";
import { Col, Row } from "react-styled-flexboxgrid";
import styled from "styled-components";

import useEnterpriseFeature from "hooks/useEnterpriseFeature";
import useSchemaValidation from "hooks/useSchemaValidation";
import App from "layout/app";
import GroupedFields from "modules/carbon_enrollment/components/GroupedFields";
import useCarbonEnrollment from "modules/carbon_enrollment/hooks/useCarbonEnrollment";

import { Button } from "components/fl-ui";
import { Checkbox, Form, FormGroup, Input, Radio, RadioGroup } from "components/fl-ui/Form";
import { Icon, IconContainer, Logo } from "components/fl-ui/Icons";
import Tooltip from "components/fl-ui/Tooltip";
import { GreyColors, UIColors } from "components/fl-ui/colors";
import { Borders, BorderRadius, Spacing, Typography } from "components/fl-ui/constants";
import useGroupedFields from "fields/hooks/useGroupedFields";

export const enrollmentLoader = ({ params: { clientId }}) => {
  const isValid = clientId === "sbb";
  return isValid ? null : replace("/fields");
};


const SbbEnrollmentPage = styled(({ className }) => {
  useDocumentTitle("Program enrollment");
  const hasAlreadyEnrolled = useEnterpriseFeature("sbb");
  const enrollment = useCarbonEnrollment("sbb");
  const sbbData = useProgramEnrollment("sbb")?.sbbAdvisors;

  const { data, fields, isSaving, onCancel, onInputChange, onSelect, onSave, selectedFields } = enrollment;
  const { groupedFields, groups } = useGroupedFields(fields);

  const validator = useSchemaValidation(SbbEnrollmentFormSchema, data);

  const errorFor = (fieldName) => {
    if (fieldName in data) {
      return validator.errorsAtPath(fieldName)?.[0] ?? null;
    }
    return null;
  };

  const errorList = Object.keys(SbbEnrollmentFormSchema.fields).map((field) => errorFor(field));
  const [errorListString, setErrorListString] = useState("");

  if (!data.advisor_email) {
    //if advisor radio button is missed it will not show an error and confirm will appear disabled for no reason
    errorList.push("Confirm your SB&B advisor");
  }

  const disabled = !validator.isValid() || selectedFields.size === 0 || isSaving;

  const handleChange = (e, change) => onInputChange(change);

  const confirmCancel = () => {
    if (!hasAlreadyEnrolled) {
      App.confirm({
        title: "Discard Changes?",
        message: "Changes you made to this form will not be saved.",
        confirm: "Yes, Discard Changes",
        cancel: "Keep Editing",
      }).then(() => {
        onCancel();
      });
    } else {
      onCancel();
    }
  };

  useEffect(() => {
    if (selectedFields.size === 0) {
      errorList.push("Please Select at least one field");
    }
    setErrorListString(errorList.filter((x) => x).join(", "));
  }, [errorList, selectedFields]);

  return (
    <FullPageOverlay m="0 auto" maxWidth="900px" className={className} onCancel={confirmCancel}>
      <StyledIconsContainer className="logo">
        <Logo size="sm" />
        <IconContainer size="xxlarge">
          <Icon icon="add" />
        </IconContainer>
        <IconContainer className="sbb-logo">
          <Icon icon="sbbLogo" />
        </IconContainer>
      </StyledIconsContainer>
      {!hasAlreadyEnrolled ? (
        <>
          <Row>
            <Col xs={12}>
              <p className="heading">
                Share your contracted acres with SB&B to digitally enable communication between you and SB&B staff.
              </p>
              <p className="copy">
                Selected field boundaries will be shared automatically with SB&B and associated field activities and
                yield information with be included in end of season reports. The records shared with SB&B are limited
                and will never include any financial information you choose to track in your Bushel Farm account.
              </p>
            </Col>
          </Row>

          <Row className="section">
            <Col xs={12} sm={6} className="mainColumn">
              <p className="steps">Step 1</p>
              <p className="heading">Update or confirm your Bushel Farm account information.</p>

              <Form className="main-container form">
                <Row>
                  <Col xs={12}>
                    <FormGroup label="Farm name">
                      <Input autoFocus defaultValue={data.name} id="farm_name" name="name" onChange={handleChange} />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col xs={6}>
                    <FormGroup label="First name">
                      <Input defaultValue={data.firstName} name="firstName" onChange={handleChange} />
                    </FormGroup>
                  </Col>

                  <Col xs={6}>
                    <FormGroup label="Last name">
                      <Input defaultValue={data.lastName} name="lastName" onChange={handleChange} />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col xs={12}>
                    <FormGroup>
                      <label>
                        Email{" "}
                        <Tooltip
                          placement="top"
                          message="Please contact support@bushelpowered.com to update your Email address"
                        >
                          <IconContainer icon="help" size="regular" style={{ paddingTop: "2px" }} />
                        </Tooltip>
                      </label>
                      <Input defaultValue={data.email} disabled name="email" />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <FormGroup>
                      <label>
                        Phone{" "}
                        <Tooltip
                          placement="top"
                          message="Please contact support@bushelpowered.com to update your Phone #"
                        >
                          <IconContainer icon="help" size="regular" style={{ paddingTop: "2px" }} />
                        </Tooltip>
                      </label>
                      <Input defaultValue={data.phone} disabled name="phone" />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col xs={6}>
                    <FormGroup label="Farm zip code">
                      <Input defaultValue={data.postalCode} name="postalCode" onChange={handleChange} />
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            </Col>

            <Col xs={12} sm={6} className="mainColumn">
              <p className="steps">Step 2</p>
              <p className="heading">Select the fields where you will be growing SB&B product.</p>

              <div className="main-container vertical-scroll">
                {groups.map((groupName) => (
                  <GroupedFields
                    fields={groupedFields[groupName]}
                    groupName={groupName}
                    key={groupName}
                    onSelect={onSelect}
                    selectedFields={selectedFields}
                  />
                ))}
              </div>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <p className="steps">Step 3</p>
              <p className="heading">Confirm your SB&B advisor.</p>
              <p className="copy">
                By doing so, the field boundaries you select will be available in their admin account to streamline
                communication and make field checks more efficient. If you are unsure of who your advisor is, reach out
                to Scott at{" "}
                <a className="blue-text" href="mailto:ssinner@sb-b.com">
                  ssinner@sb-b.com
                </a>
              </p>

              <RadioGroup name="advisor_email" onChange={({ name, value }) => onInputChange({ [name]: value })}>
                {sbbData?.getAllSbbAdvisors.map((advisor) => (
                  <Radio
                    key={advisor.email}
                    className={data.advisor_email == advisor.email ? "advisor-option selected" : "advisor-option"}
                    label={advisor.name}
                    value={advisor.email}
                  />
                ))}
              </RadioGroup>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <p className="heading">What to expect next</p>
              <div className="copy">
                Once you complete this page, Bushel Farm will securely send your information to SB&B. Here is what you
                can expect:
                <ol>
                  <li>
                    You will receive a confirmation that your field boundaries have been shared with your SB&B advisor.{" "}
                  </li>
                  <li>
                    Continue logging field activities in Bushel Farm throughout the year, you can expect to receive
                    reminders as the season progresses.
                  </li>
                  <li>
                    At the end of the season, all field work associated with the selected fields will be reported to
                    SB&B.
                  </li>
                </ol>
              </div>
              <div className="agree-box">
                <label htmlFor="agree">
                  <Checkbox
                    checked={data.checked}
                    id="agree"
                    name="agree"
                    onChange={({ target: { checked } }) => onInputChange({ checked })}
                  />
                  <span className="copy">
                    By clicking Confirm below, I agree to give Bushel Farm permission to share my information,
                    boundaries, and field records with SB&B.
                  </span>
                </label>
              </div>
            </Col>
          </Row>
          <Row className="footer">
            <Col xs={12}>
              {errorListString && <div className="error-msg">{errorListString}</div>}
              <Button color="primary" disabled={disabled} loading={isSaving} onClick={onSave} size="lg">
                Confirm
              </Button>
              <br />
              <Button color="primary" link onClick={confirmCancel} size="lg">
                Cancel
              </Button>
            </Col>
          </Row>
        </>
      ) : (
        <Row>
          <Col xs={12}>
            <p className="heading">
              Your information and field boundaries have been shared with SB&B, please refer to your confirmation email
              to view your shared fields.
            </p>
            <hr />
            <p className="copy">
              If you have questions regarding what you have shared with SB&B, please contact your advisor.
            </p>

            <div className="advisor-box-wrapper">
              {sbbData?.getAllSbbAdvisors.map((advisor) => (
                <div className="advisor-box" key={`advisor-${advisor.email}`}>
                  <div className="heading">{advisor.name}</div>
                  <a href={`mailto:${advisor.email}`}>{advisor.email}</a>
                  <div>{advisor.phone}</div>
                </div>
              ))}
            </div>
            <hr />
          </Col>
        </Row>
      )}
      <Row className="footer">
        <Col xs={12}>
          <p>
            Have questions? You can reach Bushel Farm Support at <SupportPhoneLink /> or <SupportEmailLink />.
          </p>
        </Col>
      </Row>
    </FullPageOverlay>
  );
})`

.advisor-box{
  flex-basis:  50%;
  padding-bottom: ${Spacing.large};
  padding-right: ${Spacing.large};
}
.advisor-box-wrapper{
display: flex;
flex-wrap: wrap;
width: 100%;
}

.agree-box{
  border: ${Borders.regular};
  border-radius: ${BorderRadius.medium};
  background: rgba(30, 125, 220, 0.1);
  padding: ${Spacing.large};
}

.agree-box .copy{
    color: ${UIColors.darkBlue};
}
.agree-box label{
    display: flex;
}

.advisor-option{
  border: ${Borders.regular};
  margin: 0 0 -1px 0

}
.advisor-option label{
  cursor: pointer;
  padding:   ${Spacing.regular};
  color: ${UIColors.darkBlue};
  fontWeight: ${Typography.weights.medium};
}

.advisor-option.selected{
  background: rgba(30, 125, 220, 0.1);
}

.advisor-option:first-of-type{
  border-top-left-radius: ${BorderRadius.medium};
  border-top-right-radius: ${BorderRadius.medium};
}

.advisor-option:last-of-type{
  border-bottom-left-radius: ${BorderRadius.medium};
  border-bottom-right-radius: ${BorderRadius.medium};
}
.blue-text: {
    color: ${UIColors.primary};
    fontWeight: ${Typography.weights.medium};
  },

.copy{
    font-size: ${Typography.sizes.lg};
    line-height: 20px;
    font-weight: ${Typography.weights.regular};
    color: ${GreyColors.smoke50};
}
.error-msg{
  font-size:  ${Typography.sizes.md};
  margin-top: ${Spacing.regular};
  color: ${UIColors.danger};
}

.footer{
text-align: center;
}
.footer button{
  margin-top: ${Spacing.large};
  min-width: 200px;
}
.footer p{
  margin-top: ${Spacing.large};
  font-size: ${Typography.sizes.md};
}

.form label{
  font-size: ${Typography.sizes.lg} !important;
  font-weight: ${Typography.weights.medium};
  color: ${UIColors.darkBlue};
}
.heading {
  font-size: ${Typography.sizes.md};
  line-height: 20px;
  font-weight: ${Typography.weights.medium};
  margin-top: : ${Spacing.regular};
}
.logo{
  padding-bottom: ${Spacing.large};
}
.sbb-logo{
  max-height: 4rem;
  max-width: 4rem;
  width: 4rem;
}
.steps {
  font-weight: ${Typography.weights.medium};
  margin-top: ${Spacing.regular};
  color: ${GreyColors.smoke70};
  text-transform: uppercase;
}

.main-container{
    border: ${Borders.regular};
    border-radius: ${BorderRadius.medium};
    margin-top: ${Spacing.large};
    max-height:  32rem;
    min-height: 32rem;
    padding: ${Spacing.small};
}

.main-container.vertical-scroll {
  padding: 0;
  overflow-y: scroll;
  overflow-x: hidden;
}

ol {
  padding: 20px;
  list-style-type: decimal;
}
ol li {
  margin: 10px;
}
`;

export default SbbEnrollmentPage;
