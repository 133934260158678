import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import SettingsNav from "settings/components/SettingsNav";
import { useSettingsPageContext, withSettingsPageContext } from "settings/context/settingsPageContext";
import Activities from "settings/pages/ActivityTypes";
import Billing from "settings/pages/Billing";
import Commodities from "settings/pages/Commodities";
import ConnectionsPage from "settings/pages/Connections/ConnectionsPage";
import Data from "settings/pages/Data";
import ExpenseAndRevenue from "settings/pages/ExpenseAndRevenue";
import Farm from "settings/pages/Farm";
import Inputs from "settings/pages/Inputs";
import Alerts from "settings/pages/Notifications";
import People from "settings/pages/People";
import Personal from "settings/pages/Personal";

import usePermissions from "hooks/usePermissions";
import App from "layout/app";

import Toolbar from "components/fl-ui/Layout/Navigation/Toolbar";
import RestrictedAccessBlankslate from "components/fl-ui/RestrictedAccessBlankslate";

const pageComponents = [
  Activities,
  Alerts,
  Billing,
  Commodities,
  Data,
  ExpenseAndRevenue,
  Farm,
  Inputs,
  ConnectionsPage,
  Personal,
  People,
];

const tabIdToPermission = (tabId) => {
  const map = {
    activities: ["activity_types", "write"],
    billing: ["subscription", "read"],
    data: ["import_export", "read"],
    farm: ["farm_info", "read"],
    inputs: ["inputs", "read"],
    integrations: ["integrations", "read"],
    users: ["access_control", "read"],
  };

  return map[tabId];
};

const SettingsPage = withSettingsPageContext(({ activeRoute, subroute }) => {
  const [permission, checkType] = tabIdToPermission(activeRoute) || [];
  const { canRead, canWrite } = usePermissions();
  let canAccess = true;
  if (permission) {
    if (checkType === "read") {
      canAccess = canRead(permission);
    } else if (checkType === "write") {
      canAccess = canWrite(permission);
    }
  }

  const { mainNavIsVisible } = useSettingsPageContext();
  const PageComponent = _.find(pageComponents, { tabId: activeRoute });
  if (PageComponent?.title) {
    App.setTitle(PageComponent.title);
  }

  return (
    <div className="analytics-page container-fluid">
      <div className="headline row-fluid">
        <div className="col xs-12">
          <div className="with-toolbar no-border">
            <h1>Settings</h1>
            <div id="toolbar">
              <Toolbar />
            </div>
          </div>
        </div>
      </div>

      <div className="row-fluid">
        {mainNavIsVisible && (
          <div className="col xs-12">
            <SettingsNav activeTab={activeRoute} />
          </div>
        )}

        <div className="col xs-12 margin-top-10 settings-content">
          <div className="js-tab-content tab-content">
            {canAccess && <PageComponent subroute={subroute} />}
            {!canAccess && <RestrictedAccessBlankslate />}
          </div>
        </div>
      </div>
    </div>
  );
});

SettingsPage.propTypes = {
  activeRoute: PropTypes.oneOf([
    "activities",
    "alerts",
    "billing",
    "commodities",
    "connections",
    "data",
    "expense_and_revenue",
    "farm",
    "inputs",
    "personal",
    "users",
  ]).isRequired,
};

export default SettingsPage;
