import React, { Suspense } from "react";
import { Outlet } from "react-router-dom";

import { SSOContextProvider } from "context/SSOContext";

import LoadingWrapper from "components/fl-ui/LoadingWrapper";

const Application = () => {
  return (
    <Suspense fallback={<LoadingWrapper isLoading />}>
      <SSOContextProvider>
        <Outlet />
      </SSOContextProvider>
    </Suspense>
  );
};

export default Application;
