import { css } from "aphrodite";
import { trim, upperFirst } from "lodash";
import styles from "profit_and_loss/styles";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import ReactSelect, { components } from "react-select";

import { withAllMarketedCrops, withEditMarketedCrop } from "collection/graphql/marketing";
import App from "layout/app";

import { Button } from "components/fl-ui";
import CloseX from "components/fl-ui/Icons/CloseX";
import { Modal, ModalHeader, ModalTitle, ModalBody, ModalFooter } from "components/fl-ui/Modal/Modal";
import wrapOccurrences from "components/fl-ui/util/wrapOccurrences";

const chooserStyles = {
  option: (base, { isSelected }) => {
    const selectedStyles = isSelected
      ? {
          background: "#edf6ff",
          color: "inherit",
          borderTop: "2px solid #eef0f2",
        }
      : {};

    return { ...base, ...selectedStyles };
  },
};

const MenuList = (props) => (
  <components.MenuList {...props}>
    {props.children}

    <p className={css(styles.optionSelectBlankslate)}>
      {"Can’t find your crop? "}
      <Link className={css(styles.link)} to="/marketing">
        Organize your crops in Marketing
      </Link>
    </p>
  </components.MenuList>
);

const SelectOption = (props) => {
  const {
    data: { acresPlanted, fields, name },
    search,
  } = props;
  return (
    <div className={css(styles.optionContainer)}>
      <components.Option {...props}>
        <div className={css(styles.optionSelect)}>
          <div>
            <p className={css(styles.smallMarginBottom)}>{wrapOccurrences(upperFirst(name), search)}</p>
            <p className={css(styles.smallMarginBottom, styles.smallText)}>
              {`${acresPlanted} ac · ${fields.length} ${fields.length === 1 ? "field" : "fields"}`}
            </p>
          </div>

          <Button color="secondary">Select</Button>
        </div>
      </components.Option>
    </div>
  );
};

const OrganizeCropModal = (props) => {
  const {
    commodityId,
    data: { marketedCrops = [], loading },
    editMarketedCrop,
    field,
    onClose,
  } = props;
  const options = marketedCrops
    .filter(({ commodity }) => commodity.id === commodityId)
    .map((crop) => ({ ...crop, value: crop.id }));

  const [isSaving, setIsSaving] = useState(false);
  const [marketedCrop, setMarketedCrop] = useState(null);
  const [search, setSearch] = useState("");

  const onSave = () => {
    const fields = [...marketedCrop.fields.map(({ id }) => id), field.id];
    const data = { id: marketedCrop.id, input: { fields } };
    setIsSaving(true);

    return editMarketedCrop(data).then(
      () => onClose(),
      () => {
        App.notify("An error occurred");
        setIsSaving(false);
      }
    );
  };

  const Option = (props) => <SelectOption {...props} search={search} />;

  return (
    <Modal>
      <ModalHeader>
        <ModalTitle>Organize crop</ModalTitle>
        <CloseX onClick={onClose} />
      </ModalHeader>

      <ModalBody>
        <p className={css(styles.lineHeight)}>
          {"Allocate "}
          <strong>{`"${field.name}"`}</strong>
          {" to a marketing crop to track revenue from crop sales and to organize production costs"}
        </p>

        <p>
          <strong>Marketing crop</strong>
        </p>

        <ReactSelect
          components={{ MenuList, Option }}
          getOptionLabel={({ name }) => name}
          isClearable
          isDisabled={loading || isSaving}
          isLoading={loading}
          noOptionsMessage={() => ""}
          onChange={(crop) => setMarketedCrop(crop)}
          onInputChange={(search) => setSearch(trim(search))}
          options={options}
          placeholder="Type to search..."
          styles={chooserStyles}
          value={marketedCrop}
        />
      </ModalBody>

      <ModalFooter>
        <Button className={css(styles.rightMargin)} color="primary" link onClick={onClose}>
          Cancel
        </Button>
        <Button color="primary" disabled={!marketedCrop || isSaving} onClick={onSave}>
          {isSaving ? "Saving..." : "Save"}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

OrganizeCropModal.propTypes = {
  commodityId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  field: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    name: PropTypes.string,
  }),
  onClose: PropTypes.func,
  year: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default withAllMarketedCrops(withEditMarketedCrop(OrganizeCropModal));
