import React from "react";

import { useSSOContext } from "context/SSOContext";

import { Stack } from "components/fl-ui/Layout";

const SSOLinks = () => {
  const {
    enterprises,
    selectedEnterprise,
    userRole,
    isUserAdmin,
    hasBeenPromptedToConnectContracts,
    hasFarmNameBeenConfirmed,
    isUserInBushelNetwork,
    isContractsConnected,
    firstAndLastNameExist,
    user,
  } = useSSOContext();

  console.log({
    enterprises,
    selectedEnterprise,
    userRole,
    isUserAdmin,
    hasBeenPromptedToConnectContracts,
    hasFarmNameBeenConfirmed,
    isUserInBushelNetwork,
    isContractsConnected,
    firstAndLastNameExist,
    user,
  });

  return (
    <Stack space="2rem">
      <h2>SSO Links</h2>
      <br />
      <ol>
        <li>
          <a href="/sso/confirm-farm-name">Confirm Farm Name Screen</a>
        </li>
        <li>
          <a href="/sso/connect-to-contracts">Connect to Contracts</a>
        </li>

        <li>
          <a href="/sso/create-new-enterprise">Create New Enterprise</a>
        </li>

        <li>
          <a href="/sso/select-enterprise">Select Enterprise</a>
        </li>
        <li>
          <a href="/sso/sign-up">Sign Up</a>
        </li>
        <li>
          <a href="/sso/successfully-connected-to-bushel-contracts">Successfully Connected to Bushel Contracts</a>
        </li>
        <li>
          <a href="/sso/user-information">User Information Screen</a>
        </li>
      </ol>
      <br />
      <ol>
        <li>
          <a href="/contracts">Contracts</a>
        </li>
      </ol>
    </Stack>
  );
};

export default SSOLinks;
