import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { EXPLORER_BLANK_SLATE_SHOW } from "lib/metrics/events";

import { Button } from "components/fl-ui";

const containerStyle = { maxWidth: "27em", textAlign: "center" };

const imgStyle = {
  maxWidth: "275px",
  maxHeight: "168px",
};

const getImageSrc = function (type) {
  switch (type.value) {
    case "yield.512":
      return "yield-promo-190@2x";
    case "seed":
      return "rx-promo-384@2x";
    case "nitrogen":
      return "rx-promo-384@2x";
  }
};

const getText = function (type) {
  switch (type.value) {
    case "imagery":
      return [
        "No Imagery Available Yet",
        "New images might take 24-48 hours to populate. You will be notified when an image is available.",
      ];

    case "soil.ssurgo":
      return [
        "No Soil Data Available",
        "Soil types are unavailable for this location because there's no corresponding soil survey in the SSURGO database",
      ];

    case "yield.512":
      return [
        "No Yield Uploaded",
        "Upload your yield files to see where your field performed best and compare it to other map layers",
      ];
  }
};

const getButtonProps = function ({ navigate, type }) {
  switch (type.value) {
    case "yield.512":
      return {
        children: "Upload Yield Files",
        onClick: () => navigate("/settings/data/yieldData"),
      };
  }
};

const getBlankState = function ({ navigate, type }) {
  const [title, copy] = Array.from(getText(type));
  const imageSrc = getImageSrc(type);
  const buttonProps = getButtonProps({ navigate, type });

  return { title, copy, imageSrc, buttonProps };
};

const trackBlankSlate = function ({ type }) {
  let eventType;
  switch (type.value) {
    case "yield.512":
      eventType = "Yield Prompt";
      break;
  }

  if (!eventType) {
    return;
  }

  return EXPLORER_BLANK_SLATE_SHOW.track({ type: eventType });
};

// #############################################################################

const BlankType = ({ type }) => {
  const navigate = useNavigate();
  const { buttonProps, copy, imageSrc, title } = getBlankState({
    navigate,
    type,
  });

  useEffect(() => {
    trackBlankSlate({ type });
  }, [type]);

  return (
    <div className="font-size-m" style={containerStyle}>
      {!!imageSrc && <img src={`/images/map/${imageSrc}.png`} style={imgStyle} />}

      <div style={{ lineHeight: "1.5em", margin: "1rem" }}>
        <h3 style={{ color: "inherit" }}>{title}</h3>
        {copy}
      </div>

      {!!buttonProps && <Button color="primary" {...buttonProps} />}
    </div>
  );
};

BlankType.propTypes = {
  type: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default BlankType;
