import { css } from "aphrodite";
import { ConnectToBushelCard } from "marketing/cards";
import MarketingPositionSummaryCard from "marketing/cards/MarketingPositionSummaryCard";
import CropEditModal from "marketing/components/CropEditModal";
import Disclaimer from "marketing/components/Disclaimer";
import { CARGILL_ELEVATE, SUMMARY } from "marketing/constants";
import CargillElevate from "marketing/containers/CargillElevate";
import TotalFarm from "marketing/containers/TotalFarm";
import { dashboardStyles as styles } from "marketing/styles";
import PropTypes from "prop-types";
import React from "react";
import { useNavigate } from "react-router-dom";

import { useFarmAdvisorConfig } from "collection/graphql/advisor";
import { useMarketingPageContext } from "context/MarketingPageContext";
import useCurrentCropYear from "hooks/useCurrentCropYear";
import useEnterpriseFeature from "hooks/useEnterpriseFeature";

import { Button } from "components/fl-ui";
import CropYearPicker from "components/fl-ui/CropYearPicker";
import Container from "components/fl-ui/Layout/Container";
import Content from "components/fl-ui/Layout/Content";
import ContentMain from "components/fl-ui/Layout/ContentMain";
import Header from "components/fl-ui/Layout/Header";
import Tabs, { Tab } from "components/fl-ui/Tabs";

const MarketingPageContainer = ({ loading, marketedCrops, view }) => {
  const elevatePlusEnterpriseEnabled = useEnterpriseFeature("elevate_plus");
  const advisorTabCopy = useFarmAdvisorConfig().data.nav?.tabTitle;

  const [cropYear] = useCurrentCropYear();
  const { showCropModal, openCropEditModal } = useMarketingPageContext();
  const navigate = useNavigate();
  const pageTitle = view === SUMMARY ? `Marketing ${cropYear}` : "Marketing";

  const onChangeTab = (tabId) => {
    if (tabId === CARGILL_ELEVATE) {
      navigate("/marketing/advisor");
    } else if (tabId === SUMMARY) {
      navigate("/marketing");
    }
  };

  return (
    <Container>
      <Header title={pageTitle}>
        {view === SUMMARY && cropYear && (
          <div className={css(styles.headerControlsWrapper)}>
            <Button
              className={css(styles.addCropButton)}
              color="primary"
              id="add-new-crop-button"
              onClick={openCropEditModal}
            >
              Add marketing crop
            </Button>

            <CropYearPicker />
          </div>
        )}
      </Header>
      {elevatePlusEnterpriseEnabled && (
        <div className={css(styles.tabBar)}>
          <Tabs onChange={onChangeTab} value={view} size="large" className={css(styles.tabBarContainer)}>
            <Tab value={SUMMARY}>Overview</Tab>
            {advisorTabCopy && <Tab value={CARGILL_ELEVATE}>{advisorTabCopy}</Tab>}
          </Tabs>
        </div>
      )}
      <Content style={{ marginTop: "2em" }}>
        <ContentMain fullWidth>
          {view === SUMMARY && (
            <>
              <MarketingPositionSummaryCard />
              <h2>Your Crops</h2>
              <TotalFarm loading={loading} marketedCrops={marketedCrops} />
              <ConnectToBushelCard variant="marketing" />
              <Disclaimer />
            </>
          )}
          {view === CARGILL_ELEVATE && elevatePlusEnterpriseEnabled && <CargillElevate />}
        </ContentMain>
      </Content>
      {showCropModal && <CropEditModal marketedCrops={marketedCrops} />}
    </Container>
  );
};

MarketingPageContainer.defaultProps = {
  view: SUMMARY,
};

MarketingPageContainer.propTypes = {
  marketedCrops: PropTypes.arrayOf(PropTypes.object).isRequired,
  view: PropTypes.string,
};

export default MarketingPageContainer;
