import React, { useEffect, useRef, useState } from "react";

import YieldLayer from "lib/map/layers/yield";
import { Link } from "react-router-dom";

const YieldMap = ({ fieldId, layer }) => {
  const [imageSrc, setImageSrc] = useState();
  const yieldRef = useRef();

  useEffect(() => {
    const setYieldMap = (layer) => {
      if (layer) {
        const yieldLayer = new YieldLayer(layer);
        const size = yieldRef?.current?.offsetWidth;

        yieldLayer.renderComposite({ height: size, width: size }).then((imageSrc) => setImageSrc(imageSrc));
      }
    };

    setYieldMap(layer);
  }, [layer]);

  useEffect(() => {
    return () => setImageSrc(null);
  }, []);

  return (
    <div className="yield-info-map" ref={yieldRef}>
      {layer ? (
        <Link to={`/map/${fieldId}/layers?type=yield.512&id=${layer.id}`} className="resource-icon-wrapper no-border">
          <img className="icon" src={imageSrc} />
        </Link>
      ) : (
        <Link to="/settings/data/yieldData">
          <div className="map-empty">Upload Yield</div>
        </Link>
      )}
    </div>
  );
};

export default YieldMap;
