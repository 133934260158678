import _ from "lodash";
import { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

const useContractsIndexQueryParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const setFilters = useCallback((newFilters) => {
    setSearchParams(newFilters);
  }, []);

  return useMemo(() => {
    const date = (key) => {
      const value = searchParams.get(key);
      return /^\d{4}-\d{2}-\d{2}/.test(value) ? value : null;
    };
    const inArray = (key, whitelist) => {
      const value = searchParams.get(key);
      return whitelist.includes(value) ? value : null;
    };
    const numericArray = (key) =>
      searchParams
        .getAll(key)
        .map((value) => Number(value))
        .filter((value) => !isNaN(value));
    const stringArray = (key) => _.invokeMap(searchParams.getAll(key), "trim").filter((x) => x);

    const filters = {
      buyers: stringArray("buyers"),
      commodities: numericArray("commodities"),
      contractNumber: _.trim(searchParams.get("contractNumber")),
      contractStatus: inArray("contractStatus", ["CLOSED", "OPEN"]),
      cropYears: numericArray("cropYears").filter((value) => {
        const currentYear = new Date().getFullYear();
        return value >= currentYear - 20 && value <= currentYear + 50;
      }),
      deliveryEndDate: date("deliveryEndDate"),
      deliveryStartDate: date("deliveryStartDate"),
      marketedCrops: numericArray("marketedCrops"),
      orderBy: inArray("orderBy", ["BUYER", "COMMODITY", "DELIVERY_END_DATE"]),
      sortDirection: searchParams.get("sortDirection") === "asc" ? "asc" : "desc",
    };

    return [filters, setFilters];
  }, [searchParams]);
};

export default useContractsIndexQueryParams;
