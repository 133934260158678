import { css } from "aphrodite/no-important";
import { SupportEmailLink } from "billing/lib";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import ManageElsewhere from "settings/components/ManageElsewhere";
import styles from "settings/styles";

import { getCurrentSubscription } from "collection/graphql/subscription";
import useReleaseFeature from "hooks/useReleaseFeature";
import useRestQuery from "hooks/useRestQuery";

import { Button } from "components/fl-ui";

const ManageSubscriptionAction = (props) => {
  const preventBilling = useReleaseFeature("disable-billing-page");
  const { onCancelSubscription } = props;
  const { data, loading } = useRestQuery(getCurrentSubscription);
  if (loading) {
    return null;
  } else if (preventBilling) {
    return <div>Subscription management temporarily disabled</div>;
  }
  const { cancelAtPeriodEnd, canManageSubscription, currentPlan, isCancelable, isSubscribed, selfService } =
    data.subscription;

  if (cancelAtPeriodEnd) {
    return (
      <div>
        If you wish to renew this subscription <SupportEmailLink>contact support</SupportEmailLink>.
      </div>
    );
  }

  const shouldContactSupport = !currentPlan?.id || !selfService;
  if (shouldContactSupport) {
    return <SupportEmailLink>Contact support to manage your subscription</SupportEmailLink>;
  }

  if (!canManageSubscription) {
    return <ManageElsewhere />;
  }

  return (
    <>
      {isCancelable && (
        <Button className={css(styles.rightMargin)} color="danger" hollow onClick={onCancelSubscription}>
          Cancel subscription
        </Button>
      )}

      <Link className="btn btn-primary" to="/billing">
        {!isSubscribed ? "Start a free trial" : "Manage subscription"}
      </Link>
    </>
  );
};

ManageSubscriptionAction.propTypes = {
  onCancelSubscription: PropTypes.func.isRequired,
};

export default ManageSubscriptionAction;
