import PropTypes from "prop-types";
import React from "react";
import { useNavigate } from "react-router-dom";

import { Button } from "components/fl-ui";
import BlankSlate from "components/fl-ui/BlankSlate";

const ProductDetailsBlankSlate = (props) => {
  const navigate = useNavigate();
  const { cropYear } = props;
  const handleClick = () => {
    navigate("/products");
  };

  return (
    <BlankSlate icon="inputs">
      <p style={{ margin: "20px auto" }}>
        No purchases or activities referencing this product were found in {cropYear}.
      </p>

      <Button color="primary" onClick={handleClick}>
        View all purchases
      </Button>
    </BlankSlate>
  );
};

ProductDetailsBlankSlate.propTypes = {
  cropYear: PropTypes.number.isRequired,
};

export default ProductDetailsBlankSlate;
