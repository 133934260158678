import BinDetailWrapper from "inventory/BinDetailWrapper";
import InventoryWrapper from "inventory/InventoryWrapper";
import React from "react";

import BinsCollection from "collection/bins";
import BuyersCollection from "collection/buyers";
import InventoryTransactionsCollection from "collection/inventory_transactions";

export default [
  {
    path: "/inventory",
    loader: async () => {
      await Promise.allSettled([
        BinsCollection.prime(),
        BuyersCollection.prime(),
        InventoryTransactionsCollection.prime(),
      ]);

      return null;
    },
    element: <InventoryWrapper />,
  },

  {
    path: "/inventory/bin/:id",
    loader: async () => {
      await Promise.allSettled([
        BinsCollection.prime(),
        BuyersCollection.prime(),
        InventoryTransactionsCollection.prime(),
      ]);

      return null;
    },
    element: <BinDetailWrapper />,
  },
];
