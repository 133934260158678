import { useQuery } from "@apollo/client";

import { useAuth } from "collection/graphql/auth";
import { marketingClient } from "collection/graphql/client";
import { GET_CURRENT_ENTERPRISE } from "collection/graphql/enterprise/queries";

// import { getCurrentEnterprise } from "modules/auth/utils/currentEnterpriseUtils";

const useCurrentEnterprise = () => {
  const { isLoggedIn } = useAuth();

  // TODO: ssoCurrentEnterprise/users_memberships needs "features" added to it before we can replace this query
  // const ssoCurrentEnterprise = getCurrentEnterprise()
  // if (ssoCurrentEnterprise){
  //  return {
  //     currentEnterprise: ssoCurrentEnterprise,
  //     loading: false,
  //     refetch: ()=>{},
  //   }

  // }

  const { data, loading, refetch } = useQuery(GET_CURRENT_ENTERPRISE, {
    client: marketingClient,
    skip: !isLoggedIn,
  });

  return {
    currentEnterprise: data?.currentEnterprise,
    loading,
    refetch,
  };
};

export default useCurrentEnterprise;
