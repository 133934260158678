import PropTypes from "prop-types";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";

import {
  ALERTS_ACTION_VIEW_CROP,
  ALERTS_ACTION_VIEW_MARKETING,
  ALERTS_ACTION_VIEW_RAINFALL,
  ALERTS_ACTION_VIEW_WORKORDER,
  ALERTS_VIEW_ALL_CLICKED,
} from "lib/metrics/events";

import { Spacing } from "components/fl-ui/constants";

const FarmLink = styled(Link)`
  margin-right: ${Spacing.xxlarge};
`;

const MarketPrices = ({ data: { marketedCrops } }) => {
  const event = marketedCrops.length > 1 ? ALERTS_ACTION_VIEW_MARKETING : ALERTS_ACTION_VIEW_CROP;
  const onClick = () => event.track({});
  const to = marketedCrops.length > 1 ? "/marketing" : `/marketing/${marketedCrops[0]}`;

  return (
    <FarmLink onClick={onClick} to={to}>
      {marketedCrops.length > 1 ? "View Marketing" : "View Crop Details"}
    </FarmLink>
  );
};

const News = (props) => {
  const { data } = props;

  return !data.url ? null : (
    <a href={data.url} rel="noopener noreferrer" target="_blank">
      Learn More
    </a>
  );
};

const Rainfall = ({ data: { fieldId } }) => {
  const onClick = () => ALERTS_ACTION_VIEW_RAINFALL.track({});
  const to = fieldId ? `/fields/${fieldId}/rainfall` : "/fields";
  return (
    <FarmLink onClick={onClick} to={to}>
      View Recent Rain
    </FarmLink>
  );
};

const Rollup = () => {
  const onClick = () => ALERTS_VIEW_ALL_CLICKED.track({});
  if (useLocation().pathname === "/alerts") {
    return null;
  }

  return (
    <FarmLink onClick={onClick} to="/alerts">
      View all
    </FarmLink>
  );
};

const WorkOrders = ({ user: { id } }) => {
  const onClick = () => ALERTS_ACTION_VIEW_WORKORDER.track({});
  const to = `activity?people=${id}&sortCriteria=status`;
  return (
    <FarmLink onClick={onClick} to={to}>
      View activities
    </FarmLink>
  );
};

const NotificationActions = ({ notification }) => {
  const Actions = {
    "market-prices": MarketPrices,
    news: News,
    rainfall: Rainfall,
    rollup: Rollup,
    "work-orders": WorkOrders,
  }[notification.type];

  return Actions ? <Actions {...notification} /> : null;
};

NotificationActions.propTypes = {
  notification: PropTypes.object.isRequired,
};

export default NotificationActions;
