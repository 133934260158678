import { useSuspenseQuery } from "@apollo/client";
import useDocumentTitle from "app/hooks/useDocumentTitle";
import _ from "lodash";
import React, { useMemo, useState } from "react";
import ProductRecipeList from "settings/components/ProductRecipeList";
import InputGroup from "settings/components/input_group";
import InputItem from "settings/components/input_item";

import { getCustomProducts } from "collection/graphql/products/queries";
import useBoolean from "hooks/useBoolean";

import { withSuspenseWrapper } from "components/fl-ui/LoadingWrapper";
import ProductInputModal from "components/product/crud";

const Inputs = withSuspenseWrapper(() => {
  useDocumentTitle("Input Settings");

  const {
    data: { products },
    refetch,
  } = useSuspenseQuery(getCustomProducts);
  const {
    ChemicalProduct: chemicals,
    FertilizerProduct: fertilizers,
    SeedProduct: seeds,
  } = useMemo(() => {
    const editableProducts = _.reject(products, "public"); // public products are editable by the current enterprise
    const sorted = _.sortBy(editableProducts, "name");
    return _.groupBy(sorted, "__typename");
  }, [products]);

  const [showModal, { off, on }] = useBoolean(false);
  const [productBeingEdited, setProductBeingEdited] = useState(null);
  const edit = (product) => {
    setProductBeingEdited(product);
    on();
  };

  return (
    <div className="settings-inputs-wrapper margin-top-10">
      <InputGroup
        title="Custom Fertilizers"
        onAdd={() => {
          setProductBeingEdited({ type: "FERTILIZER" });
          on();
        }}
      >
        {_.map(fertilizers, (fertilizer) => (
          <InputItem
            key={fertilizer.id}
            onEdit={() => edit(fertilizer)}
            sub={fertilizer.shortDescription}
            title={fertilizer.name}
          />
        ))}
      </InputGroup>

      <InputGroup
        title="Custom Chemicals"
        onAdd={() => {
          setProductBeingEdited({ type: "CHEMICAL" });
          on();
        }}
      >
        {_.map(chemicals, (chemical) => (
          <InputItem
            key={chemical.id}
            onEdit={() => edit(chemical)}
            sub={chemical.shortDescription}
            title={chemical.name}
          />
        ))}
      </InputGroup>

      <InputGroup
        type="seed"
        title="Custom Seeds"
        onAdd={() => {
          setProductBeingEdited({ unitType: "COUNT", type: "SEED" });
          on();
        }}
      >
        {_.map(seeds, (seed) => (
          <InputItem key={seed.id} onEdit={() => edit(seed)} sub={seed.shortDescription} title={seed.name} />
        ))}
      </InputGroup>

      {showModal && (
        <ProductInputModal
          onClose={() => {
            setProductBeingEdited(null);
            off();
          }}
          onFinish={async (newProduct) => {
            setProductBeingEdited(null);
            if (newProduct) {
              await refetch();
            }
            off();
          }}
          product={productBeingEdited}
        />
      )}

      <ProductRecipeList />
    </div>
  );
});

Inputs.tabId = "inputs";

export default Inputs;
