/* eslint-disable react/display-name */
import { ApolloProvider, makeVar } from "@apollo/client";
import React from "react";

import EventChannel from "lib/events";
import { getCurrentEnterpriseUUID } from "modules/sso/utils/currentEnterpriseUtils";
import getSSOUser from "modules/sso/utils/getSSOUser";

const toastChannel = EventChannel.getChannel("toast");

const ssoUserVar = makeVar(null);
const currentEnterpriseVar = makeVar(null);

const authHeaders = () => {
  const ssoUser = getSSOUser();
  const currentEnterprise = getCurrentEnterpriseUUID();

  const headers = {};
  // On Legacy Auth, token will be undefined
  if (ssoUser?.access_token) {
    headers["Authorization"] = `Bearer ${ssoUser.access_token}`;

    if (currentEnterprise) {
      headers["BF-Enterprise"] = currentEnterprise;
    }
  }

  return headers;
};

// helps Apollo flatten the data results
const dataIdFromObject = (o) => o.__typename + (o.id || Math.random());

const toastAfterWare = ({ response, data }) => {
  const ON_THE_CASE = "We've been notified of the problem and will fix it soon!";
  const INTERNAL_SERVER_ERROR_MSG = `There was an internal server error. ${ON_THE_CASE}`;
  const GENERIC_ERROR_MESSAGE = `There was a problem processing your request. ${ON_THE_CASE}`;

  if (!response.ok) {
    if (response.status === 500) {
      toastChannel.fire("warn", { message: INTERNAL_SERVER_ERROR_MSG });
    } else {
      toastChannel.fire("warn", { message: GENERIC_ERROR_MESSAGE });
    }
  } else if (data?.errors) {
    toastChannel.fire("warn", { message: GENERIC_ERROR_MESSAGE });
  }
};

const trackingCallback = (operation, forward) =>
  forward(operation).map(({ data, errors }) => {
    try {
      const { response } = operation.getContext();
      if (response) {
        toastAfterWare({ data, response });
      }
    } catch (e) {
      console.error(e);
    }

    return { data, errors };
  });

const wrapWithProvider = (DataComponent, client) => (props) =>
  (
    <ApolloProvider client={client}>
      <DataComponent {...props} />
    </ApolloProvider>
  );

export { authHeaders, currentEnterpriseVar, dataIdFromObject, ssoUserVar, trackingCallback, wrapWithProvider };
