import { Arrow, FlatButton, TopBar } from "map/layers/helpers";
import React from "react";
import { useNavigate } from "react-router-dom";

import { EXPLORER_SELECTION_FIELD } from "lib/metrics/events";

import Chooser from "components/ui/chooser";
import { withGroupedFieldsOptions } from "fields/hooks/useGroupedFields";

const { Option, Group } = Chooser;

const chooserStyle = {
  color: "white",
  fontWeight: "500",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  width: "100%",
};

const backStyle = {
  display: "flex",
  fontWeight: "500",
  justifyContent: "flex-start",
  textAlign: "left",
};

const Nav = ({ chooserOptions, value = null }) => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  const handleFieldChange = ({ value }) => {
    if (value === null) {
      return;
    }

    EXPLORER_SELECTION_FIELD.track({
      entity_id: value,
    });

    navigate(`/map/${value}/layers`, { replace: true });
  };

  return (
    <TopBar color="black">
      <div className="col xs-4 md-2">
        <FlatButton id="fde-back-button" color="white" onClick={handleBack} style={backStyle}>
          <Arrow color="white" direction="left" style={{ width: "1.3em" }} />
          <span style={{ padding: ".1em .5em 0" }}>{" Back"}</span>
        </FlatButton>
      </div>
      <div className="col xs-8 md-2 md-offset-3">
        <div className="select-wrapper" style={{ display: "inherit", margin: 0, color: "white" }}>
          <Chooser
            name="field_id"
            className="btn btn-transparent"
            label="field"
            onChange={handleFieldChange}
            placeholder="Choose a field"
            style={chooserStyle}
            value={value}
          >
            {chooserOptions.map(({ name, options }) => (
              <Group key={name} label={name}>
                {options.map(({ value, name }) => (
                  <Option key={value} value={value}>
                    {name}
                  </Option>
                ))}
              </Group>
            ))}
          </Chooser>
        </div>
      </div>
    </TopBar>
  );
};

export default withGroupedFieldsOptions(Nav);
