import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";
import moment from "moment";

import { COMMODITY } from "collection/graphql/commodities/fragments";
import { MinimalCashContract, ScaleTicket } from "collection/graphql/contracts/fragments";
import { BasicUnderlying } from "collection/graphql/marketing/fragments";

export const BID_FRAGMENT = gql`
  fragment Bid on Bid {
    basis
    bidType
    buyer {
      acceptsOfferDelivery
      id
      fullName
      name
      address
      distance
      city
      state
      zip
    }
    history {
      close
      date
      high
      low
      netChange
      percentChange
      open
    }
    delivery
    netChange
    percentChange
    price
    timestamp
    underlying {
      commodity {
        abbr
        color
        name
      }
      month
      year
    }
  }
`;

const BUYERS_FRAGMENT = gql`
  fragment Buyers on Elevator {
    acceptsOfferDelivery
    hasPrices
    address
    city
    distance
    id
    latitude
    longitude
    name
    state
    zip
  }
`;

const MARKETED_CROP_GOAL_FRAGMENT = gql`
  fragment MarketedCropGoal on MarketedCropGoal {
    dueDate
    targetPercentMarketed
    targetBushelsMarketed
    percentMarketed
    bushelsMarketed
    bushelsRemaining
    status
  }
`;

export const PRICE_SNAPSHOT_FRAGMENT = gql`
  fragment PriceSnapshot on PriceSnapshot {
    close
    current
    high
    low
    netChange
    open
    priceTimestamp
    __typename
  }
`;

const PROGRESS_SECTION_FRAGMENT = gql`
  fragment ProgressSection on ProgressSection {
    quantity
    value
    percentage
  }
`;

const PROGRESS_FRAGMENT = gql`
  fragment Progress on Progress {
    sold {
      profitPerAcre
      profitPerUnit
      valuePerUnit
      ...ProgressSection
    }
    hedged {
      profitPerAcre
      profitPerUnit
      valuePerUnitMin
      ...ProgressSection
    }
    unprotected {
      valuePerUnit
      profitPerAcre
      profitPerUnit
      ...ProgressSection
    }
    marketed {
      profit
      profitPerAcre
      profitPerUnit
      valuePerUnit
      ...ProgressSection
    }
    total {
      valuePerUnit
      ...ProgressSection
      __typename
    }
  }
  ${PROGRESS_SECTION_FRAGMENT}
`;

const PRODUCTION_STATS_FRAGMENT = gql`
  fragment ProductionStats on ProductionStatsCollection {
    total {
      quantity
      percentage
    }
    cash {
      quantity
      percentage
    }
    options {
      quantity
      percentage
    }
    futures {
      quantity
      percentage
    }
  }
`;

const ABBREVIATED_MARKETED_CROP = gql`
  fragment AbbreviatedMarketedCrop on MarketedCrop {
    acresPlanted
    averageYield
    disableExchangePricing
    harvestBasis
    harvestPrice
    id
    isMissingFinancialData
    irrigated
    name
    productionCost
    useItemizedProductionCosts
    year
    commodity {
      ...Commodity
    }
    fields {
      id
      name
      acreage
      crops {
        id
        acreage
        cropYear
        commodity {
          ...Commodity
        }
      }
    }
    itemizedProductionCosts {
      category
      cost
      unit
    }
  }

  ${COMMODITY}
`;

const MARKETED_CROP_FRAGMENT = gql`
  fragment MarketedCrop on MarketedCrop {
    ...AbbreviatedMarketedCrop

    allowsSpeculativeContracts @client
    activeUnderlying {
      priceTimestamp
      shortName
    }
    brokerageGainLoss
    currentMarketPrice
    deliveryLocationType
    deliveryRadius
    deliveryTargets {
      id
      name
    }
    goals {
      ...MarketedCropGoal
    }
    productionCostPerAcre
    productionCostPerUnit
    productionCostUnit
    totalProductionCost
    harvestMonth {
      expirationDate
    }
    harvestPrice
    productionStats {
      ...ProductionStats
    }
    progress {
      ...Progress
    }
    underlyings {
      ...BasicUnderlying
    }
    year
  }

  ${ABBREVIATED_MARKETED_CROP}
  ${BasicUnderlying}
  ${MARKETED_CROP_GOAL_FRAGMENT}
  ${PRODUCTION_STATS_FRAGMENT}
  ${PROGRESS_FRAGMENT}
`;

const CASH_CONTRACT_SUMMARY_FRAGMENT = gql`
  fragment CashContractSummary on CashContract {
    id
    basis
    buyer
    buyerName @client
    cashContractType: contractType
    contractDate
    contractSubtype @client
    contractStatus
    contractType
    deliveryEndDate
    deliveryStartDate
    enabled
    marketedCrop {
      id
      commodity {
        ...Commodity
      }
    }
    netPrice
    quantity
    quantityUnit
    revenue
    underlying {
      commodity {
        ...Commodity
      }
      currentMarketPrice
      fullName
      shortName
      symbol
    }
  }

  ${COMMODITY}
`;

const CASH_CONTRACT_FRAGMENT = gql`
  fragment CashContract on CashContract {
    ...MinimalCashContract
    ...CashContractSummary
    averagePricedValue
    basis
    contractPrice
    created
    cropYear
    deliveryLocation {
      id
      latitude
      locationDisplay
      longitude
    }
    marketedCrop {
      id
      commodity {
        ...Commodity
      }
    }
    notes
    percentagePriced
    premium
    pricingDays
    pricingWindowEnd
    pricingWindowStart
    quantityPriced
    quantityUnit
    marketedCrop {
      id
      name
      commodity {
        ...Commodity
      }
    }
    minPrice
    maxPrice
    scaleTickets {
      ...ScaleTicket
    }
    fulfilledQuantity
    delivered {
      ...ProgressSection
    }
    undelivered {
      ...ProgressSection
    }
  }

  ${CASH_CONTRACT_SUMMARY_FRAGMENT}
  ${COMMODITY}
  ${MinimalCashContract}
  ${PROGRESS_SECTION_FRAGMENT}
  ${ScaleTicket}
`;

const FUTURES_CONTRACT_FRAGMENT = gql`
  fragment FuturesContract on FuturesContract {
    id
    enabled
    contractDate
    contractStatus
    notes
    quantity
    quantityUnit
    underlying {
      shortName
      fullName
      symbol
      currentMarketPrice
    }
    marketedCrop {
      id
      name
      commodity {
        ...Commodity
      }
    }
    expirationDate
    contractPrice
    contractSubtype @client
    contractType
    futuresContractType: contractType
    settlementPrice
    netPrice
    revenue
    mtm
    netGain
  }

  ${COMMODITY}
`;

const OPTIONS_CONTRACT_FRAGMENT = gql`
  fragment OptionsContract on OptionsContract {
    id
    enabled
    contractDate
    contractStatus
    contractSubtype @client
    contractType
    notes
    quantity
    quantityUnit
    marketedCrop {
      id
      name
      commodity {
        ...Commodity
      }
    }
    underlying {
      shortName
      fullName
      symbol
    }
    expirationDate
    strikePrice
    premium
    currentMarketPremium
    optionsContractType: contractType
    revenueImpact
    mtm
    netValue
  }

  ${COMMODITY}
`;

const CONTRACT_LIST_SUMMARY_FRAGMENT = gql`
  fragment ContractListSummary on ContractListSummary {
    totalQuantity
    totalGainLoss
    averageContractAmount
    totalRevenue
  }
`;

// Queries
const FILTER_BUYERS = gql`
  query getFilteredBuyers($zipCode: String!, $radius: Int) {
    buyers: filterBuyers(zipCode: $zipCode, radius: $radius) {
      ...Buyers
    }
  }
  ${BUYERS_FRAGMENT}
`;

export const GET_DASHBOARD_MARKETED_CROPS = gql`
  query getDashboardMarketedCrops($year: Int!) {
    marketedCrops: getAllMarketedCrops(year: $year) @requiresPermission(feature: "marketing", actions: ["read"]) {
      allowsSpeculativeContracts @client
      commodity {
        abbr
        color
        id
        isExchangeTraded
        name
      }
      id
      name
      year
    }

    partialMarketedCrops: getPartialMarketedCrops(year: $year)
      @requiresPermission(feature: "marketing", actions: ["read"]) {
      acresPlanted
      averageYield
      commodity {
        ...Commodity
      }
      currentMarketPrice
      fields {
        id
        name
        acreage
        crops {
          acreage
          cropYear
          commodity {
            ...Commodity
          }
        }
      }
      irrigated
      name
      projectedRevenue
      year
    }
  }

  ${COMMODITY}
`;

const GET_FUTURES_CONTRACT = gql`
  query getFuturesContract($symbol: UnderlyingSymbol, $commodity: Int) {
    contract: getFuturesContract(symbol: $symbol, commodity: $commodity) {
      commodity {
        ...Commodity
      }
      high30Day
      history {
        close
        high
        low
        open
        netChange
        priceTimestamp
      }
      low30Day
      priceSnapshot {
        current
        high
        low
        currentOverMovingAverage20Day
        open
        priceTimestamp
      }
      shortName
      symbol
    }
  }

  ${COMMODITY}
`;

export const GET_MARKETED_CROP = gql`
  query MarketedCrop($id: Int!) {
    marketedCrop: getMarketedCrop(id: $id) {
      ...MarketedCrop
    }
  }
  ${MARKETED_CROP_FRAGMENT}
`;

export const GET_ALL_MARKETED_CROPS = gql`
  query AllMarketedCrops($commodities: [Int], $year: Int) {
    marketedCrops: getAllMarketedCrops(commodities: $commodities, year: $year)
      @requiresPermission(feature: "marketing", actions: ["read"]) {
      acresPlanted
      commodity {
        id
        name
      }
      fields {
        id
        name
      }
      id
      name
      year
    }
  }
`;

export const CASH_CONTRACTS_SUMMARY = gql`
  query CashContracts($marketedCropId: Int!) {
    contractsList: getCashContracts(marketedCropId: $marketedCropId) {
      contracts {
        ...CashContractSummary
      }
    }
  }
  ${CASH_CONTRACT_SUMMARY_FRAGMENT}
`;

export const FUTURES_CONTRACTS = gql`
  query FuturesContracts($marketedCropId: Int!, $contractSource: ContractSource!, $contractStatus: ContractStatus!) {
    contractData: getContracts(
      marketedCropId: $marketedCropId
      contractSource: $contractSource
      contractStatus: $contractStatus
    ) {
      contracts {
        ... on FuturesContract {
          ...FuturesContract
        }
      }

      summary {
        ...ContractListSummary
      }
    }
  }

  ${FUTURES_CONTRACT_FRAGMENT}
  ${CONTRACT_LIST_SUMMARY_FRAGMENT}
`;

export const OPTIONS_CONTRACTS = gql`
  query OptionsContracts($marketedCropId: Int!, $contractSource: ContractSource!, $contractStatus: ContractStatus!) {
    contractData: getContracts(
      marketedCropId: $marketedCropId
      contractSource: $contractSource
      contractStatus: $contractStatus
    ) {
      contracts {
        __typename
        ... on OptionsContract {
          ...OptionsContract
        }
      }

      summary {
        ...ContractListSummary
      }
    }
  }

  ${OPTIONS_CONTRACT_FRAGMENT}
  ${CONTRACT_LIST_SUMMARY_FRAGMENT}
`;

export const CASH_CONTRACT = gql`
  query getCashContract($id: Int!) {
    contract: getContract(id: $id) {
      ... on CashContract {
        ...CashContract
      }
    }
  }

  ${CASH_CONTRACT_FRAGMENT}
`;

const CONTRACT_HISTORY_BY_COMMODITY = gql`
  query getFuturesContract($commodity: Int!, $startDate: Date!, $endDate: Date) {
    contract: getContinuousFuturesContract(commodity: $commodity, startDate: $startDate, endDate: $endDate) {
      continuousHistory {
        ...PriceSnapshot
      }
      symbol

      __typename
    }
  }

  ${PRICE_SNAPSHOT_FRAGMENT}
`;

export const FUTURES_CONTRACT = gql`
  query getFuturesContract($id: Int!) {
    contract: getContract(id: $id) {
      ... on FuturesContract {
        ...FuturesContract
      }
    }
  }

  ${FUTURES_CONTRACT_FRAGMENT}
`;

export const OPTIONS_CONTRACT = gql`
  query getOptionsContract($id: Int!) {
    contract: getContract(id: $id) {
      ... on OptionsContract {
        ...OptionsContract
      }
    }
  }

  ${OPTIONS_CONTRACT_FRAGMENT}
`;

const PRODUCTION_COST_ENUMS = gql`
  query GetProductionCostEnums {
    productionCostUnits: getEnum(enum: "ProductionCostUnit") {
      data
      display
    }
    productionCostCategory: getEnum(enum: "ProductionCostCategory") {
      data
      display
    }
  }
`;

const MARKETING_CONFIG_FRAGMENT = gql`
  fragment MarketingConfig on Config {
    autoHedge {
      allowEnrollment
    }
    topBids {
      sourceType
      bidType
      enterpriseCountyHasBids
      buyers {
        id
        name
      }
    }
    localPrices {
      bidType
      counties {
        fips
        id
        name
        stateAbbr
        stateFips
        stateName
      }
      commodity {
        ...Commodity
      }
    }
  }

  ${COMMODITY}
`;

export const GET_MARKETING_CONFIG = gql`
  query getMarketingConfig {
    marketingConfig: getMarketingConfig {
      ...MarketingConfig
    }
  }

  ${MARKETING_CONFIG_FRAGMENT}
`;

export const GET_MARKETED_CROPS_SUMMARY = gql`
  query MarketedCropsSummary($year: Int!) {
    summary: getMarketedCropsPositionsSummary(year: $year) {
      protected
      unprotected
      profit
    }
  }
`;

// Mutations

export const CREATE_MARKETED_CROP = gql`
  mutation CreateMarketedCrop($input: MarketedCropInput!) {
    createMarketedCrop(input: $input) {
      marketedCrop {
        id
        year
        goals {
          ...MarketedCropGoal
        }
        commodity {
          ...Commodity
        }
      }
    }
  }
  ${MARKETED_CROP_GOAL_FRAGMENT}
  ${COMMODITY}
`;

export const DELETE_MARKETED_CROP = gql`
  mutation DeleteMarketedCrop($id: Int!) {
    deleteMarketedCrop(id: $id) {
      ok
    }
  }
`;

export const EDIT_MARKETED_CROP = gql`
  mutation EditMarketedCrop($id: Int!, $input: MarketedCropInput!) {
    editMarketedCrop(id: $id, input: $input) {
      marketedCrop {
        ...MarketedCrop
      }
    }
  }
  ${MARKETED_CROP_FRAGMENT}
`;

export const CREATE_CASH_CONTRACT = gql`
  mutation CreateCashContract($input: CashContractInput!) {
    createCashContract(input: $input) {
      contract {
        ...CashContract
      }
    }
  }
  ${CASH_CONTRACT_FRAGMENT}
`;

const CREATE_FUTURES_CONTRACT = gql`
  mutation CreateFuturesContract($input: FuturesContractInput) {
    createFuturesContract(input: $input) {
      contract {
        ...FuturesContract
      }
    }
  }
  ${FUTURES_CONTRACT_FRAGMENT}
`;

const CREATE_OPTIONS_CONTRACT = gql`
  mutation CreateOptionsContract($input: OptionsContractInput) {
    createOptionsContract(input: $input) {
      contract {
        ...OptionsContract
      }
    }
  }
  ${OPTIONS_CONTRACT_FRAGMENT}
`;

export const EDIT_CASH_CONTRACT = gql`
  mutation EditCashContract($id: Int!, $input: CashContractInput!) {
    editCashContract(id: $id, input: $input) {
      contract {
        ...CashContract
      }
    }
  }
  ${CASH_CONTRACT_FRAGMENT}
`;

export const EDIT_FUTURES_CONTRACT = gql`
  mutation EditFuturesContract($id: Int!, $input: FuturesContractInput!) {
    editFuturesContract(id: $id, input: $input) {
      contract {
        ...FuturesContract
      }
    }
  }
  ${FUTURES_CONTRACT_FRAGMENT}
`;

export const EDIT_OPTIONS_CONTRACT = gql`
  mutation EditOptionsContract($id: Int!, $input: OptionsContractInput!) {
    editOptionsContract(id: $id, input: $input) {
      contract {
        ...OptionsContract
      }
    }
  }
  ${OPTIONS_CONTRACT_FRAGMENT}
`;

const CREATE_SCALE_TICKET = gql`
  mutation CreateScaleTicket($input: ScaleTicketInput!) {
    createScaleTicket(input: $input) {
      scaleTicket {
        ...ScaleTicket
      }
    }
  }
  ${ScaleTicket}
`;

export const EDIT_SCALE_TICKET = gql`
  mutation EditScaleTicket($input: ScaleTicketInput!) {
    editScaleTicket(input: $input) {
      scaleTicket {
        ...ScaleTicket
      }
    }
  }
  ${ScaleTicket}
`;

const DELETE_SCALE_TICKET = gql`
  mutation DeleteScaleTicket($id: Int!) {
    deleteScaleTicket(id: $id) {
      ok
    }
  }
`;

const DISMISS_PARTIAL_MARKETED_CROP = gql`
  mutation DismissPartialMarketedCrop($commodityId: Int!, $year: Int!) {
    dismissPartialMarketedCrop(commodityId: $commodityId, year: $year) {
      ok
    }
  }
`;

export const SET_MARKETING_CONFIG = gql`
  mutation SetMarketingConfig($config: ConfigInput!) {
    setMarketingConfig(config: $config) {
      ok
      config {
        ...MarketingConfig
      }
    }
  }

  ${MARKETING_CONFIG_FRAGMENT}
`;

// queries

export const withAllMarketedCrops = graphql(GET_ALL_MARKETED_CROPS, {
  options: (props) => ({
    variables: {
      commodities: props.commodities,
      year: props.year,
    },
  }),
});

const POLL_INTERVAL = 5 * 60 * 1000;

export const withFilteredBuyersList = graphql(FILTER_BUYERS, {
  options: ({ radius, zipCode }) => ({
    name: "buyers",
    fetchPolicy: "cache-and-network",
    variables: {
      radius,
      zipCode: zipCode + "",
    },
  }),
});

export const withContractHistory = graphql(CONTRACT_HISTORY_BY_COMMODITY, {
  options: (props) => {
    return {
      fetchPolicy: "cache-and-network",
      pollInterval: POLL_INTERVAL, // refresh every 5 minutes
      variables: {
        commodity: props.commodityId,
        startDate: props.startDate || moment().subtract(1, "year").format("YYYY-MM-DD"),
        endDate: props.endDate || moment().format("YYYY-MM-DD"),
      },
    };
  },
});

export const withFuturesContract = graphql(GET_FUTURES_CONTRACT, {
  options: (props) => ({
    variables: {
      commodity: !props.symbol && props.commodity,
      symbol: props.symbol,
    },
  }),
});

export const withProductionCostEnums = graphql(PRODUCTION_COST_ENUMS);

// Mutations

export const withCreateMarketedCrop = graphql(CREATE_MARKETED_CROP, {
  props: ({ mutate }) => ({
    createMarketedCrop: (input) =>
      mutate({
        variables: {
          input,
        },
      }),
  }),
  options: ({ marketedCrop = {} }) => ({
    refetchQueries: [
      {
        query: GET_DASHBOARD_MARKETED_CROPS,
        variables: { year: marketedCrop?.year },
      },
      {
        query: GET_MARKETED_CROPS_SUMMARY,
        variables: { year: marketedCrop?.year },
      },
    ],
  }),
});

export const withEditMarketedCrop = graphql(EDIT_MARKETED_CROP, {
  props: ({ mutate }) => ({
    editMarketedCrop: ({ id, input }) =>
      mutate({
        variables: {
          id,
          input,
        },
      }),
  }),
  options: { refetchQueries: ["TotalFarmOverview", "MarketedCrop", "MarketedCropsSummary"] },
});

export const withCreateCashContract = graphql(CREATE_CASH_CONTRACT, {
  props: ({ mutate }) => ({
    createCashContract: (input) =>
      mutate({
        variables: {
          input,
        },
      }),
  }),
  options: { refetchQueries: ["CashContracts", "MarketedCrop"] },
});

export const withCreateFuturesContract = graphql(CREATE_FUTURES_CONTRACT, {
  props: ({ mutate }) => ({
    createFuturesContract: (input) =>
      mutate({
        variables: {
          input,
        },
      }),
  }),
  options: { refetchQueries: ["FuturesContracts", "MarketedCrop"] },
});

export const withCreateOptionsContract = graphql(CREATE_OPTIONS_CONTRACT, {
  props: ({ mutate }) => ({
    createOptionsContract: (input) =>
      mutate({
        variables: {
          input,
        },
      }),
  }),
  options: { refetchQueries: ["OptionsContracts", "MarketedCrop"] },
});

/*
 * CORE-2083: if basis is not included in the payload, it will be deleted.
 * This has to do with the way graphql handles undefined/null/0 values.
 */
export const withEditCashContract = graphql(EDIT_CASH_CONTRACT, {
  props: ({ mutate }) => ({
    editCashContract: (variables) =>
      mutate({
        variables,
      }),
  }),
  options: { refetchQueries: ["CashContracts", "MarketedCrop"] },
});

export const withEditFuturesContract = graphql(EDIT_FUTURES_CONTRACT, {
  props: ({ mutate }) => ({
    editFuturesContract: (variables) =>
      mutate({
        variables,
      }),
  }),
  options: { refetchQueries: ["FuturesContracts", "MarketedCrop"] },
});

export const withEditOptionsContract = graphql(EDIT_OPTIONS_CONTRACT, {
  props: ({ mutate }) => ({
    editOptionsContract: (variables) =>
      mutate({
        variables,
      }),
  }),
  options: { refetchQueries: ["OptionsContracts", "MarketedCrop"] },
});

export const withCreateScaleTicket = graphql(CREATE_SCALE_TICKET, {
  props: ({ mutate }) => ({
    createScaleTicket: (variables) =>
      mutate({
        variables,
      }),
  }),
  options: {
    refetchQueries: ["getCashContract"],
  },
});

export const withEditScaleTicket = graphql(EDIT_SCALE_TICKET, {
  props: ({ mutate }) => ({
    editScaleTicket: (variables) =>
      mutate({
        variables,
      }),
  }),
  options: {
    refetchQueries: ["getCashContract"],
  },
});

export const withDeleteScaleTicket = graphql(DELETE_SCALE_TICKET, {
  props: ({ mutate }) => ({
    deleteScaleTicket: (variables) =>
      mutate({
        variables,
      }),
  }),
  options: {
    refetchQueries: ["getCashContract"],
  },
});

export const withDismissPartialMarketedCrop = graphql(DISMISS_PARTIAL_MARKETED_CROP, {
  props: ({ mutate }) => ({
    dismissPartialMarketedCrop: (variables) =>
      mutate({
        variables,
      }),
  }),
  options: {
    //Do I need to rename this one?
    refetchQueries: ["getDashboardMarketedCrops"],
  },
});
