import createReactClass from "create-react-class";
import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";

import CloseX from "components/fl-ui/Icons/CloseX";
import { Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle } from "components/fl-ui/Modal/Modal";
import Pager from "components/ui/pager";

const TourPage = function ({
  title,
  imageURL,
  description,
  onBack,
  onNext,
  currentPage,
  totalPages,
  onClose,
  completeText,
  hideComplete,
}) {
  const lastPage = currentPage === totalPages;

  return (
    <Modal
      width={700}
      onClose={function () {
        return onClose(currentPage - 1);
      }}
    >
      <ModalHeader>
        <ModalTitle>What is Your Satellite Imagery Showing You?</ModalTitle>
        <CloseX
          onClick={function () {
            return onClose(currentPage - 1);
          }}
        />
      </ModalHeader>
      <img src={imageURL} />
      <ModalBody>
        <h3 style={{ marginTop: 0 }}> {title} </h3>
        <p style={{ textAlign: "left" }}> {description} </p>
      </ModalBody>
      <ModalFooter>
        <div style={{ position: "relative" }}>
          <a
            disabled={currentPage === 1}
            style={{ position: "absolute", left: 0 }}
            className="btn pull-left"
            onClick={onBack}
          >
            {`\
    Previous\
    `}
          </a>
          {!(lastPage && hideComplete) ? (
            <a style={{ position: "absolute", right: 0 }} className="btn btn-primary pull-right" onClick={onNext}>
              {lastPage ? completeText : "Next"}
            </a>
          ) : undefined}
        </div>
        <div style={{ textAlign: "center" }}>
          <span className="gray-text" style={{ padding: "7px", display: "block" }}>
            {`${currentPage} of ${totalPages}`}
          </span>
        </div>
      </ModalFooter>
    </Modal>
  );
};

TourPage.displayName = "TourPage";

const { string, bool, object } = PropTypes;

const ImageryTour = createReactClass({
  displayName: "ImageryTour",

  propTypes: {
    completeText: string,
    hideComplete: bool,
    style: object,
  },

  getDefaultProps() {
    return {
      completeText: "Learn More",
      hideComplete: false,
    };
  },

  getInitialState() {
    return { showTour: false };
  },

  handleClose() {
    return this.setState({ showTour: false });
  },

  handleClick() {
    return this.setState({ showTour: true });
  },

  handleComplete() {
    const url = "https://support.bushelfarm.com/hc/en-us/articles/5402169521300-About-FarmLogs-Satellite-Imagery";
    return window.open(url, "_blank", "noopener,noreferrer");
  },

  render() {
    const props = _.pick(this.props, ["completeText", "hideComplete"]);

    return (
      <span>
        <a
          onClick={this.handleClick}
          style={
            this.props.style || {
              textDecoration: "underline",
            }
          }
        >
          {this.props.children || "Learn More about Crop Health Imagery"}
        </a>
        {this.state.showTour ? (
          <Pager onComplete={this.handleComplete}>
            <TourPage
              imageURL="/images/advantage/preview/tour_1_chi@2x.jpg"
              title="Nitrogen Deficiency/Herbicide Overlap Injury"
              description="The farmer of this field confirmed that the short, slightly curved streaks in the middle of the image were caused by a lack of nitrogen applied near a waterway."
              onClose={this.handleClose}
              {...props}
            />
            <TourPage
              imageURL="/images/advantage/preview/tour_2_chi@2x.jpg"
              title="Nitrogen Stress from Uneven Application"
              description="The more visible streaks suggest nitrogen stress and are consistent with the frequency of charging the NH3 tanks. Therefore, the low yield in this field is the result of differences in equipment uniformity."
              onClose={this.handleClose}
              {...props}
            />
            <TourPage
              imageURL="/images/advantage/preview/tour_3_chi@2x.jpg"
              title="Low Application Pressure"
              description="Often we have variation in equipment application that we don't want. The pattern above can be caused from driving before the pressure is high enough in the applicator and can result in yield loss."
              onClose={this.handleClose}
              {...props}
            />
            <TourPage
              imageURL="/images/advantage/preview/tour_4_chi@2x.jpg"
              title="Malfunctioning Planter Row"
              description="The light vertical streaking indicates planting row problems. This farmer was using a 24-row planter. Two rows out of every pass weren't getting planted."
              onClose={this.handleClose}
              {...props}
            />
          </Pager>
        ) : undefined}
      </span>
    );
  },
});

export default ImageryTour;
