import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import { getImageUrl } from "components/field/utils";
import { GreyColors } from "components/fl-ui/colors";

const CommentaryCommodityReport = styled(({ className, detail }) => {
  const [imageAssets, pdfAssets] = _.partition(detail?.assets, { type: "IMAGE" });

  const buttonTitle = detail.commodity ? detail.commodity.name + " Recap" : "Fertilizer & Energy Market";

  return (
    <div className={className}>
      <h3>{detail?.commodity?.name}</h3>

      {imageAssets.map((asset) => {
        return (
          <div key={asset.filepickerId}>
            <img src={getImageUrl(asset.filepickerId)} />
          </div>
        );
      })}

      {pdfAssets.map((asset, index) => {
        return (
          <div key={index}>
            <a
              className="btn btn-secondary commentary-commodity-report-button"
              href={asset.url}
              target="_blank"
              rel="noreferrer"
            >
              {buttonTitle}
            </a>
          </div>
        );
      })}
    </div>
  );
})`
  h3 {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    color: ${GreyColors.smoke30};
  }
  .commentary-commodity-report-button {
    margin-top: 8px;
    margin-bottom: 24px;
  }
`;

CommentaryCommodityReport.propTypes = {
  detail: PropTypes.object.isRequired,
};

export default CommentaryCommodityReport;
