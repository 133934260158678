"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const useBoolean = (initialState = false) => {
    const [state, setState] = (0, react_1.useState)(initialState);
    return (0, react_1.useMemo)(() => {
        return [
            state,
            {
                off: () => setState(false),
                on: () => setState(true),
                toggle: () => setState((value) => !value),
            },
        ];
    }, [state]);
};
exports.default = useBoolean;
