import { gql } from "@apollo/client";
import { createFragmentRegistry } from "@apollo/client/cache";

import { ActivityInputDetail } from "collection/graphql/activities/fragments";
import { COMMODITY as Commodity } from "collection/graphql/commodities/fragments";
import ImplementDetailFragment from "collection/graphql/equipment/fragments/ImplementDetailFragment";
import MaintenanceRecordFragment from "collection/graphql/equipment/fragments/MaintenanceRecordFragment";
import TractorDetailFragment from "collection/graphql/equipment/fragments/TractorDetailFragment";
import FieldCropFragment from "collection/graphql/fieldCrops/fragments/FieldCropFragment";
import { BASIC_FIELD } from "collection/graphql/fields/fragments";
import FieldDetailFragment from "collection/graphql/fields/fragments/FieldDetailFragment";
import FieldStatsFragment from "collection/graphql/fields/fragments/FieldStatsFragment";
import ProductionHistory from "collection/graphql/fields/fragments/ProductionHistory";
import {
  DetailedProduct,
  DetailedProductPurchaseSummary,
  InputPurchase,
  ProductActivity,
} from "collection/graphql/products/fragments";
import ReportDefinitionFragment from "collection/graphql/reports/fragments/ReportDefinitionFragment";

export default () =>
  createFragmentRegistry(
    gql`
      ${ActivityInputDetail}
      ${BASIC_FIELD}
      ${Commodity}
      ${DetailedProduct}
      ${DetailedProductPurchaseSummary}
      ${FieldCropFragment}
      ${FieldDetailFragment}
      ${FieldStatsFragment}
      ${ImplementDetailFragment}
      ${InputPurchase}
      ${MaintenanceRecordFragment}
      ${ProductActivity}
      ${ProductionHistory}
      ${ReportDefinitionFragment}
      ${TractorDetailFragment}
    `
  );
