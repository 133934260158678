import React from "react";
import Loads from "loads/index";

import BinsCollection from "collection/bins";
import BuyersCollection from "collection/buyers";
import InventoryTransactionsCollection from "collection/inventory_transactions";


export default [
  {
    path: "/loads",
    loader: async () => {
      await Promise.allSettled([
        BinsCollection.prime(),
        BuyersCollection.prime(),
        InventoryTransactionsCollection.prime(),
      ]);

      return null;
    },
    element: <Loads />,
  }
];
