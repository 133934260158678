import React from "react";
import { replace, useParams } from "react-router-dom";

import useDocumentTitle from "app/hooks/useDocumentTitle";

import IndigoEnrollment from "carbon_enrollment/pages/IndigoEnrollment";
import NoriEnrollment from "carbon_enrollment/pages/NoriEnrollment";
import RegrowEnrollment from "carbon_enrollment/pages/RegrowEnrollment";
import { withSuspenseWrapper } from "components/fl-ui/LoadingWrapper";


export const enrollmentPageLoader = ({ params: { clientId } }) => {
  const isValidClient = ["indigo", "nori", "regrow"].includes(clientId);
  return !isValidClient ? replace("/fields") : null;
};

const CommonEnrollmentPage = withSuspenseWrapper(() => {
  useDocumentTitle("Carbon enrollment");

  const clients = {
    indigo: IndigoEnrollment,
    nori: NoriEnrollment,
    regrow: RegrowEnrollment,
  };
  const ClientComponent = clients[useParams().clientId];

  return <ClientComponent />;
});

export default CommonEnrollmentPage;
