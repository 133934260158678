import React from "react";
import ImageryDashboard from "imagery/pages/Dashboard";


export default [
  {
    path: "/imagery",
    element: <ImageryDashboard />,
  }
];
