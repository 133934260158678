"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const getName = (user) => {
    const { firstName = "User", lastName = "Name" } = user;
    return {
        firstName,
        lastName,
        firstInitial: firstName.charAt(0),
        lastInitial: lastName.charAt(0),
    };
};
exports.default = getName;
