import { ImageryContainer, MainText, Subtext, WrappedRow } from "imagery/styledComponents";
import { upperFirst } from "lodash";
import { getTypeUrl, sanitizeImagery } from "map/layers/utils";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { useNavigate } from "react-router-dom";

import BasicFieldMap from "components/field/BasicFieldMap";

const computeLayer = (isVisible, layer, type) => (isVisible ? sanitizeImagery(layer, type)?.layer : null);
const mapStyles = {
  borderRadius: "4px",
  cursor: "pointer",
  height: "16rem",
  marginBottom: "1rem",
  maxWidth: "100%",
  touchAction: "auto",
  width: "16rem",
};

const shouldLayerChange = (nextProps, nextState) =>
  getTypeUrl(nextProps.layer, nextProps.type) !== nextState.currentLayer?.layer?.details?.url;

const ImageryList = ({ layers, type }) => {
  const navigate = useNavigate();

  return (
    <WrappedRow>
      {layers.map((layer) => (
        <ImageryContainer key={layer.field.id}>
          <BasicFieldMap
            backdrop
            centroid={layer.field.centroid}
            computeLayer={computeLayer}
            geometry={layer.field.geometry}
            layer={layer}
            onClick={() => navigate(`/map/${layer.field.id}/layers?type=imagery&id=${layer.id}`)}
            shouldLayerChange={shouldLayerChange}
            style={mapStyles}
            type={type}
            zoom={14}
          />

          <Subtext>{moment(layer.date).format("MMM D, YYYY").toUpperCase()}</Subtext>

          <MainText>{upperFirst(layer.field.name)}</MainText>
        </ImageryContainer>
      ))}
    </WrappedRow>
  );
}

ImageryList.propTypes = {
  layers: PropTypes.arrayOf(PropTypes.object).isRequired,
  type: PropTypes.string.isRequired,
};

export default ImageryList;
