"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const client_1 = require("@apollo/client");
exports.default = (0, client_1.gql) `
  mutation deleteProduct($id: Int!) {
    product: deleteProduct(id: $id) @rest(type: "BaseProduct", path: "/api/products/{args.id}", method: "DELETE") {
      ...DetailedProduct
    }
  }
`;
