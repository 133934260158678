import PropTypes from "prop-types";
import React from "react";
import CommonIntegrationDataRow from "settings/components/CommonIntegrationDataRow";
import useBushelConnectLink from "settings/components/Integrations/hooks/useBushelConnectLink";

import usePermissions from "hooks/usePermissions";
import useWindow from "hooks/useWindow";

import { Button } from "components/fl-ui";
import { LeftButton } from "components/fl-ui/Buttons";

const BushelIntegration = ({ integration }) => {
  const showButtons = usePermissions().canWrite("integrations");

  const win = useWindow();
  const { disabled, onClick, url } = useBushelConnectLink();
  const handleSettingsClick = (event) => {
    onClick(event);
    win.location.href = url;
  };

  return (
    <CommonIntegrationDataRow
      bordered
      copy="Automatically import contract data from grain buyers in the Bushel network."
      imgSrc="/images/layout/bushel-logo.svg"
      integration={integration}
    >
      {showButtons && (
        <>
          <LeftButton color="primary" href="https://go.bushelfarm.com/connect-to-bushel-network" link target="_blank">
            Learn more
          </LeftButton>

          {integration.enabled && (
            <Button
              color="primary"
              disabled={disabled}
              hollow
              icon="settings"
              onClick={handleSettingsClick}
              source="Integrations"
            />
          )}

          {!integration.enabled && (
            <a data-source="Integrations" disabled={disabled} href={url} onClick={onClick}>
              Connect
            </a>
          )}
        </>
      )}
    </CommonIntegrationDataRow>
  );
};

BushelIntegration.integrationId = "bushel";

BushelIntegration.propTypes = {
  integration: PropTypes.shape({
    enabled: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default BushelIntegration;
