import { gql } from "@apollo/client";

export default gql`
  query getUserMemberships {
    userMemberships: getUserMemberships @rest(type: "UserMemberships", path: "/api/user_memberships") {
      enterprise
      role {
        name
        title
      }
      user
    }
  }
`;
