import { css } from "aphrodite/no-important";
import { useMediaQuery } from "beautiful-react-hooks";
import d3 from "d3";
import BlankType from "map/layers/BlankType";
import CrossHairIcon from "map/layers/CrossHairIcon";
import LayerGallery from "map/layers/LayerGallery";
import LayerInfo from "map/layers/LayerInfo";
import TypeDropdown from "map/layers/TypeDropdown";
import { InfoCard } from "map/layers/helpers";
import styles from "map/layers/styles";
import { sanitizeImagery } from "map/layers/utils";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import ReactToggle from "react-toggle";
import _ from "underscore";

import connectFeatures from "hoc/connectFeatures";
import App from "layout/app";
import { EXPLORER_VIEW_TYPE_CHANGE, EXPLORER_LAYER_INTERACTION } from "lib/metrics/events";

import ImageryBlankStateModal from "components/advertisements/modals/ImageryBlankStateModal";
import SoilBlankStateModal from "components/advertisements/modals/SoilBlankStateModal";
import Zoom from "components/map/Zoom";
import MapControl from "components/map/control";
import Layer from "components/map/layer";
import FlatDropdown from "components/ui/dropdown/FlatDropdown";
import TypeOption from "components/ui/dropdown/TypeOption";
import Map from "components/ui/map";
import { isNative } from "mobile/mobileManager";

const types = [
  { value: "crops", text: "Field Boundary", name: "fieldLayers" },
  {
    value: "imagery",
    text: "Imagery",
    name: "imageryLayers",
    placeholder: "No imagery available",
  },
  { value: "soil.ssurgo", text: "Soil", name: "soilLayers", placeholder: "No data available" },
  { value: "yield.512", text: "Yield", name: "yieldLayers", placeholder: "No yield uploaded" },
];

const MapDiv = (element) => <div style={{ marginTop: "1em", ...element.style }} {...element} />;

const withIsPrintMedia = (WrappedComponent) => (props) => {
  const isPrint = useMediaQuery("print");
  return <WrappedComponent {...props} isPrint={isPrint} />;
};

const withPlatformInfo = (WrappedComponent) => (props) => <WrappedComponent {...props} isMobile={isNative()} />;

class LayersMap extends React.Component {
  state = {
    activeLayer: null,
    activeInfo: null,
    adModalType: null,
    layers: [],
    showInfo: true,
    type: types.find(({ value }) => this.props.defaultLayer === value) || types[0],
    imageryType: "True Color",
    showVariability: false,
  };

  componentDidMount() {
    this.handleChange(this.state);
  }

  componentDidUpdate(prevProps, { layers, activeLayer, imageryType, type }) {
    const { allLayers, error } = this.props;

    if (prevProps.allLayers !== allLayers || imageryType !== this.state.imageryType) {
      this.handleChange(this.state);
    }

    if (error) {
      App.notify("Failed to get any layer data");
    }
    const clickArgs = ["click", this.handleLayerClick];
    layers
      .concat((activeLayer || {}).layer)
      .filter(_.identity)
      .map((x) => x.off(...clickArgs));
    this.state.layers
      .concat((this.state.activeLayer || {}).layer)
      .filter(_.identity)
      .map((x) => x.on(...clickArgs));
    // track type change
    if (type.text !== this.state.type.text) {
      return EXPLORER_VIEW_TYPE_CHANGE.track({
        previous_layer_type: type.text,
        current_layer_type: this.state.type.text,
      });
    }
  }

  componentWillUnmount() {
    this.state.activeLayer?.layer?.off("layer:changed");
  }

  handleChange = ({ type }) => {
    const { allLayers, defaultLayer, hasEnterpriseFeature } = this.props;

    if (type.value === "soil.ssurgo" && !hasEnterpriseFeature("soil_maps")) {
      this.setState({ adModalType: "soil.ssurgo" });
    } else if (type.value === "imagery" && !hasEnterpriseFeature("crop_health_enterprise")) {
      this.setState({ adModalType: "imagery" });
    } else {
      const { activeLayer } = this.state;
      const layers = allLayers[type.name];
      const layer =
        layers.find(
          ({ details, id }) =>
            id === +defaultLayer?.id ||
            id === activeLayer?.id ||
            (details.description && details.description === activeLayer?.details?.description)
        ) || layers[layers.length - 1];

      activeLayer?.layer?.off("layer:changed");
      this.setActive(layer, type);
      layer?.layer?.on("layer:changed", ({ target }) => this.setActiveInfo(target.selectedId, false));
      return this.setState({ type });
    }
  };

  setActive = (layer, type) => {
    const { imageryType } = this.state;
    const layerType = type || this.state.type;
    const activeLayer = layer && layerType.value === "imagery" ? sanitizeImagery(layer, imageryType) : layer;
    const data = { activeLayer };

    if (activeLayer) {
      const { details } = activeLayer;
      data.activeInfo = details?.data;
    }

    this.setState(data);
  };

  setActiveInfo = (infoId, shouldUpdateLayer) => {
    const { activeInfo, activeLayer } = this.state;

    // find the selected layer, otherwise clear id
    if (activeInfo.info.find((i) => i.selected && i.id === infoId)) {
      infoId = null;
    }

    // map and add "selected" prop to layer id
    const info = activeLayer.details.data.info.map((i) => {
      let selected;
      if (infoId) {
        selected = i.id === infoId;
      }

      return _.assign({ selected }, i);
    });

    if (typeof activeLayer.layer.highlightFeature === "function" && !!shouldUpdateLayer) {
      activeLayer.layer.highlightFeature(infoId);
    }
    this.setState({ activeInfo: { info }, showInfo: true });

    return EXPLORER_LAYER_INTERACTION.track({
      entity_id: activeLayer.id,
      field_id: activeLayer.field_id,
      layer_type: activeLayer.type,
    });
  };

  handleLayerClick = (feature) => {
    const { layer, details } = this.state.activeLayer;
    const { event } = d3;

    if ((details.data || {}).info && !event.defaultPrevented) {
      const id = layer.getId(feature);
      return this.setActiveInfo(id);
    }
  };

  setLayer = (imageryType) => {
    const data = { imageryType };

    if (imageryType.startsWith("NDVI")) {
      data.showVariability = false;
    }

    this.setState(data);
  };

  renderAdModal = () => {
    const { adModalType } = this.state;

    const onClose = () =>
      this.setState({
        adModalType: null,
      });

    if (adModalType === "soil.ssurgo") {
      return <SoilBlankStateModal onClose={onClose} />;
    } else if (adModalType === "imagery") {
      return <ImageryBlankStateModal onClose={onClose} />;
    }

    return null;
  };

  renderInfoCard = () => {
    const { activeInfo, activeLayer, imageryType, showInfo, showVariability } = this.state;
    if (activeLayer) {
      let legendData;
      const variableTypes = ["NDVI", "NDVI_relative"];

      if (variableTypes.includes(imageryType)) {
        const toggleVariability = () => {
          if (!showVariability) {
            this.setLayer("NDVI_relative");
          } else {
            this.setLayer("NDVI");
          }

          this.setState(() => ({ showVariability: !showVariability }));
        };

        legendData = (
          <div className={`row-fluid ${css(styles.legendContainer)}`}>
            <div className="col xs-3">
              <span style={{ fontWeight: showVariability ? "initial" : "bold" }}>
                {imageryType.startsWith("NDVI") ? "Vegetation" : "Temperature"}
              </span>
            </div>
            <div className="col xs-9">
              <span style={{ float: "right" }}>
                <span>
                  <ReactToggle icons={false} checked={showVariability} onClick={toggleVariability} />
                </span>
                <span
                  style={{
                    verticalAlign: "top",
                    paddingLeft: "5px",
                    fontWeight: showVariability ? "bold" : "initial",
                  }}
                >
                  {"Max Variability"}
                </span>
              </span>
            </div>
          </div>
        );
      }

      return (
        <InfoCard
          title={activeLayer.details.title}
          description={activeLayer.details.description}
          more={showInfo}
          onToggle={() => this.setState({ showInfo: !showInfo })}
        >
          {activeInfo && (
            <LayerInfo
              layer={activeInfo}
              selectedType={imageryType}
              onClick={this.setActiveInfo}
              legendData={legendData}
              showVariability={showVariability}
            />
          )}
        </InfoCard>
      );
    } else if (this.props.loading) {
      return <InfoCard title="Loading..." />;
    }
  };

  render() {
    const { allLayers, crossHair, isMobile, isPrint, loading, on, value, zoom } = this.props;
    const { activeLayer, adModalType, imageryType, layers, type } = this.state;
    const layerTypes = ["True Color", "Color Infrared", "NDVI"];
    const showImageryTypeSelector = activeLayer?.source === "leaf" || activeLayer?.details?.data?.truecolor;
    const isDisabled = (type) => type === "Color Infrared";

    return (
      <Fragment>
        <Map
          ref="map"
          backdrop={type.value !== "crops"}
          value={_.assign({ zoomControl: false }, value)}
          on={on}
          style={allLayers[type.name].length > 1 && { height: "80%", maxHeight: "calc(100% - 5rem)" }}
        >
          <MapControl position="top">
            <div className="col xs-12" style={{ maxWidth: "268px" }}>
              <div className={css(styles.mapControlButtonContainer, showImageryTypeSelector && styles.mapMultiControl)}>
                {!isPrint && (
                  <TypeDropdown
                    layers={allLayers[type.name]}
                    onChange={this.handleChange}
                    types={types}
                    value={type.value}
                  >
                    {type.text}
                  </TypeDropdown>
                )}

                {showImageryTypeSelector && !isPrint && (
                  <FlatDropdown
                    hasMultiControl
                    icon={"layers"}
                    id="fde-type-button"
                    text={imageryType.startsWith("NDVI") ? "NDVI" : imageryType}
                  >
                    {layerTypes.map((label, i) => (
                      <TypeOption
                        disabled={isDisabled(label)}
                        key={`type-option-${i}`}
                        onClick={() => (isDisabled(label) ? null : this.setLayer(label))}
                        selected={imageryType.startsWith("NDVI") ? "NDVI" : imageryType}
                        text={label}
                        value={label}
                      />
                    ))}
                  </FlatDropdown>
                )}
              </div>
              <MapDiv>
                {this.renderInfoCard()}
                {adModalType && this.renderAdModal()}
              </MapDiv>
            </div>
          </MapControl>

          {layers.length
            ? layers.map((layer) => <Layer key={layer.id} layer={layer} selectedType={imageryType} />)
            : !!activeLayer && <Layer layer={activeLayer.layer} selectedType={imageryType} />}

          {!loading && (
            <span>
              {!activeLayer && (
                <MapControl position="middle">
                  <div className="xs-12" style={{ color: "white", pointerEvents: "all", textAlign: "center" }}>
                    <div style={{ display: "inline-block" }}>
                      <BlankType type={type} />
                    </div>
                  </div>
                </MapControl>
              )}
              {crossHair && activeLayer && <CrossHairIcon />}
              {zoom && !isPrint && !isMobile && (
                <div className="col xs-12" style={{ position: "static", textAlign: "right" }}>
                  <Zoom
                    style={{
                      bottom: "3em",
                      display: "inline-block",
                      float: "none",
                      position: "absolute",
                      right: "1em",
                    }}
                  />
                </div>
              )}
            </span>
          )}
        </Map>

        <MapControl position="bottom" height="20%" minHeight="5rem" p="0 1rem">
          {activeLayer && allLayers[type.name].length > 1 && (
            <LayerGallery
              layers={allLayers[type.name]}
              onChange={this.setActive}
              type={type.value}
              value={activeLayer}
              imageryType={imageryType}
            />
          )}
        </MapControl>
      </Fragment>
    );
  }
}

LayersMap.propTypes = {
  data: PropTypes.object,
  error: PropTypes.bool,
  loading: PropTypes.bool,
  on: PropTypes.array,
  value: PropTypes.object,
};

LayersMap.defaultProps = {
  data: {},
  value: {},
};

export default withIsPrintMedia(withPlatformInfo(connectFeatures(LayersMap)));
