import useDocumentTitle from "app/hooks/useDocumentTitle";
import withBillingFormState from "billing/containers/withBillingFormState";
import CheckoutPage from "billing/pages/CheckoutPage";
import BillingProductsProvider from "billing/providers/BillingProductsProvider";
import React from "react";

import ErrorBoundary from "components/fl-ui/Layout/ErrorBoundary";

const WrappedCheckoutPage = withBillingFormState(CheckoutPage);

const BillingPage = () => {
  useDocumentTitle("Tier Selection");
  return (
    <ErrorBoundary>
      <BillingProductsProvider>
        <WrappedCheckoutPage />
      </BillingProductsProvider>
    </ErrorBoundary>
  );
};


export default BillingPage;
