import _ from "lodash";
import React from "react";
import { redirect } from "react-router-dom";

import BinsCollection from "collection/bins";
import BuyersCollection from "collection/buyers";
import { GET_CURRENT_USER } from "collection/graphql/auth/queries";
import { marketingClient } from "collection/graphql/client";
import { refreshHistoricalRainfall, refreshLast24Rainfall } from "collection/graphql/fields/rainfall";
import InventoryTransactionsCollection from "collection/inventory_transactions";
import ProtectedRouteLayout from "layout/ProtectedRouteLayout";

import FieldAdd from "fields/components/FieldAdd";
import FieldAspect from "fields/components/FieldAspect";
import FieldDetail from "fields/components/FieldDetail";
import Survey from "fields/components/Survey";
import FieldsIndexPage from "fields/pages/FieldsIndexPage";

const callAtInterval = (queryFn, interval) => queryFn().then(() => setInterval(queryFn, interval));

const initializeRainfallPolling = _.once(() => {
  marketingClient.query({ query: GET_CURRENT_USER }).then(() => {
    callAtInterval(refreshLast24Rainfall, 10 * 60 * 1000); // call every 10 minutes
    callAtInterval(refreshHistoricalRainfall, 24 * 60 * 60 * 1000); // call every 24 hours
  });

  return null;
});

const primeCollections = async () => {
  await Promise.allSettled([BinsCollection.prime(), BuyersCollection.prime(), InventoryTransactionsCollection.prime()]);

  return null;
};

export default [
  {
    element: <ProtectedRouteLayout />,
    loader: initializeRainfallPolling,
    children: [
      {
        path: "/fields",
        element: <FieldsIndexPage />,
      },

      {
        path: "/fields/survey",
        loader: () => {
          return null;
        },
        element: <Survey />,
      },

      {
        loader: primeCollections,
        children: [
          {
            path: "/fields/:id",
            element: <FieldDetail />,
          },

          {
            path: "/fields/add",
            element: <FieldAdd />,
          },

          {
            path: "/fields/:id/edit",
            element: <FieldAdd />,
          },

          {
            path: "/fields/:id/:aspect",
            loader: ({ params: { aspect, id } }) => {
              const validAspects = [
                "activities",
                "attachments",
                "crop_history",
                "gdd",
                "loads",
                "rainfall",
                "scouting",
                "yield",
              ];

              if (validAspects.includes(aspect)) {
                return null;
              }

              return redirect(`/fields/${id}`);
            },
            element: <FieldAspect />,
          },
        ],
      },
    ],
  },
];
