import { css } from "aphrodite";
import { SupportEmailLink, SupportLink, SupportPhoneLink } from "billing/lib";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

import styles from "layout/styles";

const FooterMessage = ({ link, message, onClick = null }) => (
  <div className={css(styles.footerContainer, styles.copyText)}>
    {message && (
      <Link className={css(styles.blueText)} to={link} onClick={onClick} rel="noopener noreferrer">
        {message}
      </Link>
    )}

    <p className={css(styles.contactText)}>
      If you need support, please visit our <SupportLink>support site</SupportLink>, or contact us at{" "}
      <SupportEmailLink /> or <SupportPhoneLink />.
    </p>
  </div>
);

FooterMessage.propTypes = {
  link: PropTypes.string,
  message: PropTypes.string,
  onClick: PropTypes.func,
};

export default FooterMessage;
