import { useMutation } from "@apollo/client";
import { useCallback } from "react";

import { BASIC_FIELD } from "collection/graphql/fields/fragments";
import { CREATE_FIELD, DELETE_FIELDS, IMPORT_FIELDS, UPDATE_FIELD } from "collection/graphql/fields/mutations";
import { refreshHistoricalRainfall } from "collection/graphql/fields/rainfall";

const useFieldMutations = () => {
  const create = useMutation(CREATE_FIELD)[0];
  const destroy = useMutation(DELETE_FIELDS)[0];
  const fieldsImport = useMutation(IMPORT_FIELDS)[0];
  const update = useMutation(UPDATE_FIELD)[0];

  const bulkDeleteFields = useCallback(async (fieldIds) => {
    await destroy({
      update: (cache) => {
        fieldIds.forEach((id) => {
          cache.evict({
            id: cache.identify({ id, __typename: "Field" }),
          });
        });
        cache.gc();
      },

      variables: { fieldIds },
    });
  }, []);

  const createField = useCallback(async (fieldInput) => {
    const { data } = await create({
      update(cache, payload) {
        cache.modify({
          fields: {
            getFields: () => {
              cache.writeFragment({
                data: payload.data.createField.field,
                fragment: BASIC_FIELD,
              });
            },
          },
        });
      },

      variables: {
        field: fieldInput,
      },
    });

    // including the .then() callback here to silence a "missing await" warning in the IDE
    refreshHistoricalRainfall().then(() => {});

    return data.createField.field;
  }, []);

  const deleteField = useCallback(async (id) => bulkDeleteFields([id]), []);

  const importFields = useCallback(async (input) => {
    const { data } = await fieldsImport({variables: {input}});
    return data.importFields;
  }, []);

  const saveField = useCallback(async (field) => {
    return field.id ? updateField(field) : createField(field);
  }, []);

  const updateField = useCallback(async (field) => {
    const { data } = await update({
      optimisticResponse: ({ field }) => ({
        updateField: {
          field,
          ok: true,
        },
      }),
      variables: { field },
    });

    return data.updateField.field;
  }, []);

  return {
    bulkDeleteFields,
    createField,
    deleteField,
    importFields,
    saveField,
    updateField,
  };
};

export default useFieldMutations;
