import EquipmentImage from "equipment/components/EquipmentImage";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import usePermissions from "hooks/usePermissions";

import { Button } from "components/fl-ui";
import BlankSlate from "components/fl-ui/BlankSlate";

const Listing = styled.div`
  align-content: stretch;
  align-items: stretch;
  display: flex;
  flex-flow: row wrap;

  > * {
    margin: 0 1rem 1rem 0;
    width: ${({ size }) => size}px;
  }
  h3 {
    margin-bottom: 0;
  }
`;

const EquipmentListing = ({ onAdd, listItems, size, type }) => {
  const isTractor = type === "tractor";
  const showAddButton = usePermissions().canWrite("equipment");
  const showBlankSlate = listItems.length === 0;

  if (showBlankSlate) {
    const title = isTractor ? "No tractors have been added." : "No implements have been added.";
    const buttonCopy = isTractor ? "Add a tractor" : "Add an implement";

    return (
      <BlankSlate icon="equipment" title={title}>
        {showAddButton && <Button onClick={onAdd}>{buttonCopy}</Button>}
      </BlankSlate>
    );
  }

  return (
    <Listing size={size}>
      {listItems.map(({ icon, id, name }) => {
        const path = isTractor ? `/equipment/tractor/${id}` : `/equipment/implement/${id}`;
        return (
          <Link key={[type, id].join(" ")} data-cy={isTractor ? "tractorDetailLink" : "implementDetailLink"} to={path}>
            <EquipmentImage icon={icon} name={name} size={size} type={type} />
            <h3>{name}</h3>
          </Link>
        );
      })}
    </Listing>
  );
};

EquipmentListing.propTypes = {
  listItems: PropTypes.array.isRequired,
  onAdd: PropTypes.func.isRequired,
  size: PropTypes.number,
  type: PropTypes.oneOf(["implement", "tractor"]).isRequired,
};

EquipmentListing.defaultProps = {
  size: 150,
};

export default EquipmentListing;
