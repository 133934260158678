import _ from "lodash";
import moment from "moment";
import numeral from "numeral";
import PropTypes from "prop-types";
import React from "react";

import getHeatHistory from "collection/graphql/weather/getHeatHistory";
import useRestQuery from "hooks/useRestQuery";

import AspectSummary, { HeatStat } from "components/field/AspectSummary";
import useWeatherQueryOptions from "components/field/weather/hooks/useWeatherQueryOptions";
import { calculateHeatAndRainfallTotals } from "components/field/weather/lib";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";

const HeatSummary = (props) => {
  const { cropYear, fieldId } = props;
  const { endDate, geometry, lastPlantedCrop, loading, startDate } = useWeatherQueryOptions(fieldId, cropYear);
  const hasPlanted = !!lastPlantedCrop;
  const dateFormat = moment(startDate).year() === cropYear ? "MMM D" : "MMM D, YYYY";
  let gdd = !loading ? {} : undefined;
  if (lastPlantedCrop) {
    gdd = _.pick(lastPlantedCrop.commodity, ["gddTbase", "gddTceil"]);
  }

  const { data, loading: rainfallLoading } = useRestQuery(getHeatHistory, {
    skip: loading || !gdd,
    variables: { endDate, geometry, startDate, ...gdd },
  });

  const { dailyTotal, percentChange } = calculateHeatAndRainfallTotals(data?.daily, data?.averages);
  const showSecondaryStat = !isNaN(percentChange);
  const isLoading = loading || rainfallLoading;

  return isLoading ? (
    <LoadingWrapper isLoading />
  ) : (
    <AspectSummary linkHref={`/fields/${fieldId}/gdd`} linkText="View heat units from all years" title="Heat Units">
      <HeatStat label={`since ${moment(startDate).format(dateFormat)}`} highlight={hasPlanted}>
        <big>{numeral(dailyTotal).format("0,0")}</big> GDD
      </HeatStat>

      {showSecondaryStat && (
        <HeatStat label="than avg" highlight={hasPlanted}>
          {Math.abs(percentChange * 100).toFixed(1)}% {percentChange > 0 ? "warmer" : "colder"}
        </HeatStat>
      )}
    </AspectSummary>
  );
};

HeatSummary.propTypes = {
  cropYear: PropTypes.number.isRequired,
  fieldId: PropTypes.number.isRequired,
};

export default HeatSummary;
