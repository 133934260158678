import _ from "lodash";
import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";

const getDefaults = () => ({
  crops: [],
  fields: [],
  implements: [],
  people: [],
  products: [],
  sources: [],
  status: [],
  tractors: [],
  type: [],
});

/**
 * Ingests raw query params for the activity index page and normalizes them.  Exposes
 * a setter for updating the activity index query params.
 *
 * @return {[ActivityFilters|*, Function]}
 */
const useActivityQueryParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  return useMemo(() => {
    const queryParams = {
      ...getDefaults(),
      sources: _.map(searchParams.getAll("sources"), _.toUpper),
      status: _.map(searchParams.getAll("status"), _.toUpper),
    };

    searchParams.forEach((value, key) => {
      if (!_.isEmpty(value)) {
        if (["sources", "status"].includes(key)) {
          queryParams[key] = searchParams
            .getAll(key)
            .map((value) => _.toUpper(value))
            .filter((x) => !!x);
        } else if (Array.isArray(queryParams[key])) {
          queryParams[key] = searchParams
            .getAll(key)
            .map((value) => Number(value))
            .filter((x) => !isNaN(x));
        } else {
          queryParams[key] = value;
        }
      }
    });

    const setQueryParams = ({ equipment, ...filters }) => {
      const updatedParams = new URLSearchParams();

      _.each(equipment, ({ __typename, id }) => {
        const key = __typename === "Implement" ? "implements" : "tractors";
        updatedParams.append(key, id);
      });

      for (const [key, value] of Object.entries(filters)) {
        [[value]]
          .flat(Infinity)
          .filter((x) => !!x)
          .forEach((value) => updatedParams.append(key, value));
      }

      setSearchParams(updatedParams);
    };

    return [queryParams, setQueryParams];
  }, [searchParams, setSearchParams]);
};

export default useActivityQueryParams;
