/* eslint-disable react/display-name */
import _ from "lodash";
import { optionStyles } from "marketing/styles/formStyles";
import PropTypes from "prop-types";
import React, { createContext, useContext, useMemo, useState } from "react";
import ReactSelect, { components } from "react-select";

import useCommodities from "hooks/useCommodities";
import usePermissions from "hooks/usePermissions";

import CommodityFormModal from "components/commodity/CommodityFormModal";
import { Button } from "components/fl-ui";

const ADD_ITEM_BUTTON_ID = "addCommodityAction";

const filterOption = (candidate, input) => {
  return (
    !input || candidate.value === ADD_ITEM_BUTTON_ID || candidate.label.toLowerCase().includes(input.toLowerCase())
  );
};

const Option = (props) => {
  const { id, name, onClick } = props.data;

  return id === ADD_ITEM_BUTTON_ID ? (
    <Button color="primary" link onClick={onClick} size="lg" style={{ padding: ".5rem .75rem" }}>
      {name}
    </Button>
  ) : (
    <components.Option {...props} />
  );
};

const CommodityChooserContext = createContext(null);
export const CommodityChooserProvider = CommodityChooserContext.Provider;
const useCommodityProvider = () => useContext(CommodityChooserContext);
const DefaultCommodityChooser = (props) => (
  <CommodityChooserProvider value={useCommodities()}>
    <CommodityChooser {...props} />
  </CommodityChooserProvider>
);

export const useUnitTypeFilteredCommodities = (unitType) => {
  const { commodities: unfilteredCommodities, loading } = useCommodities();
  const seedUnitType = _.toLower(unitType);
  const commodities = _.filter(unfilteredCommodities, { seedUnitType });

  return { commodities, loading };
};

const CommodityChooser = ({ commodityId, inputId, onChange, placeholder, sanitizer }) => {
  const canWriteCommodities = usePermissions().canWrite("commodities");
  const [showModal, setShowModal] = useState(false);
  const { commodities, loading } = useCommodityProvider();
  const commodityList = useMemo(() => {
    const list = sanitizer ? commodities?.map(sanitizer) : commodities;
    return canWriteCommodities
      ? [...list, { id: ADD_ITEM_BUTTON_ID, name: "+ Add commodity", onClick: () => setShowModal(true) }]
      : list;
  }, [commodities, sanitizer]);

  const handleCreate = (newCommodity) => {
    onChange(newCommodity);
    setShowModal(false);
  };

  return (
    <>
      <ReactSelect
        components={{ Option }}
        filterOption={filterOption}
        getOptionLabel={({ name }) => name}
        getOptionValue={({ id }) => id}
        isDisabled={loading}
        inputId={inputId}
        onChange={onChange}
        options={commodityList}
        placeholder={placeholder || "Choose a commodity"}
        styles={optionStyles}
        value={commodityList.find(({ id }) => commodityId === id) ?? null}
      />

      {showModal && <CommodityFormModal onClose={() => setShowModal(false)} onSave={handleCreate} />}
    </>
  );
};

CommodityChooser.propTypes = {
  commodityId: PropTypes.number,
  inputId: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  sanitizer: PropTypes.func,
};

export default (props) => {
  return useCommodityProvider() ? <CommodityChooser {...props} /> : <DefaultCommodityChooser {...props} />;
};
