import useReleaseFeature from "hooks/useReleaseFeature";
import React from "react";
import DemoIndexPage from "demo/routes/Index";
import { Outlet, useNavigate } from "react-router-dom";


const DemoGuard = () => {
  const hasAccess = useReleaseFeature("debug").enabled;
  const navigate = useNavigate();
  if (!hasAccess) {
    navigate("/fields");
    return null;
  }

  return <Outlet />;
};

export default [
  {
    path: "/demo",
    element: <DemoGuard />,
    children: [
      {
        path: "/demo",
        element: <DemoIndexPage />,
      }
    ],
  }
];
