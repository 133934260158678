import PropTypes from "prop-types";
import React, { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import ActivityTypeAddEditModal from "settings/components/ActivityTypeAddEditModal";
import SelectionList from "settings/components/SelectionList";
import { getSortedTypes } from "settings/utils";

import withActivityTypes from "collection/graphql/activities/withActivityTypes";
import { marketingClient, wrapWithProvider } from "collection/graphql/client";
import useEnterpriseFeature from "hooks/useEnterpriseFeature";
import usePermissions from "hooks/usePermissions";

import PayGateAdBanner from "components/advertisements/banners/PayGateAdBanner";

const ActivityTypes = (props) => {
  const { canRead } = usePermissions();
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState(null);
  const { activityTypes, categories } = props.data;
  const [archivedActivityTypes, activeActivityTypes] = getSortedTypes(activityTypes);
  const hasActivitiesFeature = useEnterpriseFeature("activities");
  const navigate = useNavigate();

  const handleClose = () => {
    setData(null);
    setShowModal(false);
  };

  const handleCreateData = () => {
    setData({ name: "", operationalCostRate: "PER_ACRE" });
    setShowModal(true);
  };

  const handleEditData = (type) => {
    setData(type);
    setShowModal(true);
  };

  if (!canRead("activity_types")) {
    navigate("/settings", { replace: true });
    return null;
  }

  if (!hasActivitiesFeature) {
    return <PayGateAdBanner />;
  }

  return (
    <Fragment>
      <SelectionList
        buttonText="Add activity type"
        copy="Customize activity types and set operational costs for your field work."
        handleCreateData={handleCreateData}
        handleEditData={handleEditData}
        header="Configure activities"
        list={activeActivityTypes}
        placeholder="You haven’t created any activity types yet"
      />

      <SelectionList
        copy="Archived activity types will no longer display when filtering, editing, or creating activities. Any activities that have an archived type will be unaffected by this change."
        handleEditData={handleEditData}
        header="Archived activity types"
        list={archivedActivityTypes}
        placeholder="You have no archived activity types"
        secondary
      />

      {showModal && (
        <ActivityTypeAddEditModal
          activityTypes={activityTypes}
          categories={categories}
          data={data}
          onClose={handleClose}
        />
      )}
    </Fragment>
  );
};

ActivityTypes.propTypes = {
  data: PropTypes.shape({
    activityTypes: PropTypes.arrayOf(PropTypes.object),
    categories: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
};

const WrappedActivityTypes = wrapWithProvider(withActivityTypes(ActivityTypes), marketingClient);
WrappedActivityTypes.tabId = "activities";
WrappedActivityTypes.title = "Activities";

export default WrappedActivityTypes;
