import React from "react";
import ConsentPage from "elevate_plus/pages/ConsentPage";


export default [
  {
    path: "/elevate_plus/consent",
    element: <ConsentPage />,
  },
];
