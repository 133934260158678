import PropTypes from "prop-types";
import React from "react";

import { Button } from "components/fl-ui";

const InputItem = ({ children, onEdit, sub, title }) => (
  <div className="settings-item settings-item-hoverable">
    <Button className="float-right btn btn-tiny btn-white" color="secondary" onClick={onEdit}>
      Edit
    </Button>

    <div>
      {children || null}
      <div className="title">{title}</div>
      <div className="sub">{sub}</div>
    </div>
  </div>
);

InputItem.propTypes = {
  title: PropTypes.node.isRequired,
  sub: PropTypes.node.isRequired,
  href: PropTypes.string,
};

export default InputItem;
