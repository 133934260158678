import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import useEnterpriseFeature from "hooks/useEnterpriseFeature";

const useFeatureRedirect = ({ feature, skip = false, url = "fields" }) => {
  const hasFeature = useEnterpriseFeature(feature);
  const navigate = useNavigate();

  useEffect(() => {
    if (!skip && !hasFeature && hasFeature === false) {
      navigate(url);
    }
  }, [hasFeature, skip]);
};

export default useFeatureRedirect;
