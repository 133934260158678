import { captureException } from "@sentry/react";
import { css } from "aphrodite/no-important";
import CurrentSubscriptionCopy from "billing/components/CurrentSubscriptionCopy";
import DownGradeModal from "billing/components/DownGradeModal";
import SuccessModal from "billing/components/SuccessModal";
import OrderErrorDialog from "billing/components/dialogs/OrderErrorDialog";
import moment from "moment";
import numeral from "numeral";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-styled-flexboxgrid";
import InvoiceHistory from "settings/components/InvoiceHistory";
import ManageSubscriptionAction from "settings/components/ManageSubscriptionAction";
import styles, { CancellationText, CopyText, HeaderText, TrialText } from "settings/styles";

import { getAllPeople } from "collection/graphql/people/queries";
import {
  getCurrentSubscription,
  useSubscriptionPlatform,
  useUpdateSubscription,
} from "collection/graphql/subscription";
import useRestSuspenseQuery from "hooks/useRestSuspenseQuery";
import { TIER_SELECTION_PLAN_WELCOME_START } from "lib/metrics/events";

import LoadingDialog from "components/fl-ui/LoadingDialog";
import { withSuspenseWrapper } from "components/fl-ui/LoadingWrapper";

const formatDate = (date) => moment(date).format("MMMM D, YYYY");

const Billing = withSuspenseWrapper(() => {
  const navigate = useNavigate();
  const [dialogId, setDialogId] = useState("");
  const [saveError, setSaveError] = useState(null);
  const { billingInterval, cancelAtPeriodEnd, currentPlan, daysRemaining, isSubscribed, isTrialing, userLimits } =
    useRestSuspenseQuery(getCurrentSubscription).data.subscription;
  const showInvoices = currentPlan?.source === "stripe" ?? false;
  const { cancelSubscription: cancelCurrentSubscription } = useUpdateSubscription();
  const subscriptionPlatform = useSubscriptionPlatform();

  const { people } = useRestSuspenseQuery(getAllPeople).data;
  const legacyPaidSeats = people.filter((person) => person.isLegacyPaidSeat).length;

  let planDetails = "";
  if (currentPlan?.totalPrice > 0 && currentPlan.source === "stripe" && billingInterval) {
    planDetails = `$${numeral(currentPlan.totalPrice).format("0,0")} / ${billingInterval}`;
    if (moment(currentPlan.periodEnd).isValid()) {
      planDetails += ` \u00b7 Next payment due ${formatDate(currentPlan.periodEnd)}`;
    }
  }

  const closeDialog = () => {
    setSaveError(null);
    setDialogId("");
  };

  const onSuccess = () => {
    navigate("/fields", { replace: true });
    location.reload();
  };

  const cancelSubscription = async () => {
    setDialogId("processing");
    try {
      await cancelCurrentSubscription();
      TIER_SELECTION_PLAN_WELCOME_START.track();
      onSuccess();
    } catch (error) {
      const context = error.getContext?.() ?? {};
      const sentryEventId = captureException(error, context);
      console.error(error);
      console.error("Sentry event id: ", sentryEventId);
      setSaveError(error);
      setDialogId("error");
    }
  };

  const getPlanSummary = () => {
    if (subscriptionPlatform?.isMobilePaymentProvider) {
      return `You are subscribed via ${subscriptionPlatform.storeName}`;
    }

    return !isSubscribed ? "Get started with Bushel Farm today" : <CurrentSubscriptionCopy />;
  };

  return (
    <div>
      <Row className={css(styles.rowBorderBottom)}>
        <Col xs={12} sm={6} className={css(styles.colStyles)}>
          <div>
            <div>
              <HeaderText>{getPlanSummary()}</HeaderText>
              {cancelAtPeriodEnd && (
                <div>
                  <CancellationText>
                    Scheduled for cancellation in {daysRemaining === 1 ? "1 day" : `${daysRemaining} days`} on{" "}
                    {moment(currentPlan.periodEnd).format("MMMM DD, YYYY")}.
                  </CancellationText>
                </div>
              )}

              {isTrialing && (
                <div>
                  <TrialText>Trial ends on {moment(currentPlan.periodEnd).format("dddd, MMMM DD, YYYY")}</TrialText>
                </div>
              )}
            </div>
            {planDetails && <CopyText>{planDetails}</CopyText>}
          </div>
        </Col>
        <Col xs={12} sm={6} className={css(styles.colStyles, styles.rightAligned)}>
          <ManageSubscriptionAction onCancelSubscription={() => setDialogId("confirm")} />
        </Col>
      </Row>

      <Row className={css(styles.rowBorderBottom)}>
        <Col xs={12} md={6} className={css(styles.colStyles)}>
          <div className={css(styles.summary)}>
            <HeaderText>User summary</HeaderText>
          </div>
        </Col>

        <Col xs={12} md={6} className={css(styles.colStyles, styles.rightAligned)}>
          <div className={css(styles.summary, styles.summaryRight)}>
            <span>
              <HeaderText>{legacyPaidSeats}</HeaderText>
              <CopyText>&nbsp; {legacyPaidSeats === 1 ? "user" : "users"}</CopyText>
            </span>
            <CopyText>Your account</CopyText>
          </div>

          <div className={css(styles.summary, styles.summaryRight)}>
            <span>
              <HeaderText>{userLimits?.users?.max ?? "Unlimited"}</HeaderText>
              <CopyText>&nbsp; users</CopyText>
            </span>
            <CopyText>Plan limit</CopyText>
          </div>
        </Col>
      </Row>

      {showInvoices && <InvoiceHistory />}

      {dialogId === "confirm" && <DownGradeModal onClose={closeDialog} onConfirm={cancelSubscription} />}
      {dialogId === "processing" && <LoadingDialog dialogMessage="Processing..." />}
      {dialogId === "success" && <SuccessModal onClose={onSuccess} />}
      {dialogId === "error" && <OrderErrorDialog actionType="cancel" error={saveError} onClose={closeDialog} />}
    </div>
  );
});

Billing.tabId = "billing";
Billing.title = "Billing Settings";

export default Billing;
