import { MediaAware } from "@farmlogs/fl-ui";
import cx from "classnames";
import React from "react";
import { Transition, TransitionGroup } from "react-transition-group";

import useBoolean from "hooks/useBoolean";

import { Logo } from "components/fl-ui/Icons";
import ResponsiveMenuContainer from "components/fl-ui/Layout/Navigation/ResponsiveMenu/ResponsiveMenuContainer";
import ResponsiveMenuDrawer from "components/fl-ui/Layout/Navigation/ResponsiveMenu/ResponsiveMenuDrawer";
import ResponsiveMenuItem from "components/fl-ui/Layout/Navigation/ResponsiveMenu/ResponsiveMenuItem";
import { NavigationScreen } from "components/fl-ui/Layout/Navigation/Screen";
import Toolbar from "components/fl-ui/Layout/Navigation/Toolbar";
import NavigationHeader from "components/fl-ui/Layout/Navigation/components/NavigationHeader";
import useResponsiveMenuItems from "components/fl-ui/Layout/Navigation/hooks/useResponsiveMenuItems";

const ResponsiveMenu = () => {
  const [showDrawer, { off, toggle }] = useBoolean(false);
  const responsiveMenuItems = useResponsiveMenuItems();

  const handleMenuItemClick = (event, menuItem) => {
    if (menuItem.id === "more") {
      event.preventDefault();
      toggle();
    } else {
      if (typeof menuItem.onClick === "function") {
        menuItem.onClick(event);
      }
      off();
    }
  };

  return (
    <MediaAware mobile>
      <NavigationHeader>
        <Logo size="sm" />
        <Toolbar />
      </NavigationHeader>

      <ResponsiveMenuContainer className={cx(showDrawer && "drawerOpen")} data-cy="mobileMenu" id="mobileMenuContainer">
        {responsiveMenuItems.get("MAIN").map((menuItem) => (
          <ResponsiveMenuItem
            {...menuItem}
            key={menuItem.id}
            onClick={(event) => handleMenuItemClick(event, menuItem)}
          />
        ))}
      </ResponsiveMenuContainer>

      <TransitionGroup>
        {showDrawer && (
          <Transition key="mainMenuTransition" timeout={250} transitionName="mainMenuTransition">
            {(state) => (
              <>
                <ResponsiveMenuDrawer state={state}>
                  {responsiveMenuItems.get("DRAWER").map((menuItem) => (
                    <ResponsiveMenuItem
                      {...menuItem}
                      key={menuItem.id}
                      onClick={(event) => handleMenuItemClick(event, menuItem)}
                    />
                  ))}
                </ResponsiveMenuDrawer>

                <NavigationScreen key="screen" onClick={off} state={state} />
              </>
            )}
          </Transition>
        )}
      </TransitionGroup>
    </MediaAware>
  );
};

export default ResponsiveMenu;
